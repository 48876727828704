import React, { useContext, useEffect } from 'react';
import { DisplayLineChart } from './LineChart.jsx'
import "./AnalyticsPage.css";
import AnalyticsPageCard from './AnalyticsPageCard';
import mainContext from '../../contexts/mainContext.jsx';
const AnalyticsPage = () => {
    const context = useContext(mainContext);
    let { setProfileNavTitle } = context;
    useEffect(() => {
        setProfileNavTitle("Analytics");
        // eslint-disable-next-line
    }, [])
    return (
        <>
            <div className="component_body">
                <div className="analytics_first_section">
                    <AnalyticsPageCard status={true} title="Patient" num="3,459" per="+25%" desc="since yesterday" icon={true} />
                    <AnalyticsPageCard status={false} title="Fund Raisers" num="289" per="+15%" desc="since yesterday" icon={true} />
                    {/* <AnalyticsPageCard status={false} title="Driver idle" num="190" per="+30%" desc="since yesterday" icon={true} />
                    <AnalyticsPageCard status={false} title="Driver idle" num="190" per="+30%" desc="since yesterday" icon={true} />
                    <AnalyticsPageCard status={false} title="Driver idle" num="190" per="+30%" desc="since yesterday" icon={true} /> */}
                </div>
                <div className="analytics_second_section">
                    <div className="analytics_line_chart">
                        <DisplayLineChart />
                    </div>
                    <div className="analytics_bar_chart">

                    </div>
                </div>
                <div className="analytics_third_section">
                    {/* <AnalyticsPageCard status={true} title="Total trips" num="3,459" per="+25%" desc="" icon={true} />
                    <AnalyticsPageCard status={false} title="Total companies" num="190" per="-30%" desc="" icon={true} />
                    <AnalyticsPageCard status={false} title="Total drivers" num="289" per="-10%" desc="" icon={true} />
                    <AnalyticsPageCard status={false} title="Total drivers" num="289" per="-10%" desc="" icon={true} /> */}
                </div>
            </div>
        </>
    )
}

export default AnalyticsPage

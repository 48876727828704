import React from 'react'
import "./ManagementPageBody.css"
import ManagementElementCard from "./ManagementElementCard.jsx"
const ManagementPageBody = () => {
    return (
        <>
            <div className="management_body">
                <ManagementElementCard status={false} link="/Management/patient" per="" num="27" title="patient" desc="Manage patients" />
                <ManagementElementCard status={false} link="/Management/fundraiser" per="" num="27" title="fund-raiser" desc="Manage fund-raisers " />
                <ManagementElementCard status={false} link="/Management/banner" per="" num="27" title="banner" desc="Manage banners " />
                <ManagementElementCard status={false} link="/Management/our-partner" per="" num="27" title="Association" desc="Manage Association " />
                <ManagementElementCard status={false} link="/Management/blog" per="" num="27" title="blog" desc="Manage blogs " />
                <ManagementElementCard status={false} link="/Management/success-story" per="" num="27" title="success-story" desc="Manage success-story " />
                <ManagementElementCard status={false} link="/Management/faqs" per="" num="27" title="Faq" desc="Manage Faq " />
                <ManagementElementCard status={false} link="/Management/testimonial" per="" num="27" title="Testimonial" desc="Manage Testimonial " />
                <ManagementElementCard status={false} link="/Management/amenities" per="" num="27" title="Amenities" desc="Manage Amenities " />
                <ManagementElementCard status={false} link="/Management/key-point" per="" num="27" title="Key-Point" desc="Manage Key-Point " />
            </div>
        </>
    )
}

export default ManagementPageBody

import React, { useEffect, useContext } from 'react'
import { useState } from 'react';
import mainContext from '../../../contexts/mainContext';
import Organisation from '../../../Controller/Organisation';
import { ToastContainer, toast } from 'react-toastify';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function ViewTransactionApproval() {

    const context = useContext(mainContext);
    const url = new URLSearchParams(window.location.search);
    
    const { 
        setCanvasTitle,
        setinCanvas,  
        setCanvasSubTitle,
        setUserDetail,
        transactionApprovalDetail,
        allFunctions
    } = context;

    const { handleCanvasClose, getTransactionApprovalList } = allFunctions;
    const [flag, setFlag] = useState(false);

    const { transactionid, amount, date, receipt, userid, _id
    } = transactionApprovalDetail;

    const handleuserdata = async() => {

        const res = await Organisation.getuserdetail(userid);
        if(res.status === 200 || res.status === 201){
            setUserDetail(() => res.data);
            setinCanvas('view transaction user detail')
        }
    }

    const handlereject = async() => {
        const res = await Organisation.rejectusertransactionapproval(_id);
        if(res && (res.status === 200 || res.status === 201)){
            toast.success("Data Is Deleted Successfully",{
                pauseOnHover: false,
                theme: "light"
            });

            setTimeout(async() => {
                await getTransactionApprovalList(url.get('page'))
                await handleCanvasClose();
            }, 2000)
        }
        else{
            if(typeof(res.data.message) == 'object' )
                toast.error(res.data.message ? res.data.message[0] : "SomeThing Went Wrong",{
                    pauseOnHover: false,
                    theme: "light"
                });
            else{
                toast.error(res.data.message ? res.data.message : "SomeThing Went Wrong",{
                    pauseOnHover: false,
                    theme: "light"
                });
            }

        }
    }

    const handleApprove = async() => {
        if(flag) return;
        setFlag(true);

        const res = await Organisation.approveTransactionArpproval(_id);
        if(res && (res.status === 200 || res.status === 201)){
            toast.success("This Transaction is Approved Successfully",{
                pauseOnHover: false,
                theme: "light"
            });

            setTimeout(async() => {
                await getTransactionApprovalList(url.get('page'))
                await handleCanvasClose();
            }, 2000)
        }
        else{
            if(typeof(res.data.message) == 'object' )
                toast.error(res.data.message ? res.data.message[0] : "SomeThing Went Wrong",{
                    pauseOnHover: false,
                    theme: "light"
                });
            else{
                toast.error(res.data.message ? res.data.message : "SomeThing Went Wrong",{
                    pauseOnHover: false,
                    theme: "light"
                });
            }

        }

        setFlag(false);
    }
    
    useEffect(() => {
        setCanvasTitle("View");
        setCanvasSubTitle("Transaction Approval Request");
        // eslint-disable-next-line
    }, [])

  return (
    <>
            <ToastContainer autoClose={2000} position="top-center"  />
            <div className="add_company_main_div">
                <div className='upper_section'>

                    <div className='input_box'>
                        <label htmlFor="">Transaction ID</label>
                        <input type="text" placeholder='Enter fundraiser name' id='compnay_name' 
                            value={transactionid} disabled
                        />
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">Amount</label>
                        <input type="text" placeholder='Enter fundraiser name' id='compnay_name' 
                            value={amount} disabled
                        />
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">Receipt</label>
                        <a href={`${SERVER_URL}/${receipt}`} target={'_blank'} rel="noreferrer">{receipt}</a>
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">Date</label>
                        <input type="text" placeholder='Enter fundraiser name' id='compnay_name' 
                            value={date ? date.split('T')[0] : ''} disabled
                        />
                    </div>

                </div>
                <div className='lower_section' style={{ margin: "1rem 0", display: 'flex', gap: "2vh"  }}>
                    {
                        transactionApprovalDetail?.status !== 'approved' &&
                        <button style={{ background: 'white', color: 'green', border: '1px solid green' }} onClick={handleApprove} >Approve</button>
                    }
                    {
                        transactionApprovalDetail?.status !== 'approved' &&
                        <button style={{ background: 'white', color: 'tomato', border: '1px solid tomato' }} onClick={handlereject} >Reject</button>
                    }
                    <button style={{ background: 'white', color: 'blue', border: '1px solid blue' }} onClick={handleuserdata} >User-Detail</button>
                </div>
            </div>
    </>
  )
}

export default ViewTransactionApproval
import React, { useEffect, useContext, useState } from 'react'
import mainContext from '../../../contexts/mainContext';
import { ToastContainer, toast } from 'react-toastify';
// import UploadPhoto from '../../../Controller/UploadPhoto';
import Organisation from '../../../Controller/Organisation';

// const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function EditFaqDataSingleQuickLinksCard() {

    const context = useContext(mainContext);
    const [flag, setFlag] = useState(false);
    const url = new URLSearchParams(window.location.search);
    const { 
        setCanvasTitle,
        faqdataquickLinks,
        setCanvasSubTitle,
        allFunctions,
        faqdatasinglequickLinks
    } = context;

    const { handleCanvasClose, getFaqDataList } = allFunctions;

    const [howitworkdata, setHowItWorkData] = useState(faqdatasinglequickLinks);
    const { description, isavailable, title } = howitworkdata;

    // console.log({ faqdataquickLinks })

    const handleChange = (e) => {
        const { name, value } = e.target;
        setHowItWorkData((prevSatte) => {
            return { ...prevSatte, [name]: value }
        })
    }
    
    useEffect(() => {
        setCanvasTitle("Edit");
        setCanvasSubTitle("Faq-Data QuickLinks");
        // eslint-disable-next-line
    }, [])

    const handleSubmit = async() => {

        if(flag) return;
        setFlag(true);

        for(let i=0; i<faqdataquickLinks.quicklink.length; i++){
            if(faqdataquickLinks.quicklink[i]._id === howitworkdata._id){
                faqdataquickLinks.quicklink[i] = howitworkdata;
            }
        }

        const res = await Organisation.updateFaqData(faqdataquickLinks)
        // console.log({ res })
        if(res && (res.status === 200 || res.status === 201)){
            toast.success("Data Is Updated Successfully",{
                pauseOnHover: false,
                theme: "light"
            });

            setTimeout(async() => {
                await getFaqDataList(url.get('page'))
                await handleCanvasClose();
            }, 2000)
        }
        else{
            if(typeof(res.data.message) == 'object' )
                toast.error(res.data.message ? res.data.message[0] : "SomeThing Went Wrong",{
                    pauseOnHover: false,
                    theme: "light"
                });
            else{
                toast.error(res.data.message ? res.data.message : "SomeThing Went Wrong",{
                    pauseOnHover: false,
                    theme: "light"
                });
            }

        }

        setFlag(false);
    }

  return (
    <>
            <ToastContainer autoClose={2000} position="top-center"  />
            <div className="add_company_main_div">
                <div className='upper_section'>

                    <div className="input_box">
                        <div className="title">Available</div>
                        <div className="check_box" style={{display: "flex", alignItems: "center", gap: "6vh", fontSize: "24px"}}>
                            <div>
                                <input checked={isavailable === true} type="checkbox" 
                                    onChange={(e) => { setHowItWorkData((prevSatte) => {return { ...prevSatte, isavailable: true }}) }}  
                                    id="status_active" style={{ marginRight: '1vh' }} 
                                />
                                <label htmlFor="status_active">YES</label>
                            </div>
                            <div>
                                <input checked={isavailable === false} type="checkbox" 
                                    onChange={(e) => { setHowItWorkData((prevSatte) => {return { ...prevSatte, isavailable: false }}) }}  
                                    id="status_inactive" style={{ marginRight: '1vh' }} 
                                />
                                <label htmlFor="status_inactive">NO</label>
                            </div>
                        </div>
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">Title</label>
                        <input type="text" placeholder='Enter Title' id='compnay_name'
                            name="title" value={title} onChange={handleChange}
                        />
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">URL Path</label>
                        <input type="text" placeholder='Enter Title' id='compnay_name'
                            value={howitworkdata.url} onChange={(e) => { 
                                setHowItWorkData((initialState) => { return { ...initialState, url: e.target.value } })
                            }}
                        />
                    </div>

                    {/* <div className='input_box'>
                        <label htmlFor="">Question</label>
                        <input type="text" placeholder='Enter Question' id='compnay_name'
                            name="question" value={question} onChange={handleChange}
                        />
                    </div> */}

                    <div className='input_box'>
                        <label htmlFor="">Description</label>
                        <textarea type="text" placeholder='Enter Answer' id='compnay_name'
                            name="description" value={description} onChange={handleChange}
                        />
                    </div>


                </div>

            <div className='lower_section' style={{marginTop: "1rem"}}>
                <button onClick={handleSubmit}>Save And Proceed</button>
            </div>
        </div>
    </>
  )
}

export default EditFaqDataSingleQuickLinksCard
import React, { useContext, useState } from 'react'
import "react-toggle/style.css" 
import Toggle from 'react-toggle'
import mainContext from '../../../contexts/mainContext';
import organisation from '../../../Controller/Organisation';
import { ToastContainer, toast } from 'react-toastify';

const CompaniesListCard = (props) => {

    const context = useContext(mainContext);
    const { setinCanvas, setFundraiserDetail, allFunctions } = context;
    const { goalamount, collectedamount, healthissue, operationdate, added, status, item } = props;
    const { getFundRaiserList } = allFunctions;
    const [ flag, setFlag ] = useState(false);
    const url = new URLSearchParams(window.location.search);

    const handleClick = () => {
        setFundraiserDetail(() => { return item })
        setinCanvas('Edit Fund Raiser')
    }

    const handleData = async(status) => {

        if(flag) return;
        setFlag(() => true);

        let result = status === 'active' ? 'inactive' : 'active';
        const res = await organisation.updateFundRaiser({ _id: item._id, status: result });
        if(res && (res.status === 200 || res.status === 201)){
            toast.success("FundRaiser Is Updated Successfully",{
                pauseOnHover: false,
                theme: "light"
            });

            setTimeout(async() => {
                await getFundRaiserList(url.get('page'));
            }, 2000)
        }
        else{
            if(typeof(res.data.message) == 'object' )
                toast.error(res.data.message ? res.data.message[0] : "SomeThing Went Wrong",{
                    pauseOnHover: false,
                    theme: "light"
                });
            else{
                toast.error(res.data.message ? res.data.message : "SomeThing Went Wrong",{
                    pauseOnHover: false,
                    theme: "light"
                });
            }

        }

        setFlag(() => false);

    }

    return (
        <>
            <ToastContainer autoClose={2000} position="top-center"  />
            <div className="list_card companies_list_grid" style={{ gridTemplateColumns:" 3fr 3fr 3.5fr 6.5fr 4fr 4fr 4fr" }}>
                {/* <div>
                    <div className={`list_card_status status_${status}`}>{status}</div>
                </div> */}

                <Toggle
                    id='cheese-status'
                    checked={ status === 'active' ? true : false }
                    value={status}
                    onChange={() => handleData(status)} 
                />

                <div>{goalamount}</div>
                <div>{collectedamount}</div>
                <div>{healthissue}</div>
                <div>{operationdate}</div>
                <div style={{ textAlign: "right" }}>{added}</div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <button className="list_card_view_btn" onClick={handleClick}>Edit</button>
                </div>
            </div>
        </>
    )
}

export default CompaniesListCard

import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router';
import mainContext from '../../contexts/mainContext';
import './ManagementPage.css';
import FormBody from './ManagementPageBody/FormBody';

const Forms = () => {
    
    const location = useLocation();
    const context = useContext(mainContext);
    let { setProfileNavTitle } = context;
    
    useEffect(() => {
        setProfileNavTitle(() => "Forms");
    }, [setProfileNavTitle])

    return (
        <>
            <div className="management_main_div">
                {
                    location.pathname === "/Forms" &&
                    <FormBody />
                }
            </div>
        </>
    )
}

export default Forms

import React, { useContext } from 'react'
import mainContext from '../../../contexts/mainContext';

const FaqDataListCard = (props) => {

    const context = useContext(mainContext);
    const { setinCanvas, setfaqDataFundraiserDetail, setfaqDataQuickLinks } = context;
    const { item, fundraiser, quicklink, added } = props;

    const handleFundraiserEdit = () => {
        setfaqDataFundraiserDetail(() => { return { fundraiser: item.fundraiser, _id: item._id }});
        setinCanvas('Edit Faq Data FundRaiser Detail');
    }

    const handleQuickLinkEdit = () => {
        setfaqDataQuickLinks(() => { return { quicklink: item.quicklink, _id: item._id } });
        setinCanvas('Edit Faq Data QuickLink Detail')
    }

    return (
        <>
            <div className="list_card companies_list_grid" style={{ gridTemplateColumns:"5fr 5fr 2fr 5fr 3fr" }}>
                <div>{fundraiser?.length}</div>
                <div>{quicklink?.length}</div>
                <div style={{ textAlign: "right" }}>{added}</div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <button className="list_card_view_btn" onClick={handleFundraiserEdit}>Edit FundRaiser</button>
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <button className="list_card_view_btn" onClick={handleQuickLinkEdit}>Edit QuickLink</button>
                </div>
            </div>
        </>
    )
}

export default FaqDataListCard

import React, { useContext } from 'react'
import mainContext from '../../../contexts/mainContext';

const VideoListCard = (props) => {

    const context = useContext(mainContext);
    const { setinCanvas, setVideoDetail  } = context;
    const { item, slug, points, added } = props;

    const handleEdit = () => {
        setVideoDetail(() => item);
        setinCanvas('Edit Video');
    }

    return (
        <>
            <div className="list_card companies_list_grid" style={{ gridTemplateColumns:"5fr 5fr 2fr 5fr 3fr" }}>
                <div>{slug}</div>
                <div>{points?.length}</div>
                <div></div>
                <div style={{ textAlign: "right" }}>{added}</div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <button className="list_card_view_btn" onClick={handleEdit}>Edit Details</button>
                </div>
            </div>
        </>
    )
}

export default VideoListCard

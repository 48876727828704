import React, { useEffect, useContext, useState } from 'react'
import mainContext from '../../../contexts/mainContext';
import { ToastContainer, toast } from 'react-toastify';
import UploadPhoto from '../../../Controller/UploadPhoto';
import Organisation from '../../../Controller/Organisation';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function EditFundRaiserBankDetail() {

    const context = useContext(mainContext);
    const [flag, setFlag] = useState(false);
    const url = new URLSearchParams(window.location.search);

    const { 
        setCanvasTitle,
        setCanvasSubTitle,
        fundraiserDetail,
        allFunctions
    } = context;

    console.log({ fundraiserDetail })

    const { getFundRaiserList, handleCanvasClose } = allFunctions;

    // const organisationbankdetail = { 
    //     accountholdername: '', bankname: '', ifsccode: '', accountnumber: '',
    //     swiftcode: '', country: '', cancelcheque: ''
    // }

    const [ toogle, setToogle ] = useState(false);

    const patientbankdetail = {
        accountholdername: '', bankname: '', ifsccode: '', accountnumber: '',
        swiftcode: '', country: '', cancelcheque: ''
    }
    
    const [patientbankdata, setpatientbankdata] = useState(patientbankdetail);

    
    
    useEffect(() => {
        setCanvasTitle("Edit");
        setCanvasSubTitle("Bank Detail");

        if(fundraiserDetail.patientbankdetail){
            setpatientbankdata(() => { return fundraiserDetail.patientbankdetail });
            setToogle(true);
        }

        // eslint-disable-next-line
    }, [])

    const changeAvatar2 = async(e) => {

        const { name } = e.target;

        const err = await UploadPhoto.checkImage(e.target.files[0]);
        if(err) {
            return toast.warning(err,{
                pauseOnHover: false,
                theme: "light"
            });
        }

        const image = await UploadPhoto.uploadPhoto(e.target.files[0]);
        // console.log({ image })
        const { path } = image;
        setpatientbankdata((prev) => { return { ...prev, [name]: path } })


        // setImage(e.target.files[0]);
    }

    const handleImg = (id) => {
        // console.log('handle image')
        document.getElementById(id).click();
    }

    console.log({fundraiserDetail})

    const handleSubmit = async() => {
        let data = {
            ...fundraiserDetail,
            // organisationbankdetail: fundraiserdate,
        }

        if(toogle){
            data = { ...data, patientbankdetail: patientbankdata }
        }else{
            data.patientbankdetail = null;
        }

        // console.log({ data })
        // return;

        if(flag) return;
        setFlag(true);

        const res = await Organisation.updateFundRaiser(data)
        if(res && (res.status === 200 || res.status === 201)){
            toast.success("FundRaiser Is Updated Successfully",{
                pauseOnHover: false,
                theme: "light"
            });

            setTimeout(async() => {
                await getFundRaiserList(url.get('page'))
                await handleCanvasClose();
            }, 2000)
        }
        else{
            if(typeof(res.data.message) == 'object' )
                toast.error(res.data.message ? res.data.message[0] : "SomeThing Went Wrong",{
                    pauseOnHover: false,
                    theme: "light"
                });
            else{
                toast.error(res.data.message ? res.data.message : "SomeThing Went Wrong",{
                    pauseOnHover: false,
                    theme: "light"
                });
            }

        }

        setFlag(false);
    }

    

  return (
    <>
            <ToastContainer autoClose={2000} position="top-center"  />
            <div className="add_company_main_div">
                <div className='upper_section'>

                    {/* <h3>Organisation Bank Detail</h3> */}

                    {/* <div className='input_box'>
                        <label htmlFor="">Account Holder name</label>
                        <input type="text" placeholder='Enter goal amount' id='compnay_name' 
                            name="accountholdername" value={accountholdername} onChange={handleChange} 
                        />
                    </div> */}

                    {/* <div className='input_box'>
                        <label htmlFor="">Bank Name</label>
                        <input type="text" placeholder='Enter goal amount' id='compnay_name' 
                            name="bankname" value={bankname} onChange={handleChange} 
                        />
                    </div> */}

                    {/* <div className='input_box'>
                        <label htmlFor="">IFSC Code</label>
                        <input type="text" placeholder='Enter goal amount' id='compnay_name' 
                            name="ifsccode" value={ifsccode} onChange={handleChange} 
                        />
                    </div> */}

                    {/* <div className='input_box'>
                        <label htmlFor="">Account Number</label>
                        <input type="text" placeholder='Enter goal amount' id='compnay_name' 
                            name="accountnumber" value={accountnumber} onChange={handleChange} 
                        />
                    </div> */}

                    {/* <div className='input_box'>
                        <label htmlFor="">Swift Code</label>
                        <input type="text" placeholder='Enter goal amount' id='compnay_name' 
                            name="swiftcode" value={swiftcode} onChange={handleChange} 
                        />
                    </div> */}

                    {/* <div className='input_box'>
                        <label htmlFor="">Country</label>
                        <input type="text" placeholder='Enter goal amount' id='compnay_name' 
                            name="country" value={country} onChange={handleChange} 
                        />
                    </div> */}

                    {/* <div className='input_box upload_photo'>
                        <label htmlFor="">Cancel Cheque</label>
                        <input type="file" style={{ display: "none" }} id="img_input" accept=".jpg, .jpeg, .png" onChange={changeAvatar} name="cancelcheque" />
                        <div style={{ margin: "5% 0" }}>
                            {
                                cancelcheque !== ""
                                ? <img src={`${SERVER_URL}/${cancelcheque}`} style={{ width: "90px" }} alt="" />
                                : ""
                            }
                            <button onClick={() => handleImg('img_input')}>Upload Cancel Cheque</button>
                        </div>
                    </div> */}
                        
                    {/* <div className='input_box upload_photo' style={{ marginBottom: "1rem" }}>
                        <button onClick={() => { setToogle(!toogle) }}>Upload Patient Bank Detail Also</button>
                    </div> */}

                    <div className="input_box">
                        <div className="check_box" style={{display: "flex", alignItems: "center", gap: "6vh", fontSize: "24px"}}>
                            <div>
                                <input checked={toogle === true} type="checkbox"  onChange={() => { setToogle(!toogle) }}
                                    id="status_active" style={{ marginRight: '1vh' }} 
                                />
                                <label htmlFor="status_active">Add Patient Bank Detail</label>
                            </div>
                        </div>
                    </div>

                {   toogle &&
                    <>
                        <h3>Patient Bank Detail (Optional)</h3>

                        <div className='input_box'>
                            <label htmlFor="">Account Holder name</label>
                            <input type="text" placeholder='Enter goal amount' id='compnay_name' 
                                name="accountholdername" value={patientbankdata.accountholdername} 
                                onChange={(e) => 
                                    setpatientbankdata((prev) => { return { ...prev, accountholdername: e.target.value } }
                                )} 
                            />
                        </div>

                        <div className='input_box'>
                            <label htmlFor="">Bank Name</label>
                            <input type="text" placeholder='Enter goal amount' id='compnay_name' 
                                name="bankname" value={patientbankdata.bankname} 
                                onChange={(e) => 
                                    setpatientbankdata((prev) => { return { ...prev, bankname: e.target.value } }
                                )} 
                            />
                        </div>

                        <div className='input_box'>
                            <label htmlFor="">IFSC Code</label>
                            <input type="text" placeholder='Enter goal amount' id='compnay_name' 
                                name="ifsccode" value={patientbankdata.ifsccode} 
                                onChange={(e) => 
                                    setpatientbankdata((prev) => { return { ...prev, ifsccode: e.target.value } }
                                )} 
                            />
                        </div>

                        <div className='input_box'>
                            <label htmlFor="">Account Number</label>
                            <input type="text" placeholder='Enter goal amount' id='compnay_name' 
                                name="accountnumber" value={patientbankdata.accountnumber} 
                                onChange={(e) => 
                                    setpatientbankdata((prev) => { return { ...prev, accountnumber: e.target.value } }
                                )}
                            />
                        </div>

                        <div className='input_box'>
                            <label htmlFor="">Swift Code</label>
                            <input type="text" placeholder='Enter goal amount' id='compnay_name' 
                                name="swiftcode" value={patientbankdata.swiftcode} 
                                onChange={(e) => 
                                    setpatientbankdata((prev) => { return { ...prev, swiftcode: e.target.value } }
                                )} 
                            />
                        </div>

                        <div className='input_box'>
                            <label htmlFor="">Country</label>
                            <input type="text" placeholder='Enter goal amount' id='compnay_name' 
                                name="country" value={patientbankdata.country} 
                                onChange={(e) => 
                                    setpatientbankdata((prev) => { return { ...prev, country: e.target.value } }
                                )}  
                            />
                        </div>

                        <div className='input_box upload_photo'>
                            <label htmlFor="">Cancel Cheque</label>
                            <input type="file" style={{ display: "none" }} id="img_input_2" accept=".jpg, .jpeg, .png" onChange={changeAvatar2} name="cancelcheque" />
                            <div style={{ margin: "5% 0" }}>
                                {
                                    patientbankdata.cancelcheque !== ""
                                    ? <img src={`${SERVER_URL}/${patientbankdata.cancelcheque}`} style={{ width: "90px" }} alt="" />
                                    : ""
                                }
                                <button onClick={() => handleImg('img_input_2')}>Upload Cancel Cheque</button>
                            </div>
                        </div>
                    </>
                }

                </div>
                <div className='lower_section' style={{marginTop: "1rem"}}>
                    <button onClick={handleSubmit}>Save And Proceed</button>
                </div>
            </div>
    </>
  )
}

export default EditFundRaiserBankDetail
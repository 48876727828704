import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import LoginPage from './Components/Login/LoginPage.jsx';
import MainState from './contexts/MainState';
import HomePage from './Components/Home/HomePage';
import LogoutPage from './Components/Login/LogoutPage';

function App() {
  return (
    <>
      <MainState>
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/logout" element={<LogoutPage />} />
            <Route path="/" element={<HomePage />} />
            <Route path="/Admin-Notifications" element={<HomePage />} />
            <Route path="/Management" element={<HomePage />} />
            <Route path="/Pages" element={<HomePage />} />
            <Route path="/Forms" element={<HomePage />} />

            <Route path="/Management/patient" element={<HomePage />} />
            <Route path="/Management/fundraiser" element={<HomePage />} />
            <Route path="/Management/banner" element={<HomePage />} />
            <Route path="/Management/our-partner" element={<HomePage />} />
            <Route path="/Management/blog" element={<HomePage />} />
            <Route path="/Management/success-story" element={<HomePage />} />
            <Route path="/Management/faqs" element={<HomePage />} />
            <Route path="/Management/testimonial" element={<HomePage />} />
            <Route path="/Management/amenities" element={<HomePage />} />
            <Route path="/Management/key-point" element={<HomePage />} />


            <Route path="/Pages/pricing-and-fees" element={<HomePage />} />
            <Route path="/Pages/how-it-work" element={<HomePage />} />
            <Route path="/Pages/medical-crowdfunding" element={<HomePage />} />
            <Route path="/Pages/about-us" element={<HomePage />} />
            <Route path="/Pages/social-media" element={<HomePage />} />
            <Route path="/Pages/team" element={<HomePage />} />
            <Route path="/Pages/promote" element={<HomePage />} />
            <Route path="/Pages/video" element={<HomePage />} />
            <Route path="/Pages/trust-safety" element={<HomePage />} />
            <Route path="/Pages/faq-data" element={<HomePage />} />
            <Route path="/Pages/fundraising-tip" element={<HomePage />} />
            
            
            <Route path="/Forms/subscriber" element={<HomePage />} />
            <Route path="/Forms/fundraiser-approval" element={<HomePage />} />
            <Route path="/Forms/reports" element={<HomePage />} />
            <Route path="/Forms/career" element={<HomePage />} />
            <Route path="/Forms/transaction-approval" element={<HomePage />} />
            <Route path="/Forms/stripe-data" element={<HomePage />} />

          </Routes>
        </BrowserRouter>
      </MainState>
    </>
  );
}

export default App;

import React, { useEffect, useContext, useState } from 'react'
import mainContext from '../../../contexts/mainContext';
import { ToastContainer, toast } from 'react-toastify';
import Organisation from '../../../Controller/Organisation';

function AddSocialMedia() {

    const context = useContext(mainContext);
    const [flag, setFlag] = useState(false);
    const url = new URLSearchParams(window.location.search);
    const { 
        setCanvasTitle,
        setCanvasSubTitle,
        allFunctions
    } = context;

    const { handleCanvasClose, getSocialMediaList } = allFunctions;

    const intialState = { facebook: '', instagram: '', linkedin: '', twitter: '', whatsapp: '', youtube: '', telegram: '' };
    const [socialMediaData, setSocialMediaData] = useState(intialState);
    const { facebook, instagram, linkedin, twitter, whatsapp, youtube, telegram } = socialMediaData;


    const handleChange = (e) => {
        const { name, value } = e.target;
        setSocialMediaData((prevSatte) => {
            return { ...prevSatte, [name]: value }
        })
    }
    
    useEffect(() => {
        setCanvasTitle("Add");
        setCanvasSubTitle("Social Media (only one social media record is created at a time)");
        // eslint-disable-next-line
    }, [])

    const handleSubmit = async() => {

        if(flag) return;
        setFlag(true);

        const res = await Organisation.createSocialMedia(socialMediaData)
        // console.log({ res })
        if(res && (res.status === 200 || res.status === 201)){
            toast.success("Social Media Is Created Successfully",{
                pauseOnHover: false,
                theme: "light"
            });

            setTimeout(async() => {
                await getSocialMediaList(url.get('page'))
                await handleCanvasClose();
            }, 2000)
        }
        else{
            if(typeof(res.data.message) == 'object' )
                toast.error(res.data.message ? res.data.message[0] : "SomeThing Went Wrong",{
                    pauseOnHover: false,
                    theme: "light"
                });
            else{
                toast.error(res.data.message ? res.data.message : "SomeThing Went Wrong",{
                    pauseOnHover: false,
                    theme: "light"
                });
            }

        }

        setFlag(false);
    }

  return (
    <>
            <ToastContainer autoClose={2000} position="top-center"  />
            <div className="add_company_main_div">
                <div className='upper_section'>

                <div className='input_box'>
                    <label htmlFor="">Facebook</label>
                    <input type="text" placeholder='Enter Detail' id='compnay_name'
                        name="facebook" value={facebook} onChange={handleChange}
                    />
                </div>

                <div className='input_box'>
                    <label htmlFor="">Instagram</label>
                    <input type="text" placeholder='Enter Detail' id='compnay_name'
                        name="instagram" value={instagram} onChange={handleChange}
                    />
                </div>

                <div className='input_box'>
                    <label htmlFor="">Linkedin</label>
                    <input type="text" placeholder='Enter Detail' id='compnay_name'
                        name="linkedin" value={linkedin} onChange={handleChange}
                    />
                </div>

                <div className='input_box'>
                    <label htmlFor="">Twitter</label>
                    <input type="text" placeholder='Enter Detail' id='compnay_name'
                        name="twitter" value={twitter} onChange={handleChange}
                    />
                </div>

                <div className='input_box'>
                    <label htmlFor="">Whats-App</label>
                    <input type="text" placeholder='Enter Detail' id='compnay_name'
                        name="whatsapp" value={whatsapp} onChange={handleChange}
                    />
                </div>

                <div className='input_box'>
                    <label htmlFor="">Telegram</label>
                    <input type="text" placeholder='Enter Detail' id='compnay_name'
                        name="telegram" value={telegram} onChange={handleChange}
                    />
                </div>

                <div className='input_box'>
                    <label htmlFor="">You-Tube</label>
                    <input type="text" placeholder='Enter Detail' id='compnay_name'
                        name="youtube" value={youtube} onChange={handleChange}
                    />
                </div>

                </div>
                <div className='lower_section' style={{marginTop: "1rem"}}>
                    <button onClick={handleSubmit}>Save And Proceed</button>
                </div>
            </div>
    </>
  )
}

export default AddSocialMedia
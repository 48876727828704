import React from 'react'
import "./ManagementPageBody.css"
import ManagementElementCard from "./ManagementElementCard.jsx"

const FormBody = () => {
    return (
        <>
            <div className="management_body">
                <ManagementElementCard status={false} link="/Forms/subscriber" per="" num="27" title="Subscriber" desc="Manage Your Subscribers" />
                <ManagementElementCard status={false} link="/Forms/fundraiser-approval" per="" num="27" title="Fund Raiser Approval" desc="Manage Fund Raiser Approval" />
            </div>
        </>
    )
}

export default FormBody

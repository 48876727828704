import React, { useEffect, useContext, useState } from 'react'
import mainContext from '../../../contexts/mainContext';
import { ToastContainer, toast } from 'react-toastify';
import Organisation from '../../../Controller/Organisation';

function AddAboutus() {

    const context = useContext(mainContext);
    const [flag, setFlag] = useState(false);
    const url = new URLSearchParams(window.location.search);
    const { 
        setCanvasTitle,  
        setCanvasSubTitle,
        allFunctions
    } = context;

    const { handleCanvasClose, getAboutList } = allFunctions;
    
    useEffect(() => {
        setCanvasTitle("Add new");
        setCanvasSubTitle("About-us");
        // eslint-disable-next-line
    }, [])


    const handleSubmit = async() => {

        const data = { name: [], mission: [] };

        if(flag) return;
        setFlag(true);

        const res = await Organisation.createAbout(data)
        // console.log({ res })
        if(res && (res.status === 200 || res.status === 201)){
            toast.success("About us Is Created Successfully",{
                pauseOnHover: false,
                theme: "light"
            });

            setTimeout(async() => {
                await getAboutList(url.get('page'))
                await handleCanvasClose();
            }, 2000)
        }
        else{
            if(typeof(res.data.message) == 'object' )
                toast.error(res.data.message ? res.data.message[0] : "SomeThing Went Wrong",{
                    pauseOnHover: false,
                    theme: "light"
                });
            else{
                toast.error(res.data.message ? res.data.message : "SomeThing Went Wrong",{
                    pauseOnHover: false,
                    theme: "light"
                });
            }

        }

        setFlag(false);
    }

  return (
    <>
            <ToastContainer autoClose={2000} position="top-center"  />
            <div className="add_company_main_div">
                
                <div className='lower_section' style={{marginTop: "1rem"}}>
                    <button onClick={handleSubmit}>Save And Proceed</button>
                </div>
            </div>
    </>
  )
}

export default AddAboutus
import React, { useContext, useEffect, useState } from 'react';
import FundRaisingTipListCard from "./FundRaisingTipCard.jsx";
import SearchHeader from '../../SearchHeader/SearchHeader.jsx';
import "./FundRaisingTipPage.css";
import mainContext from '../../../contexts/mainContext.jsx';
import Pagination from '../../Pagination/Pagination.jsx';
import { useNavigate } from 'react-router-dom';
import Organisation from '../../../Controller/Organisation.jsx';
import { ToastContainer, toast } from 'react-toastify';
import Cards from './Cards.jsx';

const FundRaisingTipPage = () => {
    
    const context = useContext(mainContext);
    const url = new URLSearchParams(window.location.search);
    const navigate = useNavigate();

    const [ fundRaisingTipList, setFundRaisingTipList ] = useState([])
    
    let {
        param,
        setProfileNavTitle,
        totalFetchDataCount,
        setTotalFetchDataCount,
        setAllFunctions,
        allFunctions
    } = context;
    
    const getFundRaisingTipList = async(page = 1, pass = undefined) => {
        if(!page)
            page = 1;
        navigate(`?page=${page}`)
        if(pass){
            param = pass;
        }
        param.page = page;
        const res = await Organisation.getFundRaisingTipList(param)
        // console.log({ res })
        if(res.status === 200 || res.status === 201){
            setFundRaisingTipList(() => res.data.results)
            setTotalFetchDataCount(res.data.count)
        }else{
            toast.error(res.data.message ? res.data.message : "SomeThing Went Wrong",{
                pauseOnHover: false,
                theme: "light"
            });
        }
    }

    useEffect(() => {

        getFundRaisingTipList(url.get("page"));
        setProfileNavTitle("Management");
        setAllFunctions({ ...allFunctions, getFundRaisingTipList })
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <ToastContainer autoClose={2000} position="top-center"  />
            <SearchHeader callFunction={() => {}} addMoreSection={true} addSubTitle={true} subTitle="fundraising-tip" records={totalFetchDataCount} searchPlaceHolder="Search fundraising-tip" />
            <div className="component_body">
                <div className="list_header companies_list_grid" style={{ gridTemplateColumns:"3fr 6fr 5fr 4fr" }}>
                    {/* <div></div> */}
                    <div>Card</div>
                    <div>Heading</div>
                    {/* <div></div> */}
                    
                    <div style={{ textAlign: "right" }}>Added on</div>
                    {/* <div></div> */}
                </div>

                <div>

                    {
                        fundRaisingTipList.length <= 0 ?
                            <div style={{ marginTop: "2vh" }}> No record found</div>
                        :
                            <>
                                {
                                    fundRaisingTipList.map((item) => {
                                        return (
                                            <FundRaisingTipListCard
                                                key={item._id}
                                                item={item} 
                                                card={item?.card}
                                                heading={item?.heading}
                                                added={item.createdAt ? item.createdAt.split('T')[0] : ''} 
                                            />
                                        )
                                    })
                                }
                            </>
                    }

                </div>

                <>
                    <br />
                    <h3>Cards</h3>
                    <div className="list_header companies_list_grid" style={{ gridTemplateColumns:"3fr 3fr 1fr 3fr" }}>
                        <div>IsAvailable</div>
                        <div>Title</div>
                        <div style={{ textAlign: 'center' }}>Image</div>
                        <div></div>
                    </div>
                    <div>
                        {
                            fundRaisingTipList.length <= 0 ?
                                <div style={{ marginTop: "2vh" }}> No Cards Present</div>
                            :  
                                fundRaisingTipList[0].card?.map((item, index) => {
                                    return (
                                        <Cards 
                                            title={item.title}
                                            image={item.image}
                                            isavailable={item.isavailable}
                                            item={item}
                                            key={index}
                                            data={fundRaisingTipList[0]}
                                        />
                                    )
                                })
                        }
                        
                    </div>
                </>

            </div>
            {
                param.limit < totalFetchDataCount ?
                    <>
                        {
                            param.limit >= totalFetchDataCount ?
                                ""
                                :
                                <Pagination totalPage={Math.ceil(totalFetchDataCount / param.limit)} callFunction={getFundRaisingTipList} />
                        }
                    </>
                    :
                    ""
            }
        </>
    )
}

export default FundRaisingTipPage

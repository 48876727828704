import React, { useEffect, useContext, useState } from 'react'
import mainContext from '../../../contexts/mainContext';
import { ToastContainer, toast } from 'react-toastify';
import UploadPhoto from '../../../Controller/UploadPhoto';
import Organisation from '../../../Controller/Organisation';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function EditFundRaisingTipCard() {

    const context = useContext(mainContext);
    const [flag, setFlag] = useState(false);
    const url = new URLSearchParams(window.location.search);
    const { 
        setCanvasTitle,
        fundraisingtipcardDetail, 
        setFundRaisingTipCardDetail,
        setCanvasSubTitle,
        allFunctions
    } = context;

    const { handleCanvasClose, getFundRaisingTipList } = allFunctions;

    const initialState = { points: [], isavailable: '', title: '', image: '' }
    const [howitworkdata, setHowItWorkData] = useState(initialState);
    const { points, isavailable, title, image } = howitworkdata;
    const [ pointcontent, setpointcontent ] = useState('')

    // console.log({ fundraisingtipcardDetail })

    const handleChange = (e) => {
        const { name, value } = e.target;
        setHowItWorkData((prevSatte) => {
            return { ...prevSatte, [name]: value }
        })
    }
    
    useEffect(() => {
        setCanvasTitle("Edit");
        setCanvasSubTitle("Fund Raising Tip Card");
        // eslint-disable-next-line
    }, [])

    const changeAvatar = async(e) => {

        const { name } = e.target;

        const err = await UploadPhoto.checkImage(e.target.files[0]);
        if(err) {
            return toast.warning(err,{
                pauseOnHover: false,
                theme: "light"
            });
        }

        const image = await UploadPhoto.uploadPhoto(e.target.files[0]);
        // console.log({ image })
        const { path } = image;
        setHowItWorkData((prevSatte) => {
            return { ...prevSatte, [name]: path }
        })

        // setImage(e.target.files[0]);
    }

    const handleImg = (id) => {
        // console.log('handle image')
        document.getElementById(id).click();
    }

    const handleSubmit = async() => {

        if(flag) return;
        setFlag(true);

        const res = await Organisation.updateFundraisingTip(fundraisingtipcardDetail)
        // console.log({ res })
        if(res && (res.status === 200 || res.status === 201)){
            toast.success("Data Is Updated Successfully",{
                pauseOnHover: false,
                theme: "light"
            });

            setTimeout(async() => {
                await getFundRaisingTipList(url.get('page'))
                await handleCanvasClose();
            }, 2000)
        }
        else{
            if(typeof(res.data.message) == 'object' )
                toast.error(res.data.message ? res.data.message[0] : "SomeThing Went Wrong",{
                    pauseOnHover: false,
                    theme: "light"
                });
            else{
                toast.error(res.data.message ? res.data.message : "SomeThing Went Wrong",{
                    pauseOnHover: false,
                    theme: "light"
                });
            }

        }

        setFlag(false);
    }

    function handleDelete(index){
        
        const newArr = [ ...points ];
        newArr.splice(index, 1);

        setHowItWorkData((prevSatte) => {
            return { ...prevSatte, points: [ ...newArr ] }
        })
    }

    function handleEdit(index, item){
        const newArr = [ ...points ];
        newArr.splice(index, 1);

        setHowItWorkData((prevSatte) => {
            return { ...prevSatte, points: [ ...newArr ] }
        })

        setpointcontent(() => item);
    }

    function handlePoint(){
        setHowItWorkData((prev) => {
            return { ...prev, points: [ ...prev.points, pointcontent ] }
        })
        setpointcontent(() => '');
    }

    function handlecardData(){
        
        setFundRaisingTipCardDetail((prev) => {
            return { ...prev, card: [ ...prev.card, howitworkdata ] }
        })

        setHowItWorkData(() => initialState);
    }

    function handleDeleteCard(index){
        const newArr = [ ...fundraisingtipcardDetail.card ];
        newArr.splice(index, 1);

        setFundRaisingTipCardDetail((prevSatte) => {
            return { ...prevSatte, card: [ ...newArr ] }
        })
    }

    function handleEditCard(index, item){
        const newArr = [ ...fundraisingtipcardDetail.card ];
        newArr.splice(index, 1);

        setFundRaisingTipCardDetail((prevSatte) => {
            return { ...prevSatte, card: [ ...newArr ] }
        })

        setHowItWorkData(() => item);
    }

  return (
    <>
            <ToastContainer autoClose={2000} position="top-center"  />
            <div className="add_company_main_div">
                <div className='upper_section'>

                    <div className="input_box">
                        <div className="title">Available</div>
                        <div className="check_box" style={{display: "flex", alignItems: "center", gap: "6vh", fontSize: "24px"}}>
                            <div>
                                <input checked={isavailable === true} type="checkbox" 
                                    onChange={(e) => { setHowItWorkData((prevSatte) => {return { ...prevSatte, isavailable: true }}) }}  
                                    id="status_active" style={{ marginRight: '1vh' }} 
                                />
                                <label htmlFor="status_active">YES</label>
                            </div>
                            <div>
                                <input checked={isavailable === false} type="checkbox" 
                                    onChange={(e) => { setHowItWorkData((prevSatte) => {return { ...prevSatte, isavailable: false }}) }}  
                                    id="status_inactive" style={{ marginRight: '1vh' }} 
                                />
                                <label htmlFor="status_inactive">NO</label>
                            </div>
                        </div>
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">Title</label>
                        <input type="text" placeholder='Enter Title' id='compnay_name'
                            name="title" value={title} onChange={handleChange}
                        />
                    </div>

                    <div className='input_box upload_photo'>
                        <label htmlFor="">Image</label>
                        <input type="file" style={{ display: "none" }} id="img_input" accept=".jpg, .jpeg, .png" onChange={changeAvatar} name="image" />
                        <div style={{ margin: "5% 0" }}>
                            {
                                image !== ""
                                ? <img src={`${SERVER_URL}/${image}`} style={{ width: "90px" }} alt="" />
                                : ""
                            }
                            <button onClick={() => handleImg('img_input')}>Upload image</button>
                        </div>
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">Points</label>
                        <input type="text" placeholder='Enter Points' id='compnay_name'
                            value={pointcontent} onChange={(e) => setpointcontent(e.target.value)}
                        />
                    </div>

                    <div className='lower_section'>
                        <button style={{ margin: "10px 0", width: '17vh', padding: '0.5rem 0' }} onClick={handlePoint}>Add</button>
                    </div>

                    {
                        points &&
                        points.length > 0 &&
                        points.map((item, index) => {
                            return (
                                <div key={index} style={{ display: 'flex', alignItems: 'center', gap: '3vh' }}>
                                    <p style={{ margin: '5px 0', width: '90%' }}>{item}</p>
                                    <i className="fas fa-edit" style={{ cursor: 'pointer' }}
                                        onClick={() => handleEdit(index, item)}
                                    ></i>
                                    <i className="fa fa-trash" aria-hidden="true" style={{ cursor: 'pointer' }} 
                                        onClick={() => handleDelete(index)}
                                    ></i>
                                </div>
                            )
                        })
                    }

                    <div className='lower_section' style={{marginTop: "1rem"}}>
                        <button onClick={handlecardData}>Add Card</button>
                    </div>

                    {/* <div className='input_box'>
                        <label htmlFor="">Question</label>
                        <input type="text" placeholder='Enter Question' id='compnay_name'
                            name="question" value={question} onChange={handleChange}
                        />
                    </div> */}

                    {/* <div className='input_box'>
                        <label htmlFor="">Description</label>
                        <textarea type="text" placeholder='Enter Answer' id='compnay_name'
                            name="description" value={description} onChange={handleChange}
                        />
                    </div> */}

                    {/* <div className='lower_section'>
                        <button onClick={handleFundData} style={{ margin: '1rem 0' }}>Add Data</button>
                    </div> */}

                    {
                        fundraisingtipcardDetail &&
                        fundraisingtipcardDetail.card &&
                        fundraisingtipcardDetail.card.length > 0 &&
                        fundraisingtipcardDetail.card.map((item, index) => {
                            return (
                                <div key={index} style={{ display: 'flex', alignItems: 'center', gap: '3vh' }}>
                                    <p style={{ margin: '5px 0', width: '90%', color: `${item.isavailable ? 'green' : 'red'}` }}>{item?.title}</p>
                                    <i className="fas fa-edit" style={{ cursor: 'pointer' }}
                                        onClick={() => handleEditCard(index, item)}
                                    ></i>
                                    <i className="fa fa-trash" aria-hidden="true" style={{ cursor: 'pointer' }} 
                                        onClick={() => handleDeleteCard(index)}
                                    ></i>
                                </div>
                            )
                        })
                    }

                </div>

            <div className='lower_section' style={{marginTop: "1rem"}}>
                <button onClick={handleSubmit}>Save And Proceed</button>
            </div>
        </div>
    </>
  )
}

export default EditFundRaisingTipCard
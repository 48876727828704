import React, { useEffect, useContext, useState } from 'react'
import mainContext from '../../../contexts/mainContext';
import { ToastContainer, toast } from 'react-toastify';
import Organisation from '../../../Controller/Organisation';

function AddPricingFees() {

    const context = useContext(mainContext);
    const [flag, setFlag] = useState(false);
    const url = new URLSearchParams(window.location.search);
    const { 
        setCanvasTitle,
        // setinCanvas,  
        setCanvasSubTitle,
        allFunctions
    } = context;

    const { handleCanvasClose, getPricingFeesList } = allFunctions;

    const initialState = { 
        subscriptiontype: '', fees: 0, title: 'collaborate', service: []
    }

    const [ keypointData, setKeyPointData] = useState(initialState);
    const { subscriptiontype, fees, title, service } = keypointData;
    const [ pointContent, setPointContent ] = useState('');
    const [ isinlist, setisinlist ] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setKeyPointData((prevSatte) => {
            return { ...prevSatte, [name]: value }
        })
    }
    
    useEffect(() => {
        setCanvasTitle("Add new");
        setCanvasSubTitle("pricingfees");
        // eslint-disable-next-line
    }, [])


    const handleSubmit = async() => {

        if(flag) return;
        setFlag(true);

        const res = await Organisation.createPricingFees(keypointData)
        // console.log({ res })
        if(res && (res.status === 200 || res.status === 201)){
            toast.success("PricingFees Is Created Successfully",{
                pauseOnHover: false,
                theme: "light"
            });

            setTimeout(async() => {
                await getPricingFeesList(url.get('page'))
                await handleCanvasClose();
            }, 2000)
        }
        else{
            if(typeof(res.data.message) == 'object' )
                toast.error(res.data.message ? res.data.message[0] : "SomeThing Went Wrong",{
                    pauseOnHover: false,
                    theme: "light"
                });
            else{
                toast.error(res.data.message ? res.data.message : "SomeThing Went Wrong",{
                    pauseOnHover: false,
                    theme: "light"
                });
            }

        }

        setFlag(false);
    }

    const handlePoint = () => {
        const data = {
            servicename: pointContent,
            isavailable: isinlist
        }

        setKeyPointData((prevSatte) => {
            return { ...prevSatte, service: [...prevSatte.service, data] }
        })

        setPointContent(() => '')
        setisinlist(() => false);
    }

    function handleDelete(index){
        const newArr = [...service];
        newArr.splice(index, 1);
        setKeyPointData((prevSatte) => {
            return { ...prevSatte, service: newArr }
        })
    }

  return (
    <>
            <ToastContainer autoClose={2000} position="top-center"  />
            <div className="add_company_main_div">
                <div className='upper_section'>

                    <div className='input_box'>
                        <label htmlFor="">SubscriptionType</label>
                        <input type="text" placeholder='Enter Name' id='compnay_name'
                            name="subscriptiontype" value={subscriptiontype} onChange={handleChange}
                        />
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">Fees</label>
                        <input type="number" placeholder='Enter Fees' id='compnay_name'
                            name="fees" value={fees} onChange={handleChange}
                        />
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">Title</label>
                        <div>
                            <select name="title" value={title} onChange={handleChange}>
                                <option value="collaborate">Collaborate</option>
                                <option value="assisted">Assisted</option>
                                <option value="self-driven">Self-Driven</option>
                            </select>
                            
                        </div>
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center', gap: '3vh', justifyContent: 'space-between' }}>
                        <div className='input_box' style={{ flex: 1 }}>
                            <label htmlFor="">Service</label>
                            <input type="text" placeholder='Enter Service' id='compnay_name' value={pointContent} onChange={(e) => setPointContent(() => e.target.value)} />
                        </div>
                        <div className="input_box">
                            <div className="title">IsAvaiable</div>
                            <div className="check_box" style={{display: "flex", alignItems: "center", gap: "6vh", fontSize: "24px"}}>
                                <div>
                                    <input type="checkbox" checked={isinlist === true} 
                                        onChange={()=> setisinlist(() => true)}  
                                        id="status_active" style={{ marginRight: '1vh' }} 
                                    />
                                    <label htmlFor="status_active">Yes</label>
                                </div>
                                <div>
                                    <input type="checkbox" checked={isinlist === false} 
                                        onChange={()=> setisinlist(() => false)}  
                                        id="status_inactive" style={{ marginRight: '1vh' }} 
                                    />
                                    <label htmlFor="status_inactive">No</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='lower_section'>
                        <button style={{ margin: "10px 0", width: '17vh', padding: '0.5rem 0' }} onClick={handlePoint}>Add</button>
                    </div>

                    {
                        service &&
                        service.length > 0 &&
                        service.map((item, index) => {
                            return (
                                <div key={index} style={{ display: 'flex', alignItems: 'center', gap: '3vh' }}>
                                    <p style={{ margin: '5px 0', width: '90%', color: `${item.isavailable ? 'green' : 'red' }` }}>{item?.servicename}</p>
                                    <i className="fa fa-trash" aria-hidden="true" style={{ cursor: 'pointer' }} onClick={() => handleDelete(index)}></i>
                                </div>
                            )
                        })
                    }

                </div>
                <div className='lower_section' style={{marginTop: "1rem"}}>
                    <button onClick={handleSubmit}>Save And Proceed</button>
                </div>
            </div>
    </>
  )
}

export default AddPricingFees
import React, { useContext, useEffect, useState } from 'react';
import SocialMediaListCard from "./SocialMediaCard.jsx";
import SearchHeader from '../../SearchHeader/SearchHeader.jsx';
import "./SocialMediaPage.css";
import mainContext from '../../../contexts/mainContext.jsx';
import Pagination from '../../Pagination/Pagination.jsx';
import { useNavigate } from 'react-router-dom';
import Organisation from '../../../Controller/Organisation.jsx';
import { ToastContainer, toast } from 'react-toastify';

const SocialMediaPage = () => {
    
    const context = useContext(mainContext);
    const url = new URLSearchParams(window.location.search);
    const navigate = useNavigate();

    const [ socialmediaList, setSocialMediaList ] = useState([])
    
    let {
        param,
        setProfileNavTitle,
        totalFetchDataCount,
        setTotalFetchDataCount,
        setAllFunctions,
        allFunctions
    } = context;
    
    const getSocialMediaList = async(page = 1, pass = undefined) => {
        if(!page)
            page = 1;
        navigate(`?page=${page}`)
        if(pass){
            param = pass;
        }
        param.page = page;
        const res = await Organisation.getSocialMediaDetail()
        // console.log({ res })
        if(res.status === 200 || res.status === 201){
            setSocialMediaList(() => res.data)
            setTotalFetchDataCount(res.data.count)
        }else{
            toast.error(res.data.message ? res.data.message : "SomeThing Went Wrong",{
                pauseOnHover: false,
                theme: "light"
            });
        }
    }

    useEffect(() => {

        getSocialMediaList(url.get("page"));
        setProfileNavTitle("Management");
        setAllFunctions({ ...allFunctions, getSocialMediaList })
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <ToastContainer autoClose={2000} position="top-center"  />
            <SearchHeader callFunction={() => {}} addMoreSection={true} addSubTitle={true} subTitle="social-media" records={totalFetchDataCount} searchPlaceHolder="Search social-media" />
            <div className="component_body">
                <div className="list_header companies_list_grid" style={{ gridTemplateColumns:"5fr 5fr 5fr 5fr 3fr" }}>
                    <div>Facebook</div>
                    <div>Instagram</div>
                    <div>Linkedin</div>
                    <div style={{ textAlign: "right" }}>YouTube</div>

                </div>
                <div>

                    {
                        !socialmediaList ?
                            <div style={{ marginTop: "2vh" }}> No record found</div>
                        :
                            <>
                                <SocialMediaListCard
                                    Facebook={socialmediaList?.facebook}
                                    Instagram={socialmediaList?.instagram}
                                    Linkedin={socialmediaList?.linkedin}
                                    YouTube={socialmediaList?.youtube}
                                    data={socialmediaList}
                                />
                            </>
                    }

                </div>
            </div>
            {
                param.limit < totalFetchDataCount ?
                    <>
                        {
                            param.limit >= totalFetchDataCount ?
                                ""
                                :
                                <Pagination totalPage={Math.ceil(totalFetchDataCount / param.limit)} callFunction={getSocialMediaList} />
                        }
                    </>
                    :
                    ""
            }
        </>
    )
}

export default SocialMediaPage

import React, { useContext, useEffect, useState } from 'react';
import FaqDataListCard from "./FaqDataCard.jsx";
import SearchHeader from '../../SearchHeader/SearchHeader.jsx';
import "./FaqDataPage.css";
import mainContext from '../../../contexts/mainContext.jsx';
import Pagination from '../../Pagination/Pagination.jsx';
import { useNavigate } from 'react-router-dom';
import Organisation from '../../../Controller/Organisation.jsx';
import { ToastContainer, toast } from 'react-toastify';
import Fundraiser from './fundraiser.jsx';
import QuickLinks from './quicklinks.jsx';

const FaqDataPage = () => {
    
    const context = useContext(mainContext);
    const url = new URLSearchParams(window.location.search);
    const navigate = useNavigate();

    const [ faqDataList, setFaqDataList ] = useState([])
    
    let {
        param,
        setProfileNavTitle,
        totalFetchDataCount,
        setTotalFetchDataCount,
        setAllFunctions,
        allFunctions
    } = context;
    
    const getFaqDataList = async(page = 1, pass = undefined) => {
        if(!page)
            page = 1;
        navigate(`?page=${page}`)
        if(pass){
            param = pass;
        }
        param.page = page;
        const res = await Organisation.getFaqDataList(param)
        // console.log({ res })
        if(res.status === 200 || res.status === 201){
            setFaqDataList(() => res.data.results)
            setTotalFetchDataCount(res.data.count)
        }else{
            toast.error(res.data.message ? res.data.message : "SomeThing Went Wrong",{
                pauseOnHover: false,
                theme: "light"
            });
        }
    }

    useEffect(() => {

        getFaqDataList(url.get("page"));
        setProfileNavTitle("Management");
        setAllFunctions({ ...allFunctions, getFaqDataList })
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <ToastContainer autoClose={2000} position="top-center"  />
            <SearchHeader callFunction={() => {}} addMoreSection={true} addSubTitle={true} subTitle="faq-data" records={totalFetchDataCount} searchPlaceHolder="Search faq-data" />
            <div className="component_body">
                <div className="list_header companies_list_grid" style={{ gridTemplateColumns:"5fr 5fr 2fr 5fr 3fr" }}>
                    <div>FundRaiser</div>
                    <div>QuickLinks</div>
                    <div>Added on</div>
                    <div style={{ textAlign: "right" }}></div>

                </div>
                
                <div>

                    {
                        faqDataList.length <= 0 ?
                            <div style={{ marginTop: "2vh" }}> No record found</div>
                        :
                            <>

                                {
                                    faqDataList.map((item) => {
                                        return (
                                            <FaqDataListCard
                                                key={item._id}
                                                fundraiser={item?.fundraiser}
                                                quicklink={item?.quicklink}
                                                item={item}
                                                added={item.createdAt ? item.createdAt.split('T')[0] : ''} 
                                            />
                                        )
                                    })
                                }
                                
                            </>
                    }

                </div>

                <>
                    <br />
                    <h3>Fund Raiser</h3>
                    <div className="list_header companies_list_grid" style={{ gridTemplateColumns:"3fr 3fr 6fr 1fr" }}>
                        <div>Title</div>
                        <div>Question</div>
                        <div style={{ textAlign: 'center' }}>Answer</div>
                        <div></div>
                    </div>
                    <div>
                        {
                            faqDataList.length <= 0 ?
                                <div style={{ marginTop: "2vh" }}> No Fund Raiser Present</div>
                            :  
                                faqDataList[0].fundraiser?.map((item, index) => {

                                    return (
                                        <Fundraiser 
                                            title={item.title}
                                            question={item.question}
                                            answer={item.answer}
                                            item={item}
                                            key={index}
                                            data={faqDataList[0]}
                                        />
                                    )
                                })
                        }
                        
                    </div>
                </>

                <>
                    <br />
                    <h3>Quick Links</h3>
                    <div className="list_header companies_list_grid" style={{ gridTemplateColumns:"3fr 3fr 6fr 3fr" }}>
                        <div>IsAvailable</div>
                        <div>Title</div>
                        <div style={{ textAlign: 'center' }}>URL</div>
                        <div></div>
                    </div>
                    <div>
                        {
                            faqDataList.length <= 0 ?
                                <div style={{ marginTop: "2vh" }}> No Quick Links Present</div>
                            :  
                                faqDataList[0].quicklink?.map((item, index) => {

                                    return (
                                        <QuickLinks 
                                            title={item.title}
                                            url={item.url}
                                            isavailable={item.isavailable}
                                            item={item}
                                            key={index}
                                            data={faqDataList[0]}
                                        />
                                    )
                                })
                        }
                        
                    </div>
                </>


            </div>
            {
                param.limit < totalFetchDataCount ?
                    <>
                        {
                            param.limit >= totalFetchDataCount ?
                                ""
                                :
                                <Pagination totalPage={Math.ceil(totalFetchDataCount / param.limit)} callFunction={getFaqDataList} />
                        }
                    </>
                    :
                    ""
            }
        </>
    )
}

export default FaqDataPage

import React, { useEffect, useContext } from 'react'
import mainContext from '../../../contexts/mainContext';

function ViewReport() {

    const context = useContext(mainContext);
    
    const { 
        setCanvasTitle,
        setCanvasSubTitle,
        reportDetail,
    } = context;

    const { name, email, description, fundraiserurl } = reportDetail;
    
    useEffect(() => {
        setCanvasTitle("View");
        setCanvasSubTitle("Report Detail");
        // eslint-disable-next-line
    }, [])

  return (
    <>
            <div className="add_company_main_div">
                <div className='upper_section'>

                    <div className='input_box'>
                        <label htmlFor="">Name</label>
                        <input type="text" placeholder='Enter name' id='compnay_name' 
                            value={name} disabled
                        />
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">Email</label>
                        <input type="text" placeholder='Enter email' id='compnay_name' 
                            value={email} disabled
                        />
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">FundRaiser Url</label>
                        <input type="text" placeholder='Enter email' id='compnay_name' 
                            value={fundraiserurl} disabled
                        />
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">Description</label>
                        <textarea type="text" placeholder='Enter description' 
                            value={description} disabled
                        />
                    </div>


                </div>
            </div>
    </>
  )
}

export default ViewReport
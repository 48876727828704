import React, { useContext, useEffect } from 'react'
import mainContext from '../../../contexts/mainContext'
const Profile = () => {
    
    const context = useContext(mainContext);
    const { setCanvasTitle, setinCanvas, loginUserInfo } = context;
    console.log({ loginUserInfo })
    
    const handleClick = () => {
        setinCanvas("Edit admin profile")
    }
    
    useEffect(() => {
        setCanvasTitle("Your profile");
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <div className="profile_main_div ">
                <div className='upper_section'>
                    <div className='input_box' style={{ padding: "0" }}>
                        <div>Profile image</div>
                        <div className='img_div'><img src="https://picsum.photos/200/300" alt="" /></div>
                    </div>

                    <div className='input_box'>
                        <div>Full name</div>
                        <div style={{ fontWeight: "bold" }}>{loginUserInfo?.name}</div>
                    </div>

                    <div className='input_box'>
                        <div>Phone number</div>
                        <div style={{ fontWeight: "bold" }}>+{loginUserInfo?.countrycode} {loginUserInfo?.mobileno}</div>
                    </div>

                    <div className='input_box'>
                        <div>Email address</div>
                        <div style={{ fontWeight: "bold" }}>{loginUserInfo?.email}</div>
                    </div>

                    <div className='input_box'>
                        <div>Role</div>
                        <div style={{ fontWeight: "bold" }} className='green'>{loginUserInfo?.roles}</div>
                    </div>

                    <div className='input_box'>
                        <div>Key</div>
                        <div style={{ fontWeight: "bold" }}>{loginUserInfo?._id}</div>
                    </div>
                </div>
                <div className='lower_section'>
                    <button onClick={handleClick}>Edit profile or chnage password</button>
                </div>
            </div>
        </>
    )
}

export default Profile

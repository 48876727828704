import React, { useEffect, useContext, useState } from 'react'
import mainContext from '../../../contexts/mainContext';
import { ToastContainer, toast } from 'react-toastify';
import UploadPhoto from '../../../Controller/UploadPhoto';
import Organisation from '../../../Controller/Organisation';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function EditCrowdFundingSingleFund() {

    const context = useContext(mainContext);
    const [flag, setFlag] = useState(false);
    const url = new URLSearchParams(window.location.search);
    const { 
        setCanvasTitle,
        crowdfundingfundDetail,
        setCanvasSubTitle,
        allFunctions,
        crowdfundingsinglefundDetail
    } = context;

    const { handleCanvasClose, getCrowdFundingList } = allFunctions;

    const [howitworkdata, setHowItWorkData] = useState(crowdfundingsinglefundDetail);
    const { image, title, isavailable, description } = howitworkdata;

    // const [value, setValue] = useState('');
    const Editor= {
        formats: [
            'header', 'font', 'size',
            'bold', 'italic', 'underline', 'strike', 'blockquote',
            'list', 'bullet', 'indent',
            'link', 'image', 'video'
        ],

        modules: {
            toolbar: [
              [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
              [{size: []}],
              ['bold', 'italic', 'underline', 'strike', 'blockquote'],
              [{'list': 'ordered'}, {'list': 'bullet'}, 
               {'indent': '-1'}, {'indent': '+1'}],
              ['link', 'image', 'video'],
              ['clean']
            ],
            clipboard: {
              // toggle to add extra line breaks when pasting HTML:
              matchVisual: false,
            }
          }

    }


    const handleChange = (e) => {
        const { name, value } = e.target;
        setHowItWorkData((prevSatte) => {
            return { ...prevSatte, [name]: value }
        })
    }
    
    useEffect(() => {
        setCanvasTitle("Edit");
        setCanvasSubTitle("Crowd Funding Single Fund");
        // eslint-disable-next-line
    }, [])

    const changeAvatar = async(e) => {

        const { name } = e.target;

        const err = await UploadPhoto.checkImage(e.target.files[0]);
        if(err) {
            return toast.warning(err,{
                pauseOnHover: false,
                theme: "light"
            });
        }

        const image = await UploadPhoto.uploadPhoto(e.target.files[0]);
        // console.log({ image })
        const { path } = image;
        setHowItWorkData((prevSatte) => {
            return { ...prevSatte, [name]: path }
        })

        // setImage(e.target.files[0]);
    }

    const handleImg = (id) => {
        // console.log('handle image')
        document.getElementById(id).click();
    }

    const handleSubmit = async() => {

        for(let i=0; i<crowdfundingfundDetail.fund.length; i++){
            if(crowdfundingfundDetail.fund[i]._id === howitworkdata._id){
                crowdfundingfundDetail.fund[i] = howitworkdata;
            }
        }

        if(flag) return;
        setFlag(true);

        const res = await Organisation.updatecrowdfunding(crowdfundingfundDetail)
        // console.log({ res })
        if(res && (res.status === 200 || res.status === 201)){
            toast.success("Fund Data Is Updated Successfully",{
                pauseOnHover: false,
                theme: "light"
            });

            setTimeout(async() => {
                await getCrowdFundingList(url.get('page'))
                await handleCanvasClose();
            }, 2000)
        }
        else{
            if(typeof(res.data.message) == 'object' )
                toast.error(res.data.message ? res.data.message[0] : "SomeThing Went Wrong",{
                    pauseOnHover: false,
                    theme: "light"
                });
            else{
                toast.error(res.data.message ? res.data.message : "SomeThing Went Wrong",{
                    pauseOnHover: false,
                    theme: "light"
                });
            }

        }

        setFlag(false);
    }

  return (
    <>
            <ToastContainer autoClose={2000} position="top-center"  />
            <div className="add_company_main_div">
                <div className='upper_section'>

                    <div className="input_box">
                        <div className="title">Available</div>
                        <div className="check_box" style={{display: "flex", alignItems: "center", gap: "6vh", fontSize: "24px"}}>
                            <div>
                                <input checked={isavailable === true} type="checkbox" 
                                    onChange={(e) => { setHowItWorkData((prevSatte) => {return { ...prevSatte, isavailable: true }}) }}  
                                    id="status_active" style={{ marginRight: '1vh' }} 
                                />
                                <label htmlFor="status_active">YES</label>
                            </div>
                            <div>
                                <input checked={isavailable === false} type="checkbox" 
                                    onChange={(e) => { setHowItWorkData((prevSatte) => {return { ...prevSatte, isavailable: false }}) }}  
                                    id="status_inactive" style={{ marginRight: '1vh' }} 
                                />
                                <label htmlFor="status_inactive">NO</label>
                            </div>
                        </div>
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">Title</label>
                        <input type="text" placeholder='Enter Title' id='compnay_name'
                            name="title" value={title} onChange={handleChange}
                        />
                    </div>

                <div className="input_box">
                    <label htmlFor="notification_body">Enter Description</label>
                    <ReactQuill 
                        theme="snow" 
                        value={description} 
                        onChange={(value) => setHowItWorkData((prev) => { return { ...prev, description: value } })}
                        modules={Editor.modules}
                        formats={Editor.formats} 
                    />
                    
                </div>

                <div className='input_box upload_photo'>
                    <label htmlFor="">Image</label>
                    <input type="file" style={{ display: "none" }} id="img_input" accept=".jpg, .jpeg, .png" onChange={changeAvatar} name="image" />
                    <div style={{ margin: "5% 0" }}>
                        {
                            image !== ""
                            ? <img src={`${SERVER_URL}/${image}`} style={{ width: "90px" }} alt="" />
                            : ""
                        }
                        <button onClick={() => handleImg('img_input')}>Upload image</button>
                    </div>
                </div>

                </div>
                <div className='lower_section' style={{marginTop: "1rem"}}>
                    <button onClick={handleSubmit}>Save And Proceed</button>
                </div>
            </div>
    </>
  )
}

export default EditCrowdFundingSingleFund
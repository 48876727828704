import React from 'react'
import "./ManagementPageBody.css"
import ManagementElementCard from "./ManagementElementCard.jsx"

const PageBody = () => {
    return (
        <>
            <div className="management_body">
                <ManagementElementCard status={false} link="/Pages/pricing-and-fees" per="" num="27" title="Pricing Fees" desc="Manage Pricing Fees" />
                <ManagementElementCard status={false} link="/Pages/how-it-work" per="" num="27" title="How It Work" desc="Manage How It Work" />
                <ManagementElementCard status={false} link="/Pages/medical-crowdfunding" per="" num="27" title="Crowd Funding" desc="Manage Crowd Funding" />
                <ManagementElementCard status={false} link="/Pages/about-us" per="" num="27" title="About us" desc="Manage About us" />
                <ManagementElementCard status={false} link="/Pages/social-media" per="" num="27" title="Social Media" desc="Manage Social Media" />
                <ManagementElementCard status={false} link="/Pages/team" per="" num="27" title="Team" desc="Manage Team" />
                <ManagementElementCard status={false} link="/Pages/promote" per="" num="27" title="Promote" desc="Manage Promote" />
                <ManagementElementCard status={false} link="/Pages/video" per="" num="27" title="Video" desc="Manage Video" />
                <ManagementElementCard status={false} link="/Pages/trust-safety" per="" num="27" title="Trust & Safety" desc="Manage Trust & Safety" />
            </div>
        </>
    )
}

export default PageBody

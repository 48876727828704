import React, { useEffect, useContext, useState } from 'react'
import mainContext from '../../../contexts/mainContext';
import { ToastContainer, toast } from 'react-toastify';
import Organisation from '../../../Controller/Organisation';

function EditFundRaisingTip() {

    const context = useContext(mainContext);
    const [flag, setFlag] = useState(false);
    const url = new URLSearchParams(window.location.search);
    const { 
        setCanvasTitle,  
        setCanvasSubTitle,
        allFunctions,
        fundraisingtipcardDetail
    } = context;

    const { handleCanvasClose, getFundRaisingTipList } = allFunctions;
    const [ heading, setHeading ] = useState('');
    
    useEffect(() => {
        setCanvasTitle("Edit");
        setCanvasSubTitle("FundRaisingTip");

        setHeading(() => {
            if(fundraisingtipcardDetail.heading){
                return fundraisingtipcardDetail.heading
            }
        })

        // eslint-disable-next-line
    }, [])


    const handleSubmit = async() => {

        const data = { heading, _id: fundraisingtipcardDetail._id };

        if(flag) return;
        setFlag(true);

        const res = await Organisation.updateFundraisingTip(data)
        // console.log({ res })
        if(res && (res.status === 200 || res.status === 201)){
            toast.success("Fund Raising Tip Is updated Successfully",{
                pauseOnHover: false,
                theme: "light"
            });

            setTimeout(async() => {
                await getFundRaisingTipList(url.get('page'))
                await handleCanvasClose();
            }, 2000)
        }
        else{
            if(typeof(res.data.message) == 'object' )
                toast.error(res.data.message ? res.data.message[0] : "SomeThing Went Wrong",{
                    pauseOnHover: false,
                    theme: "light"
                });
            else{
                toast.error(res.data.message ? res.data.message : "SomeThing Went Wrong",{
                    pauseOnHover: false,
                    theme: "light"
                });
            }

        }

        setFlag(false);
    }

  return (
    <>
            <ToastContainer autoClose={2000} position="top-center"  />
            <div className="add_company_main_div" style={{ height: 'auto' }}>
                <div className='upper_section'>
                    <div className='input_box'>
                        <label htmlFor="">Heading</label>
                        <input type="text" placeholder='Enter heading' id='compnay_name' 
                            value={heading} onChange={(e) => setHeading(() => e.target.value) } 
                        />
                    </div>
                </div>
                <div className='lower_section' style={{marginTop: "1rem"}}>
                    <button onClick={handleSubmit}>Update And Proceed</button>
                </div>
            </div>
    </>
  )
}

export default EditFundRaisingTip
import React, { useContext } from 'react'
import Organisation from '../../../Controller/Organisation';
import mainContext from '../../../contexts/mainContext';
import { ToastContainer, toast } from 'react-toastify';

const OurPartnerListCard = (props) => {

    const url = new URLSearchParams(window.location.search);
    const context = useContext(mainContext);
    const { allFunctions } = context;
    const { getSubscriberList } = allFunctions;
    const { added, email, item } = props;

    const handleClick = async() => {
        const res = await Organisation.deleteSubscriber(item._id)
        // console.log({ res })
        if(res && (res.status === 200 || res.status === 201)){
            toast.success("Subscriber Is Deleted Successfully",{
                pauseOnHover: false,
                theme: "light"
            });

            await getSubscriberList(url.get('page'))

        }
        else{
            if(typeof(res.data.message) == 'object' )
                toast.error(res.data.message ? res.data.message[0] : "SomeThing Went Wrong",{
                    pauseOnHover: false,
                    theme: "light"
                });
            else{
                toast.error(res.data.message ? res.data.message : "SomeThing Went Wrong",{
                    pauseOnHover: false,
                    theme: "light"
                });
            }

        }
    }

    return (
        <>
            <ToastContainer autoClose={2000} position="top-center"  />
            <div className="list_card companies_list_grid" style={{ gridTemplateColumns:"2fr 6fr 6fr 6fr 2fr 2fr" }}>
                <div>{email}</div>
                <div></div>
                <div></div>
                <div></div>
                <div style={{ textAlign: "right" }}>{added}</div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <button className="list_card_view_btn" onClick={handleClick}>Delete</button>
                </div>
            </div>
        </>
    )
}

export default OurPartnerListCard

import axios from "axios";
const authToken = localStorage.getItem("authToken");
// const authToken = process.env.REACT_APP_AUTHTOKEN;
const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const Suffix = process.env.REACT_APP_SUFFIX;

class Organisation {

    async createPatient( data ){
        try{

            const config = {
                method: 'post',
                url: `${SERVER_URL}/${Suffix}/patient`,
                data,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };

            const res = await axios(config);
            return res;


        }catch(err){
            return err.response
        }
    }

    async getpatientList(param){
        const { limit, page, filterDate } = param;
        
        try {

            let parseUrl = `?`;
            parseUrl = limit ? `${parseUrl}limit=${limit}&` : parseUrl ;
            parseUrl = page ? `${parseUrl}page=${page}&` : parseUrl ;

            if( filterDate.from && filterDate.to ) {
                parseUrl = `${parseUrl}from=${filterDate.from}&to=${filterDate.to}`
            }

            const config = {
                method: 'get',
                url: `${SERVER_URL}/${Suffix}/patient${parseUrl}`,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };
            const res = await axios(config);
            return res;

        } catch (error) {
            return error.response
        }
    }

    async getPartialPatientList(){
        try {

            const config = {
                method: 'get',
                url: `${SERVER_URL}/${Suffix}/patient/partial-list`,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };
            const res = await axios(config);
            return res;

        } catch (error) {
            return error.response
        }
    }

    async updatePatient(data){
        try{

            const config = {
                method: 'put',
                url: `${SERVER_URL}/${Suffix}/patient`,
                data,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };

            const res = await axios(config);
            return res;


        }catch(err){
            return err.response
        }
    }

    async getFundRaiserList(param){
        const { limit, page, filterDate } = param;
        
        try {

            let parseUrl = `?`;
            parseUrl = limit ? `${parseUrl}limit=${limit}&` : parseUrl ;
            parseUrl = page ? `${parseUrl}page=${page}&` : parseUrl ;

            if( filterDate.from && filterDate.to ) {
                parseUrl = `${parseUrl}from=${filterDate.from}&to=${filterDate.to}`
            }

            const config = {
                method: 'get',
                url: `${SERVER_URL}/${Suffix}/fund-raiser${parseUrl}`,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };
            const res = await axios(config);
            return res;

        } catch (error) {
            return error.response
        }
    }

    async createFundRaiser(data){
        try{

            const config = {
                method: 'post',
                url: `${SERVER_URL}/${Suffix}/fund-raiser`,
                data,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };

            const res = await axios(config);
            return res;


        }catch(err){
            return err.response
        }
    }

    async updateFundRaiser(data){
        try{

            const config = {
                method: 'put',
                url: `${SERVER_URL}/${Suffix}/fund-raiser`,
                data,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };

            const res = await axios(config);
            return res;


        }catch(err){
            return err.response
        }
    }

    async getBannerList(param){
        const { limit, page, filterDate } = param;
        
        try {

            let parseUrl = `?`;
            parseUrl = limit ? `${parseUrl}limit=${limit}&` : parseUrl ;
            parseUrl = page ? `${parseUrl}page=${page}&` : parseUrl ;

            if( filterDate.from && filterDate.to ) {
                parseUrl = `${parseUrl}from=${filterDate.from}&to=${filterDate.to}`
            }

            const config = {
                method: 'get',
                url: `${SERVER_URL}/${Suffix}/banner${parseUrl}`,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };
            const res = await axios(config);
            return res;

        } catch (error) {
            return error.response
        }
    }

    async createBanner( data ){
        try{

            const config = {
                method: 'post',
                url: `${SERVER_URL}/${Suffix}/banner`,
                data,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };

            const res = await axios(config);
            return res;


        }catch(err){
            return err.response
        }
    }

    async updateBanner( data ){
        try{

            const config = {
                method: 'put',
                url: `${SERVER_URL}/${Suffix}/banner`,
                data,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };

            const res = await axios(config);
            return res;


        }catch(err){
            return err.response
        }
    }

    async getOurPartnerList(param){
        const { limit, page, filterDate } = param;
        
        try {

            let parseUrl = `?`;
            parseUrl = limit ? `${parseUrl}limit=${limit}&` : parseUrl ;
            parseUrl = page ? `${parseUrl}page=${page}&` : parseUrl ;

            if( filterDate.from && filterDate.to ) {
                parseUrl = `${parseUrl}from=${filterDate.from}&to=${filterDate.to}`
            }

            const config = {
                method: 'get',
                url: `${SERVER_URL}/${Suffix}/our-partner${parseUrl}`,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };
            const res = await axios(config);
            return res;

        } catch (error) {
            return error.response
        }
    }

    async createOurPartner( data ){
        try{

            const config = {
                method: 'post',
                url: `${SERVER_URL}/${Suffix}/our-partner`,
                data,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };

            const res = await axios(config);
            return res;


        }catch(err){
            return err.response
        }
    }

    async updateOurPartner( data ){
        try{

            const config = {
                method: 'put',
                url: `${SERVER_URL}/${Suffix}/our-partner`,
                data,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };

            const res = await axios(config);
            return res;


        }catch(err){
            return err.response
        }
    }

    async getBlogList(param){
        const { limit, page, filterDate } = param;
        
        try {

            let parseUrl = `?`;
            parseUrl = limit ? `${parseUrl}limit=${limit}&` : parseUrl ;
            parseUrl = page ? `${parseUrl}page=${page}&` : parseUrl ;

            if( filterDate.from && filterDate.to ) {
                parseUrl = `${parseUrl}from=${filterDate.from}&to=${filterDate.to}`
            }

            const config = {
                method: 'get',
                url: `${SERVER_URL}/${Suffix}/blog${parseUrl}`,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };
            const res = await axios(config);
            return res;

        } catch (error) {
            return error.response
        }
    }

    async createBlog( data ){
        try{

            const config = {
                method: 'post',
                url: `${SERVER_URL}/${Suffix}/blog`,
                data,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };

            const res = await axios(config);
            return res;


        }catch(err){
            return err.response
        }
    }

    async updateBlog( data ){
        try{

            const config = {
                method: 'put',
                url: `${SERVER_URL}/${Suffix}/blog`,
                data,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };

            const res = await axios(config);
            return res;


        }catch(err){
            return err.response
        }
    }

    async getSuccessStoryList(param){
        const { limit, page, filterDate } = param;
        
        try {

            let parseUrl = `?`;
            parseUrl = limit ? `${parseUrl}limit=${limit}&` : parseUrl ;
            parseUrl = page ? `${parseUrl}page=${page}&` : parseUrl ;

            if( filterDate.from && filterDate.to ) {
                parseUrl = `${parseUrl}from=${filterDate.from}&to=${filterDate.to}`
            }

            const config = {
                method: 'get',
                url: `${SERVER_URL}/${Suffix}/success-story${parseUrl}`,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };
            const res = await axios(config);
            return res;

        } catch (error) {
            return error.response
        }
    }

    async getSuccessStoryPartialList(){
        try {

            const config = {
                method: 'get',
                url: `${SERVER_URL}/${Suffix}/success-story/partial-list`,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };
            const res = await axios(config);
            return res;

        } catch (error) {
            return error.response
        }
    }

    async createSuccessStory( data ){
        try{

            const config = {
                method: 'post',
                url: `${SERVER_URL}/${Suffix}/success-story`,
                data,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };

            const res = await axios(config);
            return res;


        }catch(err){
            return err.response
        }
    }

    async updateSuccessStory( data ){
        try{

            const config = {
                method: 'put',
                url: `${SERVER_URL}/${Suffix}/success-story`,
                data,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };

            const res = await axios(config);
            return res;


        }catch(err){
            return err.response
        }
    }

    async getFaqsList(param){
        const { limit, page, filterDate } = param;
        
        try {

            let parseUrl = `?`;
            parseUrl = limit ? `${parseUrl}limit=${limit}&` : parseUrl ;
            parseUrl = page ? `${parseUrl}page=${page}&` : parseUrl ;

            if( filterDate.from && filterDate.to ) {
                parseUrl = `${parseUrl}from=${filterDate.from}&to=${filterDate.to}`
            }

            const config = {
                method: 'get',
                url: `${SERVER_URL}/${Suffix}/faq${parseUrl}`,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };
            const res = await axios(config);
            return res;

        } catch (error) {
            return error.response
        }
    }

    async createFaq( data ){
        try{

            const config = {
                method: 'post',
                url: `${SERVER_URL}/${Suffix}/faq`,
                data,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };

            const res = await axios(config);
            return res;


        }catch(err){
            return err.response
        }
    }

    async updateFaq( data ){
        try{

            const config = {
                method: 'post',
                url: `${SERVER_URL}/${Suffix}/faq`,
                data,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };

            const res = await axios(config);
            return res;


        }catch(err){
            return err.response
        }
    }

    async getTestimonialList(param){
        const { limit, page, filterDate } = param;
        
        try {

            let parseUrl = `?`;
            parseUrl = limit ? `${parseUrl}limit=${limit}&` : parseUrl ;
            parseUrl = page ? `${parseUrl}page=${page}&` : parseUrl ;

            if( filterDate.from && filterDate.to ) {
                parseUrl = `${parseUrl}from=${filterDate.from}&to=${filterDate.to}`
            }

            const config = {
                method: 'get',
                url: `${SERVER_URL}/${Suffix}/testimonial${parseUrl}`,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };
            const res = await axios(config);
            return res;

        } catch (error) {
            return error.response
        }
    }

    async createTestimonial( data ){
        try{

            const config = {
                method: 'post',
                url: `${SERVER_URL}/${Suffix}/testimonial`,
                data,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };

            const res = await axios(config);
            return res;


        }catch(err){
            return err.response
        }
    }

    async updateTestimonial( data ){
        try{

            const config = {
                method: 'put',
                url: `${SERVER_URL}/${Suffix}/testimonial`,
                data,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };

            const res = await axios(config);
            return res;


        }catch(err){
            return err.response
        }
    }

    async getAmenitiesList(param){
        const { limit, page, filterDate } = param;
        
        try {

            let parseUrl = `?`;
            parseUrl = limit ? `${parseUrl}limit=${limit}&` : parseUrl ;
            parseUrl = page ? `${parseUrl}page=${page}&` : parseUrl ;

            if( filterDate.from && filterDate.to ) {
                parseUrl = `${parseUrl}from=${filterDate.from}&to=${filterDate.to}`
            }

            const config = {
                method: 'get',
                url: `${SERVER_URL}/${Suffix}/amenities${parseUrl}`,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };
            const res = await axios(config);
            return res;

        } catch (error) {
            return error.response
        }
    }
    
    async createAmenities( data ){
        try{

            const config = {
                method: 'post',
                url: `${SERVER_URL}/${Suffix}/amenities`,
                data,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };

            const res = await axios(config);
            return res;


        }catch(err){
            return err.response
        }
    }

    async updateAmenities( data ){
        try{

            const config = {
                method: 'put',
                url: `${SERVER_URL}/${Suffix}/amenities`,
                data,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };

            const res = await axios(config);
            return res;


        }catch(err){
            return err.response
        }
    }

    async getKeypointList(param){
        const { limit, page, filterDate } = param;
        
        try {

            let parseUrl = `?`;
            parseUrl = limit ? `${parseUrl}limit=${limit}&` : parseUrl ;
            parseUrl = page ? `${parseUrl}page=${page}&` : parseUrl ;

            if( filterDate.from && filterDate.to ) {
                parseUrl = `${parseUrl}from=${filterDate.from}&to=${filterDate.to}`
            }

            const config = {
                method: 'get',
                url: `${SERVER_URL}/${Suffix}/key-point${parseUrl}`,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };
            const res = await axios(config);
            return res;

        } catch (error) {
            return error.response
        }
    }

    async createKeyPoint( data ){
        try{

            const config = {
                method: 'post',
                url: `${SERVER_URL}/${Suffix}/key-point`,
                data,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };

            const res = await axios(config);
            return res;


        }catch(err){
            return err.response
        }
    }

    async updateKeyPoint( data ){
        try{

            const config = {
                method: 'put',
                url: `${SERVER_URL}/${Suffix}/key-point`,
                data,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };

            const res = await axios(config);
            return res;


        }catch(err){
            return err.response
        }
    }

    async getPricingFeesList(param){
        const { limit, page, filterDate } = param;
        
        try {

            let parseUrl = `?`;
            parseUrl = limit ? `${parseUrl}limit=${limit}&` : parseUrl ;
            parseUrl = page ? `${parseUrl}page=${page}&` : parseUrl ;

            if( filterDate.from && filterDate.to ) {
                parseUrl = `${parseUrl}from=${filterDate.from}&to=${filterDate.to}`
            }

            const config = {
                method: 'get',
                url: `${SERVER_URL}/${Suffix}/pricing-fee${parseUrl}`,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };
            const res = await axios(config);
            return res;

        } catch (error) {
            return error.response
        }
    }

    async createPricingFees( data ){
        try{

            const config = {
                method: 'post',
                url: `${SERVER_URL}/${Suffix}/pricing-fee`,
                data,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };

            const res = await axios(config);
            return res;


        }catch(err){
            return err.response
        }
    }

    async updatePricingFees( data ){
        try{

            const config = {
                method: 'put',
                url: `${SERVER_URL}/${Suffix}/pricing-fee`,
                data,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };

            const res = await axios(config);
            return res;


        }catch(err){
            return err.response
        }
    }

    async getHowitworkList(param){
        const { limit, page, filterDate } = param;
        
        try {

            let parseUrl = `?`;
            parseUrl = limit ? `${parseUrl}limit=${limit}&` : parseUrl ;
            parseUrl = page ? `${parseUrl}page=${page}&` : parseUrl ;

            if( filterDate.from && filterDate.to ) {
                parseUrl = `${parseUrl}from=${filterDate.from}&to=${filterDate.to}`
            }

            const config = {
                method: 'get',
                url: `${SERVER_URL}/${Suffix}/howitworks${parseUrl}`,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };
            const res = await axios(config);
            return res;

        } catch (error) {
            return error.response
        }
    }

    async createHowItWork( data ){
        try{

            const config = {
                method: 'post',
                url: `${SERVER_URL}/${Suffix}/howitworks`,
                data,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };

            const res = await axios(config);
            return res;


        }catch(err){
            return err.response
        }
    }

    async updateHowItWork( data ){
        try{

            const config = {
                method: 'put',
                url: `${SERVER_URL}/${Suffix}/howitworks`,
                data,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };

            const res = await axios(config);
            return res;


        }catch(err){
            return err.response
        }
    }

    async getCrowdFundingList(param){
        const { limit, page, filterDate } = param;
        
        try {

            let parseUrl = `?`;
            parseUrl = limit ? `${parseUrl}limit=${limit}&` : parseUrl ;
            parseUrl = page ? `${parseUrl}page=${page}&` : parseUrl ;

            if( filterDate.from && filterDate.to ) {
                parseUrl = `${parseUrl}from=${filterDate.from}&to=${filterDate.to}`
            }

            const config = {
                method: 'get',
                url: `${SERVER_URL}/${Suffix}/crowd-funding${parseUrl}`,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };
            const res = await axios(config);
            return res;

        } catch (error) {
            return error.response
        }
    }

    async updatecrowdfunding(data){
        try{

            const config = {
                method: 'put',
                url: `${SERVER_URL}/${Suffix}/crowd-funding`,
                data,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };

            const res = await axios(config);
            return res;


        }catch(err){
            return err.response
        }
    }

    async createCrowdFunding(data){
        try{

            const config = {
                method: 'post',
                url: `${SERVER_URL}/${Suffix}/crowd-funding`,
                data,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };

            const res = await axios(config);
            return res;


        }catch(err){
            return err.response
        }
    }

    async getAboutList(param){
        const { limit, page, filterDate } = param;
        
        try {

            let parseUrl = `?`;
            parseUrl = limit ? `${parseUrl}limit=${limit}&` : parseUrl ;
            parseUrl = page ? `${parseUrl}page=${page}&` : parseUrl ;

            if( filterDate.from && filterDate.to ) {
                parseUrl = `${parseUrl}from=${filterDate.from}&to=${filterDate.to}`
            }

            const config = {
                method: 'get',
                url: `${SERVER_URL}/${Suffix}/about${parseUrl}`,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };
            const res = await axios(config);
            return res;

        } catch (error) {
            return error.response
        }
    }

    async createAbout(data){
        try{

            const config = {
                method: 'post',
                url: `${SERVER_URL}/${Suffix}/about`,
                data,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };

            const res = await axios(config);
            return res;


        }catch(err){
            return err.response
        }
    }

    async updateAbout(data){
        try{

            const config = {
                method: 'put',
                url: `${SERVER_URL}/${Suffix}/about`,
                data,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };

            const res = await axios(config);
            return res;


        }catch(err){
            return err.response
        }
    }

    async getSocialMediaDetail(){
        try {

            const config = {
                method: 'get',
                url: `${SERVER_URL}/${Suffix}/social-media`,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };
            const res = await axios(config);
            return res;

        } catch (error) {
            return error.response
        }
    }

    async createSocialMedia(data){
        try{

            const config = {
                method: 'post',
                url: `${SERVER_URL}/${Suffix}/social-media`,
                data,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };

            const res = await axios(config);
            return res;


        }catch(err){
            return err.response
        }
    }

    async updateSocialMedia(data){
        try{

            const config = {
                method: 'put',
                url: `${SERVER_URL}/${Suffix}/social-media`,
                data,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };

            const res = await axios(config);
            return res;


        }catch(err){
            return err.response
        }
    }

    async getTeamDetail(){
        try {

            const config = {
                method: 'get',
                url: `${SERVER_URL}/${Suffix}/team`,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };
            const res = await axios(config);
            return res;

        } catch (error) {
            return error.response
        }
    }

    async createTeam(data){
        try{

            const config = {
                method: 'post',
                url: `${SERVER_URL}/${Suffix}/team`,
                data,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };

            const res = await axios(config);
            return res;


        }catch(err){
            return err.response
        }
    }

    async updateTeam(data){
        try{

            const config = {
                method: 'put',
                url: `${SERVER_URL}/${Suffix}/team`,
                data,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };

            const res = await axios(config);
            return res;


        }catch(err){
            return err.response
        }
    }

    async getPromoteList(param){
        const { limit, page, filterDate } = param;
        
        try {

            let parseUrl = `?`;
            parseUrl = limit ? `${parseUrl}limit=${limit}&` : parseUrl ;
            parseUrl = page ? `${parseUrl}page=${page}&` : parseUrl ;

            if( filterDate.from && filterDate.to ) {
                parseUrl = `${parseUrl}from=${filterDate.from}&to=${filterDate.to}`
            }

            const config = {
                method: 'get',
                url: `${SERVER_URL}/${Suffix}/promote${parseUrl}`,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };
            const res = await axios(config);
            return res;

        } catch (error) {
            return error.response
        }
    }

    async createPromote(data){
        try{

            const config = {
                method: 'post',
                url: `${SERVER_URL}/${Suffix}/promote`,
                data,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };

            const res = await axios(config);
            return res;


        }catch(err){
            return err.response
        }
    }

    async updatePromote(data){
        try{

            const config = {
                method: 'put',
                url: `${SERVER_URL}/${Suffix}/promote`,
                data,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };

            const res = await axios(config);
            return res;


        }catch(err){
            return err.response
        }
    }

    async getvideoList(param){
        const { limit, page, filterDate } = param;
        
        try {

            let parseUrl = `?`;
            parseUrl = limit ? `${parseUrl}limit=${limit}&` : parseUrl ;
            parseUrl = page ? `${parseUrl}page=${page}&` : parseUrl ;

            if( filterDate.from && filterDate.to ) {
                parseUrl = `${parseUrl}from=${filterDate.from}&to=${filterDate.to}`
            }

            const config = {
                method: 'get',
                url: `${SERVER_URL}/${Suffix}/video${parseUrl}`,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };
            const res = await axios(config);
            return res;

        } catch (error) {
            return error.response
        }
    }

    async createVideo(data){
        try{

            const config = {
                method: 'post',
                url: `${SERVER_URL}/${Suffix}/video`,
                data,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };

            const res = await axios(config);
            return res;


        }catch(err){
            return err.response
        }
    }

    async updateVideo(data){
        try{

            const config = {
                method: 'put',
                url: `${SERVER_URL}/${Suffix}/video`,
                data,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };

            const res = await axios(config);
            return res;


        }catch(err){
            return err.response
        }
    }

    async getTrustAndSafetyList(param){
        const { limit, page, filterDate } = param;
        
        try {

            let parseUrl = `?`;
            parseUrl = limit ? `${parseUrl}limit=${limit}&` : parseUrl ;
            parseUrl = page ? `${parseUrl}page=${page}&` : parseUrl ;

            if( filterDate.from && filterDate.to ) {
                parseUrl = `${parseUrl}from=${filterDate.from}&to=${filterDate.to}`
            }

            const config = {
                method: 'get',
                url: `${SERVER_URL}/${Suffix}/trust-and-safety${parseUrl}`,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };
            const res = await axios(config);
            return res;

        } catch (error) {
            return error.response
        }
    }

    async createTrustAndSafety(data){
        try{

            const config = {
                method: 'post',
                url: `${SERVER_URL}/${Suffix}/trust-and-safety`,
                data,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };

            const res = await axios(config);
            return res;


        }catch(err){
            return err.response
        }
    }

    async updateTrustAndSafety(data){
        try{

            const config = {
                method: 'put',
                url: `${SERVER_URL}/${Suffix}/trust-and-safety`,
                data,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };

            const res = await axios(config);
            return res;


        }catch(err){
            return err.response
        }
    }

    async getSubscriberList(param){
        const { limit, page, filterDate } = param;
        
        try {

            let parseUrl = `?`;
            parseUrl = limit ? `${parseUrl}limit=${limit}&` : parseUrl ;
            parseUrl = page ? `${parseUrl}page=${page}&` : parseUrl ;

            if( filterDate.from && filterDate.to ) {
                parseUrl = `${parseUrl}from=${filterDate.from}&to=${filterDate.to}`
            }

            const config = {
                method: 'get',
                url: `${SERVER_URL}/${Suffix}/subscribe${parseUrl}`,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };
            const res = await axios(config);
            return res;

        } catch (error) {
            return error.response
        }
    }

    async deleteSubscriber(id){
        try{

            const config = {
                method: 'delete',
                url: `${SERVER_URL}/${Suffix}/subscribe/${id}`,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };

            const res = await axios(config);
            return res;


        }catch(err){
            return err.response
        }
    }

    async getFundRaiserApprovalList(param){
        const { limit, page, filterDate } = param;
        
        try {

            let parseUrl = `?`;
            parseUrl = limit ? `${parseUrl}limit=${limit}&` : parseUrl ;
            parseUrl = page ? `${parseUrl}page=${page}&` : parseUrl ;

            if( filterDate.from && filterDate.to ) {
                parseUrl = `${parseUrl}from=${filterDate.from}&to=${filterDate.to}`
            }

            const config = {
                method: 'get',
                url: `${SERVER_URL}/${Suffix}/fund-raiser-approval${parseUrl}`,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };
            const res = await axios(config);
            return res;

        } catch (error) {
            return error.response
        }
    }

    async getuserdetail(id){
        try{

            const config = {
                method: 'get',
                url: `${SERVER_URL}/${Suffix}/user/specific/${id}`,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };

            const res = await axios(config);
            return res;

        }catch(err){
            return err.response
        }
    }

    async rejectfundraiserapproval(id){
        try{

            const config = {
                method: 'delete',
                url: `${SERVER_URL}/${Suffix}/fund-raiser-approval/${id}`,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };

            const res = await axios(config);
            return res;

        }catch(err){
            return err.response
        }
    }

    async approvefundraiserArpproval(data, slug = ''){
        try{

            const config = {
                method: 'post',
                url: `${SERVER_URL}/${Suffix}/fund-raiser-approval?slug=${slug}`,
                data,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };

            const res = await axios(config);
            return res;


        }catch(err){
            return err.response
        }
    }

    async getReportList(param){
        const { limit, page, filterDate } = param;
        
        try {

            let parseUrl = `?`;
            parseUrl = limit ? `${parseUrl}limit=${limit}&` : parseUrl ;
            parseUrl = page ? `${parseUrl}page=${page}&` : parseUrl ;

            if( filterDate.from && filterDate.to ) {
                parseUrl = `${parseUrl}from=${filterDate.from}&to=${filterDate.to}`
            }

            const config = {
                method: 'get',
                url: `${SERVER_URL}/${Suffix}/report${parseUrl}`,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };
            const res = await axios(config);
            return res;

        } catch (error) {
            return error.response
        }
    }

    async deleteReport(id){
        try{

            const config = {
                method: 'delete',
                url: `${SERVER_URL}/${Suffix}/report/${id}`,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };

            const res = await axios(config);
            return res;


        }catch(err){
            return err.response
        }
    }

    async getCareerList(param){
        const { limit, page, filterDate } = param;
        
        try {

            let parseUrl = `?`;
            parseUrl = limit ? `${parseUrl}limit=${limit}&` : parseUrl ;
            parseUrl = page ? `${parseUrl}page=${page}&` : parseUrl ;

            if( filterDate.from && filterDate.to ) {
                parseUrl = `${parseUrl}from=${filterDate.from}&to=${filterDate.to}`
            }

            const config = {
                method: 'get',
                url: `${SERVER_URL}/${Suffix}/career${parseUrl}`,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };
            const res = await axios(config);
            return res;

        } catch (error) {
            return error.response
        }
    }

    async deleteCareerRecord(id){
        try{

            const config = {
                method: 'delete',
                url: `${SERVER_URL}/${Suffix}/career/${id}`,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };

            const res = await axios(config);
            return res;


        }catch(err){
            return err.response
        }
    }

    async createFaqData(data){
        try{

            const config = {
                method: 'post',
                url: `${SERVER_URL}/${Suffix}/faqdata`,
                data,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };

            const res = await axios(config);
            return res;


        }catch(err){
            return err.response
        }
    }

    async getFaqDataList(param){
        const { limit, page, filterDate } = param;
        
        try {

            let parseUrl = `?`;
            parseUrl = limit ? `${parseUrl}limit=${limit}&` : parseUrl ;
            parseUrl = page ? `${parseUrl}page=${page}&` : parseUrl ;

            if( filterDate.from && filterDate.to ) {
                parseUrl = `${parseUrl}from=${filterDate.from}&to=${filterDate.to}`
            }

            const config = {
                method: 'get',
                url: `${SERVER_URL}/${Suffix}/faqdata${parseUrl}`,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };
            const res = await axios(config);
            return res;

        } catch (error) {
            return error.response
        }
    }

    async updateFaqData(data){
        try{

            const config = {
                method: 'put',
                url: `${SERVER_URL}/${Suffix}/faqdata`,
                data,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };

            const res = await axios(config);
            return res;


        }catch(err){
            return err.response
        }
    }

    async updateOrganisation(data){
        try{

            const config = {
                method: 'put',
                url: `${SERVER_URL}/${Suffix}/organisation/profile`,
                data,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };

            const res = await axios(config);
            return res;


        }catch(err){
            return err.response
        }
    }

    async getFundRaisingTipList(param){
        const { limit, page, filterDate } = param;
        
        try {

            let parseUrl = `?`;
            parseUrl = limit ? `${parseUrl}limit=${limit}&` : parseUrl ;
            parseUrl = page ? `${parseUrl}page=${page}&` : parseUrl ;

            if( filterDate.from && filterDate.to ) {
                parseUrl = `${parseUrl}from=${filterDate.from}&to=${filterDate.to}`
            }

            const config = {
                method: 'get',
                url: `${SERVER_URL}/${Suffix}/fund-raiser-tip${parseUrl}`,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };
            const res = await axios(config);
            return res;

        } catch (error) {
            return error.response
        }
    }

    async createFundRaisingTip(data){
        try{

            const config = {
                method: 'post',
                url: `${SERVER_URL}/${Suffix}/fund-raiser-tip`,
                data,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };

            const res = await axios(config);
            return res;


        }catch(err){
            return err.response
        }
    }

    async updateFundraisingTip(data){
        try{

            const config = {
                method: 'put',
                url: `${SERVER_URL}/${Suffix}/fund-raiser-tip`,
                data,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };

            const res = await axios(config);
            return res;


        }catch(err){
            return err.response
        }
    }

    async getTransactionApprovalList(param){
        const { limit, page, filterDate } = param;
        
        try {

            let parseUrl = `?`;
            parseUrl = limit ? `${parseUrl}limit=${limit}&` : parseUrl ;
            parseUrl = page ? `${parseUrl}page=${page}&` : parseUrl ;

            if( filterDate.from && filterDate.to ) {
                parseUrl = `${parseUrl}from=${filterDate.from}&to=${filterDate.to}`
            }

            const config = {
                method: 'get',
                url: `${SERVER_URL}/${Suffix}/approve-transaction${parseUrl}`,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };
            const res = await axios(config);
            return res;

        } catch (error) {
            return error.response
        }
    }

    async rejectusertransactionapproval(id){
        try{

            const config = {
                method: 'delete',
                url: `${SERVER_URL}/${Suffix}/approve-transaction/${id}`,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };

            const res = await axios(config);
            return res;


        }catch(err){
            return err.response
        }
    }

    async approveTransactionArpproval(id){
        try{

            const config = {
                method: 'get',
                url: `${SERVER_URL}/${Suffix}/approve-transaction/${id}`,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };

            const res = await axios(config);
            return res;

        }catch(err){
            return err.response
        }
    }

    async getStripeList(param){
        const { limit, page, filterDate } = param;
        
        try {

            let parseUrl = `?`;
            parseUrl = limit ? `${parseUrl}limit=${limit}&` : parseUrl ;
            parseUrl = page ? `${parseUrl}page=${page}&` : parseUrl ;

            if( filterDate.from && filterDate.to ) {
                parseUrl = `${parseUrl}from=${filterDate.from}&to=${filterDate.to}`
            }

            const config = {
                method: 'get',
                url: `${SERVER_URL}/${Suffix}/stripe-data${parseUrl}`,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };
            const res = await axios(config);
            return res;

        } catch (error) {
            return error.response
        }
    }

    async createStripeData(data){
        try{

            const config = {
                method: 'post',
                url: `${SERVER_URL}/${Suffix}/stripe-data`,
                data,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };

            const res = await axios(config);
            return res;


        }catch(err){
            return err.response
        }
    }

    async updateStripeData(data){
        try{

            const config = {
                method: 'put',
                url: `${SERVER_URL}/${Suffix}/stripe-data`,
                data,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };

            const res = await axios(config);
            return res;


        }catch(err){
            return err.response
        }
    }

}

const organisation = new Organisation();

export default organisation;
import React, { useContext } from 'react'
import mainContext from '../../../contexts/mainContext';

const FundRaisingTipListCard = (props) => {

    const context = useContext(mainContext);
    const { setinCanvas, setFundRaisingTipCardDetail } = context;
    const { added, card, item, heading } = props;

    const handleClick = () => {
        setFundRaisingTipCardDetail(() => { return item })
        setinCanvas('Edit Fund-Raising-Tip-Card')
    }

    const handleClick2 = () => {
        setFundRaisingTipCardDetail(() => { return item })
        setinCanvas('Edit Fund-Raising-Tip')
    }

    return (
        <>
            <div className="list_card companies_list_grid" style={{ gridTemplateColumns:"3fr 6fr 5fr 4fr" }}>
                {/* <div>
                    <div className={`list_card_status status_${activated ? 'active' : 'inactive'}`}>{activated ? 'TRUE' : 'FALSE'}</div>
                </div> */}
                <div>{card ? card.length : 0}</div>
                <div>{heading}</div>
                {/* <div></div> */}
                <div style={{ textAlign: "right" }}>{added}</div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <button className="list_card_view_btn" onClick={handleClick} style={{ marginRight: '10px' }}>Edit Card</button>
                    <button className="list_card_view_btn" onClick={handleClick2}>Edit</button>
                </div>
            </div>
        </>
    )
}

export default FundRaisingTipListCard

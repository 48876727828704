import React, { useEffect, useContext, useState } from 'react'
import mainContext from '../../../contexts/mainContext';
import Organisation from '../../../Controller/Organisation';
import { ToastContainer, toast } from 'react-toastify';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function ViewFundRaiserApproval() {

    const context = useContext(mainContext);
    const url = new URLSearchParams(window.location.search);
    
    const { 
        setCanvasTitle,
        setinCanvas,  
        setCanvasSubTitle,
        setUserDetail,
        fundraiserApprovalDetail,
        allFunctions
    } = context;

    const { handleCanvasClose, getFundRaiserApprovalList } = allFunctions;
    const [flag, setFlag] = useState(false);

    // const [fundraiserdate, setfundraiserdata] = useState(fundraiserApprovalDetail);
    const { fundraisername, cause, goalamount,
        email, countrycode, mobileno, patientname, healthissue, fundraisertitle, 
        hospitalname, hospitalcity, hospitalcountry, image, medicalreport, userid, _id,
        patientemail, patientmobileno, patientcountrycode, patientidnumber
    } = fundraiserApprovalDetail;

    const [slug, setSlug] = useState(`${fundraisertitle}-01`)

    const handleuserdata = async() => {

        const res = await Organisation.getuserdetail(userid);
        if(res.status === 200 || res.status === 201){
            setUserDetail(() => res.data);
            setinCanvas('view user detail')
        }
    }

    const handlereject = async() => {
        const res = await Organisation.rejectfundraiserapproval(_id);
        if(res && (res.status === 200 || res.status === 201)){
            toast.success("Data Is Deleted Successfully",{
                pauseOnHover: false,
                theme: "light"
            });

            setTimeout(async() => {
                await getFundRaiserApprovalList(url.get('page'))
                await handleCanvasClose();
            }, 2000)
        }
        else{
            if(typeof(res.data.message) == 'object' )
                toast.error(res.data.message ? res.data.message[0] : "SomeThing Went Wrong",{
                    pauseOnHover: false,
                    theme: "light"
                });
            else{
                toast.error(res.data.message ? res.data.message : "SomeThing Went Wrong",{
                    pauseOnHover: false,
                    theme: "light"
                });
            }

        }
    }

    const handleApprove = async() => {
        if(flag) return;
        setFlag(true);
        let data = { _id };
        const res = await Organisation.approvefundraiserArpproval(data, slug);
        if(res && (res.status === 200 || res.status === 201)){
            toast.success("Data Is Created Successfully",{
                pauseOnHover: false,
                theme: "light"
            });

            setTimeout(async() => {
                await getFundRaiserApprovalList(url.get('page'))
                await handleCanvasClose();
            }, 2000)
        }
        else{
            if(typeof(res.data.message) == 'object' )
                toast.error(res.data.message ? res.data.message[0] : "SomeThing Went Wrong",{
                    pauseOnHover: false,
                    theme: "light"
                });
            else{
                toast.error(res.data.message ? res.data.message : "SomeThing Went Wrong",{
                    pauseOnHover: false,
                    theme: "light"
                });
            }

        }

        setFlag(false);
    }
    
    useEffect(() => {
        setCanvasTitle("View");
        setCanvasSubTitle("Fund Raiser Request");
        // eslint-disable-next-line
    }, [])

    // const changeAvatar = async(e) => {

    //     const { name } = e.target;

    //     const err = await UploadPhoto.checkImage(e.target.files[0]);
    //     if(err) {
    //         return toast.warning(err,{
    //             pauseOnHover: false,
    //             theme: "light"
    //         });
    //     }

    //     const image = await UploadPhoto.uploadPhoto(e.target.files[0]);
    //     console.log({ image })
    //     const { path } = image;

    //     if(name === 'medicalreport'){
    //         setfundraiserdata((prevSatte) => {
    //             return { ...prevSatte, about: { ...prevSatte.about, [name]: path } }
    //         })
    //     }else{   
    //         setfundraiserdata((prevSatte) => {
    //             return { ...prevSatte, [name]: path }
    //         })
    //     }

    //     // setImage(e.target.files[0]);
    // }

    // const handleImg = (id) => {
    //     // console.log('handle image')
    //     document.getElementById(id).click();
    // }

    // const handleSubmit = async() => {

    //     // console.log(fundraiserdate)
    //     setFundraiserDetail((prevstate) => { return fundraiserdate })
    //     setinCanvas('add fundraiser bankdetail')
    // }

    // function handleDelete(index){
    //     const newArr = [...medicalreport];
    //     newArr.splice(index, 1);
    //     setfundraiserdata((prevSatte) => {
    //         return { ...prevSatte, 
    //             about: { ...prevSatte.about, 
    //                         medicalreport: [ ...newArr ] 
    //                     } 
    //         }
    //     })
    // }

  return (
    <>
            <ToastContainer autoClose={2000} position="top-center"  />
            <div className="add_company_main_div">
                <div className='upper_section'>

                    <div className='input_box'>
                        <label htmlFor="">FundRaiser Name</label>
                        <input type="text" placeholder='Enter fundraiser name' id='compnay_name' 
                            value={fundraisername} disabled
                        />
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">URL POST FIX (SLUG)</label>
                        <input type="text" placeholder='Enter fundraiser url' id='compnay_name' 
                            value={slug} onChange={(e) => setSlug(e.target.value)}
                        />
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">Cause</label>
                        <input type="text" placeholder='Enter cause' id='compnay_name' 
                            value={cause} disabled
                        />
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">Email</label>
                        <input type="text" placeholder='Enter cause' id='compnay_name' 
                            value={email} disabled
                        />
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">Patient Name</label>
                        <input type="text" placeholder='Enter cause' id='compnay_name' 
                            value={patientname} disabled
                        />
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">Patient Mobile No</label>
                        <input type="text" placeholder='Enter cause' id='compnay_name' 
                            value={`${patientcountrycode} ${patientmobileno}`} disabled
                        />
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">Patient Email</label>
                        <input type="text" placeholder='Enter cause' id='compnay_name' 
                            value={patientemail} disabled
                        />
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">Patient Id</label>
                        <input type="text" placeholder='Enter cause' id='compnay_name' 
                            value={patientidnumber} disabled
                        />
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">Health Issue</label>
                        <input type="text" placeholder='Enter cause' id='compnay_name' 
                            value={healthissue} disabled
                        />
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">Fundraiser Title</label>
                        <input type="text" placeholder='Enter cause' id='compnay_name' 
                            value={fundraisertitle} disabled
                        />
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">Hospital Name</label>
                        <input type="text" placeholder='Enter cause' id='compnay_name' 
                            value={hospitalname} disabled
                        />
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">Hospital Country</label>
                        <input type="text" placeholder='Enter cause' id='compnay_name' 
                            value={hospitalcountry} disabled
                        />
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">Hospital City</label>
                        <input type="text" placeholder='Enter cause' id='compnay_name' 
                            value={hospitalcity} disabled
                        />
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">Mobile No</label>
                        <input type="text" placeholder='Enter cause' id='compnay_name' 
                            value={`${countrycode} ${mobileno}`} disabled
                        />
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">Goal Amount</label>
                        <input type="text" placeholder='Enter goal amount' id='compnay_name' 
                            name="goalamount" value={goalamount} disabled
                        />
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">Medical Report</label>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '3vh' }}>
                            <p style={{ margin: '5px 0', width: '90%' }}>
                                <a href={`${SERVER_URL}/${medicalreport}`} target={'_blank'} rel="noreferrer">{medicalreport}</a>
                            </p>
                        </div>
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">Image</label>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '3vh' }}>
                            <a href={`${SERVER_URL}/${image}`} target={'_blank'} rel="noreferrer">
                                <img src={`${SERVER_URL}/${image}`} style={{ width: "90px" }} alt="" />
                            </a>
                        </div>
                    </div>

                </div>
                <div className='lower_section' style={{ margin: "1rem 0", display: 'flex', gap: "2vh"  }}>
                    <button style={{ background: 'white', color: 'green', border: '1px solid green' }} onClick={handleApprove} >Approve</button>
                    <button style={{ background: 'white', color: 'tomato', border: '1px solid tomato' }} onClick={handlereject} >Reject</button>
                    <button style={{ background: 'white', color: 'blue', border: '1px solid blue' }} onClick={handleuserdata} >User-Detail</button>
                </div>
            </div>
    </>
  )
}

export default ViewFundRaiserApproval
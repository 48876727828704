import React, { useEffect, useRef, useContext } from 'react';
import Notifications from './Notifications/Notifications';
import AdminProfile from './Profile/AdminProfile';
import mainContext from '../../contexts/mainContext';
import "./OffCanvas.css";
import "./Profile/Profile.css";
import "./EditSection/Edit.css";
import Close_btn from './Assets/Menu.svg';
import Filter from './Filter/Filter'
import Invoice from './Invoice/Invoice'
import EditDriverBankDetails from './EditBankDetails/EditDriverBankDetails';
import EditAdminProfile from './EditSection/EditAdminProfile';
import EditDriverProfile from './EditSection/EditDriverProfile';
import EditNotification from './EditSection/EditNotification';
import AddKnowledgeBase from './AddSections/AddKnowledgeBase';
import KnowledgeBase from './ViewSection/KnowledgeBase';
import AddSurgeLocation from './AddSections/AddSurgeLocation';
import EditPromo from './EditSection/EditPromo';
import AddNewPromo from './AddSections/AddNewPromo';
import AddNewDriver from './AddSections/AddNewDriver';
import AddDriverBank from './AddSections/AddDriverBank';
import AddDriverDocument from './AddSections/AddDriverDocument';
import AddCompnay from './AddSections/AddCompnay';
import EditCompanyBankDetails from './EditBankDetails/EditCompanyBankDetails';
import AddCompanyBankDetails from './AddSections/AddCompanyBankDetails';
import AddCompanyDocuments from './AddSections/AddCompanyDocuments';
import EditCompany from './EditSection/EditCompany';
import EditCompanydocuments from './EditSection/EditCompanydocuments'
import FAQRider from './ViewSection/FAQRider';
import FAQDriver from './ViewSection/FAQDriver';
import EditFAQRider from './EditSection/EditFAQRider';
import EditFAQDriver from './EditSection/EditFAQDriver';
import AddFAQRider from './AddSections/AddFAQRider';
import AddOrganisation from './AddSections/AddOrganisation';
import AddOrganisationDocuments from './AddSections/AddOrganisationDocuments';
import AddFAQDriver from './AddSections/AddFAQDriver';
import OrganisationProfile from './Profile/OrganisationProfile';
import EditOrganisationProfile from './EditSection/EditOrganisationProfile';
import EditEmployeeProfile from './EditSection/EditEmployeeProfile';
import AddDriverLevel from './AddSections/AddDriverLevel';
import EditDriverLevel from './EditSection/EditDriverLevel';
import EditRefferal from './EditSection/EditRefferal';

import AddNotification from './AddSections/AddNotification';
import ViewNotification from './ViewSection/ViewNotification';
import EditKnowledgeBase from './EditSection/EditKnowledgeBase';
import AddEmergencyNumber from './AddSections/AddEmergencyNumber';
import EditEmergencyNumber from './EditSection/EditEmergencyNumber'
import EmergencyNumberFilter from './Filter/EmergencyNumberFilter';

import AddPatient from './Patient/AddPatient';
import EditPatient from './Patient/EditPatient';
import PatientFilter from './Filter/PatientFilter';

import AddFundRaiser from './FundRaiser/AddFundRaiser';
import AddFundRaiserBankDetail from './FundRaiser/AddFundRaiserBank';
import EditFundRaiser from './FundRaiser/EditFundRaiser';
import EditFundRaiserBankDetail from './FundRaiser/EditFundRaiserBank';
import FundraiserFilter from './Filter/FundraiserFilter';

import AddBanner from './Banner/AddBanner';
import EditBanner from './Banner/EditBanner';
import BannerFilter from './Filter/BannerFilter';

import AddOurPartner from './OurPartner/AddOurPartner';
import EditOurPartner from './OurPartner/EditOurPartner';
import PartnerFilter from './Filter/PartnerFilter';

import AddBlog from './Blog/AddBlog';
import EditBlog from './Blog/EditBlog';
import BlogFilter from './Filter/BlogFilter';

import AddSuccessStory from './SuccessStory/AddSuccessStory';
import EditSuccessStory from './SuccessStory/EditSuccessStory';
import SuccessStoryFilter from './Filter/SuccessStoryFilter';

import AddFaq from './Faq/AddFaq';
import EditFaq from './Faq/EditFaq';
import FaqFilter from './Filter/FaqFilter';

import AddTestimonial from './Testimonial/AddTestimonial';
import EditTestimonial from './Testimonial/EditTestimonial';
import TestimonialFilter from './Filter/TestimonialFilter';

import AddAmenities from './Amenities/AddAmenities';
import EditAmenities from './Amenities/EditAmenities';
import AmenitiesFilter from './Filter/AmenitiesFilter';

import AddKeypoint from './KeyPoint/AddKeyPoint';
import EditKeypoint from './KeyPoint/EditKeyPoint';
import KeyPointFilter from './Filter/KeyPointFilter';

import AddPricingFees from './PricingFees/AddPricingFees';
import EditPricingFees from './PricingFees/EditPricingFees';
import PricingFeesFilter from './Filter/PricingFeesFilter';

import AddHowItWork from './HowItWork/AddHowItWork';
import EditHowItWork from './HowItWork/EditHowitWork';
import HowitworksFilter from './Filter/HowitworksFilter';

import EditCrowdFundingCard from './MedicalCrowdFunding/EditCrowdFundingCard';
import EditCrowdFundingFund from './MedicalCrowdFunding/EditCrowdFundingFund';
import AddCrowdFunding from './MedicalCrowdFunding/AddCrowdFunding';
import CrowdFundingFilter from './Filter/CrowdFundingFilter';

import EditAboutName from './About/EditAboutName';
import EditAboutMission from './About/EditAboutMission';

import AddSocialMedia from './SocialMedia/AddSocialMedia';
import EditSocialMedia from './SocialMedia/EditSocialMedia';

import EditTeamMember from './Team/EditTeammember';
import EditTeam from './Team/EditTeam';
import AddTeam from './Team/AddTeam';

import AddPromote from './Promote/AddPromote';
import EditPromote from './Promote/EditPromote';
import AddVideo from './Video/AddVideo';
import EditVideo from './Video/EditVideo';
import AddTrustAndSafety from './TrustAndSafety/AddTrustAndSafety';
import EditTrustAndSafety from './TrustAndSafety/EditTrustAndSafety';
import EditTrustAndSafetyCard from './TrustAndSafety/EditTrustAndSafetyCard';
import EditTrustAndSafetyPieces from './TrustAndSafety/EditTrustAndSafetyPieces';
import ViewFundRaiserApproval from './FundRaiserApproval/ViewFundRaiserApproval';
import ViewUserDetail from './FundRaiserApproval/ViewUserDetail';
import ViewReport from './Report/ViewReport';
import ViewCareer from './Career/ViewCareer';
import EditFaqDataFundRaiserCard from './FaqData/EditFaqDataFundRaiserCard';
import EditFaqDataQuickLinksCard from './FaqData/EditFaqDataQuickLinksCard';
import AddFundRaisingTip from './FundRaisingTip/AddFundRaisingTip';
import EditFundRaisingTipCard from './FundRaisingTip/EditFundRaisingTipCard';
import EditFundRaisingTip from './FundRaisingTip/EditFundRaisingTip';
import ViewTransactionApproval from './TransactionApproval/ViewTransactionApproval';
import ViewTransactionUserDetail from './TransactionApproval/ViewTransactionUserDetail';
import AddStripe from './StripeData/AddStripe';
import EditStripe from './StripeData/EditStripe';
import AddAboutus from './About/AddAbout';
import AddFaqData from './FaqData/AddFaqData';
import EditCrowdFundingSingleCard from './MedicalCrowdFunding/EditCrowdFundingSingleCard';
import EditCrowdFundingSingleFund from './MedicalCrowdFunding/EditCrowdFundingSingleFund';
import EditAboutSingleName from './About/EditAboutSingleName';
import EditAboutSingleMission from './About/EditAboutSingleMission';
import EditSingleTeamMember from './Team/EditSingleTeamMember';
import EditPromoteSingleName from './Promote/EditPromoteSingleName';
import EditFundRaisingSingleTipCard from './FundRaisingTip/EditFundRaisingSingleTipCard';
import EditFaqDataSingleFundRaiserCard from './FaqData/EditFaqDataSingleFundraiserCard';
import EditFaqDataSingleQuickLinksCard from './FaqData/EditFaqDataSingleQuickLinksCards';
import EditTrustAndSafetySingleCard from './TrustAndSafety/EditTrustAndSafetySingleCard';
import EditTrustAndSafetySinglePieces from './TrustAndSafety/EditTrustAndSafetySinglePieces';

const OffCanvas = () => {
    
    const firstUpdate = useRef(true);
    const context = useContext(mainContext);
    
    const {
        inCanvas,
        setinCanvas,
        canvasTitle,
        canvasSubTitle,
        allFunctions,
        setAllFunctions,
    } = context;
    
    const handleCanvasClose = () => {
        document.querySelector(".off_canvas_main_div").classList.remove("off_canvas_active");
        setinCanvas("unset");
    }
    
    useEffect(() => {
        setAllFunctions({ handleCanvasClose, ...allFunctions })
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        if (inCanvas === "unset") {
            return;
        }
        else {
            document.querySelector(".off_canvas_main_div").classList.add("off_canvas_active");
        }
        // eslint-disable-next-line
    }, [inCanvas]);

    return (
        <>
            <div className="off_canvas_main_div">
                <span className="before" onClick={handleCanvasClose}></span>
                <div className="off_canvas_content">
                    <div className="off_canvas_header">
                        <div className="off_canvs_title">
                            <h3>{canvasTitle}</h3>
                            <small>{canvasSubTitle}</small>
                        </div>
                        <span onClick={handleCanvasClose}><img src={Close_btn} alt='' /></span>
                    </div>
                    {
                        inCanvas === "filter" &&
                        <Filter />
                    }
                    {
                        inCanvas === "Trip invoice" &&
                        <Invoice />
                    }
                    {
                        inCanvas === "Notification" &&
                        <Notifications />
                    }
                    {
                        inCanvas === "Admin Profile" &&
                        <AdminProfile />
                    }
                    {
                        inCanvas === "Organisation profile" &&
                        <OrganisationProfile />
                    }
                    {
                        inCanvas === "Employee profile" &&
                        <EditEmployeeProfile />
                    }
                    {
                        inCanvas === "faq-rider" &&
                        <FAQRider />
                    }
                    {
                        inCanvas === "faq-driver" &&
                        <FAQDriver />
                    }
                    {
                        inCanvas === "View notification" &&
                        <ViewNotification />
                    }
                    {
                        inCanvas === "Edit admin profile" &&
                        <EditAdminProfile />
                    }
                    {
                        inCanvas === "Edit driver profile" &&
                        <EditDriverProfile />
                    }
                    {
                        inCanvas === "Edit driver bank details" &&
                        <EditDriverBankDetails />
                    }
                    {
                        inCanvas === "Edit company bank details" &&
                        <EditCompanyBankDetails />
                    }
                    {
                        inCanvas === "Edit company documents" &&
                        <EditCompanydocuments />
                    }
                    {
                        inCanvas === "Edit organisation profile" &&
                        <EditOrganisationProfile />
                    }
                    {
                        inCanvas === "Edit faq-rider" &&
                        <EditFAQRider />
                    }
                    {
                        inCanvas === "Edit faq-driver" &&
                        <EditFAQDriver />
                    }
                    {
                        inCanvas === "Add new faq-rider" &&
                        <AddFAQRider />
                    }
                    {
                        inCanvas === "Add new faq-driver" &&
                        <AddFAQDriver />
                    }
                    {
                        inCanvas === "Add new companies" &&
                        <AddCompnay />
                    }
                    {
                        inCanvas === "Add new organisation" &&
                        <AddOrganisation />
                    }
                    {
                        inCanvas === "Add company bank details" &&
                        <AddCompanyBankDetails />
                    }
                    {
                        inCanvas === "Add company documents" &&
                        <AddCompanyDocuments />
                    }
                    {
                        inCanvas === "Add organisation documents" &&
                        <AddOrganisationDocuments />
                    }
                    {
                        inCanvas === "Add new driver" &&
                        <AddNewDriver />
                    }
                    {
                        inCanvas === "Add driver document" &&
                        <AddDriverDocument />
                    }
                    {
                        inCanvas === "Add driver bank" &&
                        <AddDriverBank />
                    }
                    {
                        inCanvas === "Add new surge location" &&
                        <AddSurgeLocation />
                    }
                    {
                        inCanvas === "Add new driver levels" &&
                        <AddDriverLevel />
                    }
                    {
                        inCanvas === "Add new notifications" &&
                        <AddNotification />
                    }
                    {
                        inCanvas === "Edit driver level" &&
                        <EditDriverLevel />
                    }
                    {
                        inCanvas === "Edit notification" &&
                        <EditNotification />
                    }
                    {
                        inCanvas === "Edit company" &&
                        <EditCompany />
                    }
                    {
                        inCanvas === "Add new knowledgebase" &&
                        <AddKnowledgeBase />
                    }
                    {
                        inCanvas === "Edit knowledgebase" &&
                        <EditKnowledgeBase />
                    }
                    {
                        inCanvas === "knowledgebase" &&
                        <KnowledgeBase />
                    }
                    {
                        inCanvas === "Add new emergency numbers" &&
                        <AddEmergencyNumber />
                    }
                    {
                        inCanvas === "Edit emergency number" &&
                        <EditEmergencyNumber />
                    }
                    {
                        inCanvas === "Add new promos & offers" &&
                        <AddNewPromo />
                    }
                    {
                        inCanvas === "Edit promo" &&
                        <EditPromo />
                    }
                    {
                        inCanvas === "Edit refferal" &&
                        <EditRefferal />
                    }
                    {
                        inCanvas === "Add new fundraising-tip" &&
                        <AddFundRaisingTip />
                    }
                    {
                        inCanvas === 'Edit Fund-Raising-Tip-Card' &&
                        <EditFundRaisingTipCard />
                    }
                    {
                        inCanvas === "Edit Fund-Raising-Single-Tip-Card" &&
                        <EditFundRaisingSingleTipCard />
                    }
                    {
                        inCanvas === 'Edit Fund-Raising-Tip' &&
                        <EditFundRaisingTip />
                    }
                    

{/* -------------------------------------------------------------- */}

                    {
                        inCanvas === "Add new patient" &&
                        <AddPatient />
                    }

                    {
                        inCanvas === "Edit Patient" &&
                        <EditPatient />
                    }

                    {
                        inCanvas === "add fundraiser bankdetail" &&
                        <AddFundRaiserBankDetail />
                    }

                    {
                        inCanvas === "Add new fundraiser" &&
                        <AddFundRaiser />
                    }

                    {
                        inCanvas === "edit fundraiser bankdetail" &&
                        <EditFundRaiserBankDetail />
                    }

                    {
                        inCanvas === "Edit Fund Raiser" &&
                        <EditFundRaiser />
                    }

                    {
                        inCanvas === "Add new banner" &&
                        <AddBanner />
                    }

                    {
                        inCanvas === "Edit Banner" &&
                        <EditBanner />
                    }

                    {
                        inCanvas === "Add new association" &&
                        <AddOurPartner />
                    }

                    {
                        inCanvas === "Edit Our Partner" &&
                        <EditOurPartner />
                    }

                    {
                        inCanvas === "Add new blog" &&
                        <AddBlog />
                    }

                    {
                        inCanvas === "Edit Blog" &&
                        <EditBlog />
                    }

                    {
                        inCanvas === "Add new success-story" &&
                        <AddSuccessStory />
                    }

                    {
                        inCanvas === "Edit Success Story" &&
                        <EditSuccessStory />
                    }

                    {
                        inCanvas === "Add new faq" &&
                        <AddFaq />
                    }

                    {
                        inCanvas === "Edit Faq" &&
                        <EditFaq />
                    }

                    {
                        inCanvas === "Add new testimonial" &&
                        <AddTestimonial />
                    }

                    {
                        inCanvas === "Edit Testimonial" &&
                        <EditTestimonial />
                    }

                    {
                        inCanvas === "Add new amenities" &&
                        <AddAmenities />
                    }

                    {
                        inCanvas === "Edit Amenities" &&
                        <EditAmenities />
                    }

                    {
                        inCanvas === "Add new keypoint" &&
                        <AddKeypoint />
                    }

                    {
                        inCanvas === "Edit KeyPoint" &&
                        <EditKeypoint />
                    }

                    {
                        inCanvas === "Add new pricing-fees" &&
                        <AddPricingFees />
                    }

                    {
                        inCanvas === "Edit Pricing-Fees" &&
                        <EditPricingFees />
                    }

                    {
                        inCanvas === "Add new how-it-work" &&
                        <AddHowItWork />
                    }

                    {
                        inCanvas === "Edit How-It-Work" &&
                        <EditHowItWork />
                    }

                    {
                        inCanvas === "Add new medical crowdfunding" &&
                        <AddCrowdFunding />
                    }

                    {
                        inCanvas === "Edit crowdFunding Card" &&
                        <EditCrowdFundingCard />
                    }

                    {
                        inCanvas === "Edit crowdFunding Single Card" &&
                        <EditCrowdFundingSingleCard />
                    }

                    {
                        inCanvas === "Edit crowdFunding Fund" &&
                        <EditCrowdFundingFund />
                    }

                    {
                        inCanvas === "Edit crowdFunding Single Fund" &&
                        <EditCrowdFundingSingleFund />
                    }

                    {
                        inCanvas === "Add new about-us" &&
                        <AddAboutus />
                    }

                    {
                        inCanvas === "Edit about name" &&
                        <EditAboutName />
                    }

                    {
                        inCanvas === "Edit about single name" &&
                        <EditAboutSingleName />
                    }

                    {
                        inCanvas === "Edit about mission" &&
                        <EditAboutMission />
                    }

                    {
                        inCanvas === "Edit about single mission" &&
                        <EditAboutSingleMission />
                    }

                    {
                        inCanvas === "Add new social-media" &&
                        <AddSocialMedia />
                    }

                    {
                        inCanvas === "Edit SocialMedia" &&
                        <EditSocialMedia />
                    }

                    {
                        inCanvas === "Add new team" &&
                        <AddTeam />
                    }

                    {
                        inCanvas === "Edit Team member" &&
                        <EditTeamMember />
                    }

                    {
                        inCanvas === "Edit Single Team member" &&
                        <EditSingleTeamMember />
                    }

                    {
                        inCanvas === "Edit Team" &&
                        <EditTeam />
                    }

                    {
                        inCanvas === "Add new promote" &&
                        <AddPromote />
                    }

                    {
                        inCanvas === "Edit Promote" &&
                        <EditPromote />
                    }

                    {
                        inCanvas === "Edit promote single name" &&
                        <EditPromoteSingleName />
                    }

                    {
                        inCanvas === "Add new video" &&
                        <AddVideo />
                    }

                    {
                        inCanvas === "Edit Video" &&
                        <EditVideo />
                    }

                    {
                        inCanvas === "Add new trust-safety" &&
                        <AddTrustAndSafety />
                    }

                    {
                        inCanvas === "Edit Trust And Safety" &&
                        <EditTrustAndSafety />
                    }

                    {
                        inCanvas === "Edit Trust And Safety Card Detail" &&
                        <EditTrustAndSafetyCard />
                    }

                    {
                        inCanvas === "Edit Trust And Safety Single Card Detail" &&
                        <EditTrustAndSafetySingleCard />
                    }

                    {
                        inCanvas === "Edit Trust And Safety Piece Detail" &&
                        <EditTrustAndSafetyPieces />
                    }

                    {
                        inCanvas === "Edit Trust And Safety Single Piece Detail" &&
                        <EditTrustAndSafetySinglePieces />
                    }

                    {
                        inCanvas === "View Fund Raiser Approval" &&
                        <ViewFundRaiserApproval />
                    }

                    {
                        inCanvas === "view user detail" &&
                        <ViewUserDetail />
                    }

                    {
                        inCanvas === "View Report" &&
                        <ViewReport />
                    }

                    {
                        inCanvas === "View Career" &&
                        <ViewCareer />
                    }

                    {
                        inCanvas === "Add new faq-data" &&
                        <AddFaqData />
                    }

                    {
                        inCanvas === "Edit Faq Data FundRaiser Detail" &&
                        <EditFaqDataFundRaiserCard />
                    }

                    {
                        inCanvas === "Edit Faq Data Single FundRaiser Detail" &&
                        <EditFaqDataSingleFundRaiserCard />
                    }

                    {
                        inCanvas === "Edit Faq Data QuickLink Detail" &&
                        <EditFaqDataQuickLinksCard />
                    }

                    {
                        inCanvas === "Edit Faq Data Single QuickLink Detail" &&
                        <EditFaqDataSingleQuickLinksCard />
                    }

                    {
                        inCanvas === "View Transaction Approval" &&
                        <ViewTransactionApproval />
                    }

                    {
                        inCanvas === "view transaction user detail" &&
                        <ViewTransactionUserDetail />
                    }

                    {
                        inCanvas === "Add new stripe" &&
                        <AddStripe />
                    }

                    {
                        inCanvas === "Edit Stripe Data" &&
                        <EditStripe />
                    }


                    {/* FILTERS OFF CANVASES */}
                    {
                        inCanvas === "EmergencyNumberFilter" &&
                        <EmergencyNumberFilter/>
                    }
                    {
                        inCanvas==="PatientFilter" &&
                        <PatientFilter/>
                    }
                    {
                        inCanvas==="BlogFilter" &&
                        <BlogFilter/>
                    }
                    {
                        inCanvas==="FundraiserFilter" &&
                        <FundraiserFilter/>
                    }
                    {
                        inCanvas==="BannerFilter" &&
                        <BannerFilter/>
                    }
                    {
                        inCanvas==="PartnerFilter" &&
                        <PartnerFilter/>
                    }
                    {
                        inCanvas==="SuccessStoryFilter" &&
                        <SuccessStoryFilter/>
                    }
                    {
                        inCanvas==="TestimonialFilter" &&
                        <TestimonialFilter/>
                    }
                    {
                        inCanvas==="AmenitiesFilter" &&
                        <AmenitiesFilter/>
                    }
                    {
                        inCanvas==="KeyPointFilter" &&
                        <KeyPointFilter/>
                    }
                    {
                        inCanvas==="HowitworksFilter" &&
                        <HowitworksFilter/>
                    }
                    {
                        inCanvas==="FaqFilter" &&
                        <FaqFilter/>
                    }
                    {
                        inCanvas==="PricingFeesFilter" &&
                        <PricingFeesFilter/>
                    }
                    {
                        inCanvas==="CrowdFundingFilter" &&
                        <CrowdFundingFilter/>
                    }
                </div>
            </div>
        </>
    )
}

export default OffCanvas

import React, { useEffect, useContext, useState } from 'react'
import mainContext from '../../../contexts/mainContext';
import { ToastContainer, toast } from 'react-toastify';
import UploadPhoto from '../../../Controller/UploadPhoto';
import Organisation from '../../../Controller/Organisation';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function AddHowItWork() {

    const context = useContext(mainContext);
    const [flag, setFlag] = useState(false);
    const url = new URLSearchParams(window.location.search);
    const { 
        setCanvasTitle,
        // setinCanvas,  
        setCanvasSubTitle,
        allFunctions
    } = context;

    const { handleCanvasClose, getHowitworkList } = allFunctions;

    const initialState = { 
        isavailable: true, title: '', button1: '', button2: '', url1: '', url2: '', desc: '', point: [], image: ''
    }

    const [howitworkdata, setHowItWorkData] = useState(initialState);
    const { isavailable, title, button1, button2, url1, url2, desc, point, image  } = howitworkdata;
    const [ pointContent, setPointContent ] = useState('');

    // const [value, setValue] = useState('');
    const Editor= {
        formats: [
            'header', 'font', 'size',
            'bold', 'italic', 'underline', 'strike', 'blockquote',
            'list', 'bullet', 'indent',
            'link', 'image', 'video'
        ],

        modules: {
            toolbar: [
              [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
              [{size: []}],
              ['bold', 'italic', 'underline', 'strike', 'blockquote'],
              [{'list': 'ordered'}, {'list': 'bullet'}, 
               {'indent': '-1'}, {'indent': '+1'}],
              ['link', 'image', 'video'],
              ['clean']
            ],
            clipboard: {
              // toggle to add extra line breaks when pasting HTML:
              matchVisual: false,
            }
          }

    }


    const handleChange = (e) => {
        const { name, value } = e.target;
        setHowItWorkData((prevSatte) => {
            return { ...prevSatte, [name]: value }
        })
    }
    
    useEffect(() => {
        setCanvasTitle("Add new");
        setCanvasSubTitle("How It Work");
        // eslint-disable-next-line
    }, [])

    const changeAvatar = async(e) => {

        const { name } = e.target;

        const err = await UploadPhoto.checkImage(e.target.files[0]);
        if(err) {
            return toast.warning(err,{
                pauseOnHover: false,
                theme: "light"
            });
        }

        const image = await UploadPhoto.uploadPhoto(e.target.files[0]);
        // console.log({ image })
        const { path } = image;
        setHowItWorkData((prevSatte) => {
            return { ...prevSatte, [name]: path }
        })

        // setImage(e.target.files[0]);
    }

    const handleImg = (id) => {
        // console.log('handle image')
        document.getElementById(id).click();
    }

    const handleSubmit = async() => {

        if(flag) return;
        setFlag(true);

        const res = await Organisation.createHowItWork(howitworkdata)
        // console.log({ res })
        if(res && (res.status === 200 || res.status === 201)){
            toast.success("Data Is Created Successfully",{
                pauseOnHover: false,
                theme: "light"
            });

            setTimeout(async() => {
                await getHowitworkList(url.get('page'))
                await handleCanvasClose();
            }, 2000)
        }
        else{
            if(typeof(res.data.message) == 'object' )
                toast.error(res.data.message ? res.data.message[0] : "SomeThing Went Wrong",{
                    pauseOnHover: false,
                    theme: "light"
                });
            else{
                toast.error(res.data.message ? res.data.message : "SomeThing Went Wrong",{
                    pauseOnHover: false,
                    theme: "light"
                });
            }

        }

        setFlag(false);
    }

    const handlePoint = () => {
        setHowItWorkData((prevSatte) => {
            return { ...prevSatte, point: [...prevSatte.point, { desc: pointContent } ] }
        })

        setPointContent((prev) => '');
    }

    function handleDelete(index){
        // console.log({ index })
        const newArr = [...point];
        newArr.splice(index, 1);
        // setImages(newArr)
        setHowItWorkData((prevSatte) => {
            return { ...prevSatte, point: newArr }
        })
    }

  return (
    <>
            <ToastContainer autoClose={2000} position="top-center"  />
            <div className="add_company_main_div">
                <div className='upper_section'>

                    <div className="input_box">
                        <div className="title">Available</div>
                        <div className="check_box" style={{display: "flex", alignItems: "center", gap: "6vh", fontSize: "24px"}}>
                            <div>
                                <input checked={isavailable === true} type="checkbox" 
                                    onChange={(e) => { setHowItWorkData((prevSatte) => {return { ...prevSatte, isavailable: true }}) }}  
                                    id="status_active" style={{ marginRight: '1vh' }} 
                                />
                                <label htmlFor="status_active">YES</label>
                            </div>
                            <div>
                                <input checked={isavailable === false} type="checkbox" 
                                    onChange={(e) => { setHowItWorkData((prevSatte) => {return { ...prevSatte, isavailable: false }}) }}  
                                    id="status_inactive" style={{ marginRight: '1vh' }} 
                                />
                                <label htmlFor="status_inactive">NO</label>
                            </div>
                        </div>
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">Title</label>
                        <input type="text" placeholder='Enter Title' id='compnay_name'
                            name="title" value={title} onChange={handleChange}
                        />
                    </div>

                <div style={{ display: 'flex', gap: '3vh' }}>
                    <div className='input_box'>
                        <label htmlFor="">First Button Title</label>
                        <input type="text" placeholder='Enter Title' id='compnay_name'
                            name="button1" value={button1} onChange={handleChange}
                        />
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">First Url</label>
                        <input type="text" placeholder='Enter Url' id='compnay_name'
                            name="url1" value={url1} onChange={handleChange}
                        />
                    </div>
                </div>

                <div style={{ display: 'flex', gap: '3vh' }}>
                    <div className='input_box'>
                        <label htmlFor="">Second Button Title</label>
                        <input type="text" placeholder='Enter Title' id='compnay_name'
                            name="button2" value={button2} onChange={handleChange}
                        />
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">Second Url</label>
                        <input type="text" placeholder='Enter Url' id='compnay_name'
                            name="url2" value={url2} onChange={handleChange}
                        />
                    </div>
                </div>

                <div className="input_box">
                    <label htmlFor="notification_body">Enter Description</label>
                    <ReactQuill 
                        theme="snow" 
                        value={desc} 
                        onChange={(value) => setHowItWorkData((prev) => { return { ...prev, desc: value } })}
                        modules={Editor.modules}
                        formats={Editor.formats} 
                    />
                    
                </div>

                <div className='input_box upload_photo'>
                    <label htmlFor="">Image</label>
                    <input type="file" style={{ display: "none" }} id="img_input" accept=".jpg, .jpeg, .png" onChange={changeAvatar} name="image" />
                    <div style={{ margin: "5% 0" }}>
                        {
                            image !== ""
                            ? <img src={`${SERVER_URL}/${image}`} style={{ width: "90px" }} alt="" />
                            : ""
                        }
                        <button onClick={() => handleImg('img_input')}>Upload image</button>
                    </div>
                </div>

                    <div style={{ display: 'flex', alignItems: 'center', gap: '3vh', justifyContent: 'space-between' }}>
                        <div className='input_box' style={{ flex: 1 }}>
                            <label htmlFor="">Points</label>
                            <input type="text" placeholder='Enter Name' id='compnay_name' value={pointContent} onChange={(e) => setPointContent(() => e.target.value)} />
                        </div>
                        <div className='lower_section'>
                            <button style={{ margin: 0, width: '17vh', padding: '0.5rem 0' }} onClick={handlePoint}>Add</button>
                        </div>
                    </div>

                    {
                        point &&
                        point.length > 0 &&
                        point.map((item, index) => {
                            return (
                                <div key={index} style={{ display: 'flex', alignItems: 'center', gap: '3vh' }}>
                                    <p style={{ margin: '5px 0', width: '90%' }}>{item?.desc}</p>
                                    <i className="fa fa-trash" aria-hidden="true" style={{ cursor: 'pointer' }} onClick={() => handleDelete(index)}></i>
                                </div>
                            )
                        })
                    }

                </div>
                <div className='lower_section' style={{marginTop: "1rem"}}>
                    <button onClick={handleSubmit}>Save And Proceed</button>
                </div>
            </div>
    </>
  )
}

export default AddHowItWork
import React, { useContext } from 'react'
import mainContext from '../../../contexts/mainContext';

const SocialMediaListCard = (props) => {

    const context = useContext(mainContext);
    const { setinCanvas, setSocialMediaDetail  } = context;
    const { Linkedin, data, Facebook, Instagram, YouTube } = props;

    const handleEdit = () => {
        setSocialMediaDetail(() => data);
        setinCanvas('Edit SocialMedia');
    }

    return (
        <>
            <div className="list_card companies_list_grid" style={{ gridTemplateColumns:"5fr 5fr 5fr 5fr 3fr" }}>
                <div>{Facebook}</div>
                <div>{Instagram}</div>
                <div>{Linkedin}</div>
                <div style={{ textAlign: "right" }}>{YouTube}</div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <button className="list_card_view_btn" onClick={handleEdit}>Edit Details</button>
                </div>
            </div>
        </>
    )
}

export default SocialMediaListCard

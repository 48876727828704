import React, { useEffect, useContext, useState } from 'react'
import mainContext from '../../../contexts/mainContext';
import { ToastContainer, toast } from 'react-toastify';
import UploadPhoto from '../../../Controller/UploadPhoto';
import Organisation from '../../../Controller/Organisation';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function EditOurPartner() {

    const context = useContext(mainContext);
    const [flag, setFlag] = useState(false);
    const url = new URLSearchParams(window.location.search);
    const { 
        setCanvasTitle,
        allFunctions,
        ourpartnerDetail,
        // setinCanvas,  
        setCanvasSubTitle 
    } = context;

    const { handleCanvasClose, getOurPartnerList } = allFunctions;

    const [partnerdata, setPartnerData] = useState(ourpartnerDetail);
    const { name, title, image, isactivated } = partnerdata;

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPartnerData((prevSatte) => {
            return { ...prevSatte, [name]: value }
        })
    }
    
    useEffect(() => {
        setCanvasTitle("Edit");
        setCanvasSubTitle("Association");
        // eslint-disable-next-line
    }, [])

    const changeAvatar = async(e) => {

        const { name } = e.target;

        const err = await UploadPhoto.checkImage(e.target.files[0]);
        if(err) {
            return toast.warning(err,{
                pauseOnHover: false,
                theme: "light"
            });
        }

        const image = await UploadPhoto.uploadPhoto(e.target.files[0]);
        // console.log({ image })
        const { path } = image;
        setPartnerData((prevSatte) => {
            return { ...prevSatte, [name]: path }
        })

        // setImage(e.target.files[0]);
    }

    const handleImg = (id) => {
        // console.log('handle image')
        document.getElementById(id).click();
    }

    const handleSubmit = async() => {

        if(flag) return;
        setFlag(true);

        console.log({ partnerdata })

        const res = await Organisation.updateOurPartner(partnerdata)
        // console.log({ res })
        if(res && (res.status === 200 || res.status === 201)){
            toast.success("Our Partner Is Created Successfully",{
                pauseOnHover: false,
                theme: "light"
            });

            setTimeout(async() => {
                await getOurPartnerList(url.get('page'))
                await handleCanvasClose();
            }, 2000)
        }
        else{
            if(typeof(res.data.message) == 'object' )
                toast.error(res.data.message ? res.data.message[0] : "SomeThing Went Wrong",{
                    pauseOnHover: false,
                    theme: "light"
                });
            else{
                toast.error(res.data.message ? res.data.message : "SomeThing Went Wrong",{
                    pauseOnHover: false,
                    theme: "light"
                });
            }

        }

        setFlag(false);
    }

  return (
    <>
            <ToastContainer autoClose={2000} position="top-center"  />
            <div className="add_company_main_div">
                <div className='upper_section'>

                    <div className="input_box">
                        <div className="title">Status</div>
                        <div className="check_box" style={{display: "flex", alignItems: "center", gap: "6vh", fontSize: "24px"}}>
                            <div>
                                <input checked={isactivated === true} type="checkbox" 
                                    onChange={(e) => { setPartnerData((prevSatte) => {return { ...prevSatte, isactivated: true }}) }}  
                                    id="status_active" style={{ marginRight: '1vh' }} 
                                />
                                <label htmlFor="status_active">Active</label>
                            </div>
                            <div>
                                <input checked={isactivated === false} type="checkbox" 
                                    onChange={(e) => { setPartnerData((prevSatte) => {return { ...prevSatte, isactivated: false }}) }}  
                                    id="status_inactive" style={{ marginRight: '1vh' }} 
                                />
                                <label htmlFor="status_inactive">Inactive</label>
                            </div>
                        </div>
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">Name</label>
                        <input type="text" placeholder='Enter Name' id='compnay_name'
                            name="name" value={name} onChange={handleChange}
                        />
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">Title</label>
                        <input type="text" placeholder='Enter Title' id='compnay_name'
                            name="title" value={title} onChange={handleChange}
                        />
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">Url</label>
                        <input type="text" placeholder='Enter Url' id='compnay_name'
                            value={partnerdata.url} onChange={(e) => { setPartnerData((prev) => { return { ...prev, url: e.target.value } }) }}
                        />
                    </div>

                    <div className='input_box upload_photo'>
                        <label htmlFor="">Partner Image</label>
                        <input type="file" style={{ display: "none" }} id="img_input" accept=".jpg, .jpeg, .png" onChange={changeAvatar} name="image" />
                        <div style={{ margin: "5% 0" }}>
                            {
                                image !== ""
                                ? <img src={`${SERVER_URL}/${image}`} style={{ width: "90px" }} alt="" />
                                : ""
                            }
                            <button onClick={() => handleImg('img_input')}>Upload image</button>
                        </div>
                    </div>

                </div>
                <div className='lower_section' style={{marginTop: "1rem"}}>
                    <button onClick={handleSubmit}>Update Our Partner</button>
                </div>
            </div>
    </>
  )
}

export default EditOurPartner
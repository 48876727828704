import React, { useContext, useEffect, useState } from 'react';
import SubscriberListCard from "./SubscriberListCard.jsx";
import SearchHeader from '../../SearchHeader/SearchHeader.jsx';
import "./SubscriberPage.css";
import mainContext from '../../../contexts/mainContext.jsx';
import Pagination from '../../Pagination/Pagination.jsx';
import { useNavigate } from 'react-router-dom';
import Organisation from '../../../Controller/Organisation.jsx';
import { ToastContainer, toast } from 'react-toastify';

const SubscriberPage = () => {
    
    const context = useContext(mainContext);
    const url = new URLSearchParams(window.location.search);
    const navigate = useNavigate();

    const [ subscriberList, setSubscriberList ] = useState([])
    
    let {
        param,
        setProfileNavTitle,
        totalFetchDataCount,
        setTotalFetchDataCount,
        setAllFunctions,
        allFunctions
    } = context;
    
    const getSubscriberList = async(page = 1, pass = undefined) => {
        if(!page)
            page = 1;
        navigate(`?page=${page}`)
        if(pass){
            param = pass;
        }
        param.page = page;
        const res = await Organisation.getSubscriberList(param)
        console.log({ res })
        if(res.status === 200 || res.status === 201){
            setSubscriberList(() => res.data.results)
            setTotalFetchDataCount(res.data.count)
        }else{
            toast.error(res.data.message ? res.data.message : "SomeThing Went Wrong",{
                pauseOnHover: false,
                theme: "light"
            });
        }
    }

    useEffect(() => {

        getSubscriberList(url.get("page"));
        setProfileNavTitle("Management");
        setAllFunctions({ ...allFunctions, getSubscriberList })
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <ToastContainer autoClose={2000} position="top-center"  />
            <SearchHeader callFunction={() => {}} addMoreSection={false} addSubTitle={true} subTitle="subscribers" records={totalFetchDataCount} searchPlaceHolder="Search subscribers" />
            <div className="component_body">
                <div className="list_header companies_list_grid" style={{ gridTemplateColumns:"2fr 6fr 6fr 6fr 2fr 2fr" }}>
                    <div>Email</div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div style={{ textAlign: "right" }}>Added on</div>
                    <div></div>
                </div>
                <div>

                    {
                        subscriberList.length <= 0 ?
                            <div style={{ marginTop: "2vh" }}> No record found</div>
                        :
                            <>
                                {
                                    subscriberList.map((item) => {
                                        return (
                                            <SubscriberListCard
                                                key={item._id}
                                                item={item}
                                                email={item?.email} 
                                                added={item.createdAt ? item.createdAt.split('T')[0] : ''} 
                                            />
                                        )
                                    })
                                }
                            </>
                    }

                </div>
            </div>
            {
                param.limit < totalFetchDataCount ?
                    <>
                        {
                            param.limit >= totalFetchDataCount ?
                                ""
                                :
                                <Pagination totalPage={Math.ceil(totalFetchDataCount / param.limit)} callFunction={getSubscriberList} />
                        }
                    </>
                    :
                    ""
            }
        </>
    )
}

export default SubscriberPage

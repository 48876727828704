import axios from "axios";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const Suffix = process.env.REACT_APP_SUFFIX;
const authToken = localStorage.getItem("authToken");

class UploadPhoto {
    async uploadPhoto(fileInput) {
        try {
            var formdata = new FormData();
            formdata.append("file", fileInput);

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            const imgUrl = await fetch(`${SERVER_URL}/${Suffix}/media`, requestOptions)
            return imgUrl.json()
        } catch (error) {
            console.log(error);
            return error
        }
    }

    async checkImage(file){
        let err = "";
        if(!file) return err= "File does not exist"
    
        if(file.size > 15 * 1024 * 1024) //15mb
        err= 'the largest image size is 1mb';

        // console.log({ file })
    
        if(file.type !== 'image/jpeg' && file.type !== 'image/png' && file.type !== 'application/pdf' )
        err = 'Image format is incorrect';
    
        return err;
    }

    async checkVideo(file){
        let err = "";
        if(!file) return err= "File does not exist"
    
        if(file.size > 15 * 1024 * 1024) //15mb
        err= 'the largest video size is 1mb';

        // console.log({ file })
    
        if(file.type !== 'video/mp4' && file.type !== 'video/mp4' )
        err = 'Video format is incorrect';
    
        return err;
    }

    // async uploadVideo2(fileInput) {
    //     try {
    //         var formdata = new FormData();
    //         formdata.append("file", fileInput);

    //         var requestOptions = {
    //             method: 'POST',
    //             body: formdata,
    //             redirect: 'follow',
    //             headers: {
    //                 'Authorization': `Bearer ${authToken}`,
    //                 'Content-Type': 'application/json'
    //             },
    //         };

    //         const imgUrl = await fetch(`${SERVER_URL}/${Suffix}/media/video`, requestOptions)
    //         return imgUrl.json()
    //     } catch (error) {
    //         console.log(error);
    //         return error
    //     }
    // }

    async uploadVideo(fileInput){
        
        
        try {

            var formdata = new FormData();
            formdata.append("file", fileInput);

            const config = {
                method: 'POST',
                url: `${SERVER_URL}/${Suffix}/media/video`,
                data: formdata,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };
            const res = await axios(config);
            return res;

        } catch (error) {
            return error.response
        }
    }

    async validateAlphaNumericvalue(value = ''){
        for(let i=0; i<value.length; i++){
            let code = value.charCodeAt(i);
            console.log({ code })
            if((code >= 97 && code <= 122) || (code >= 65 && code <= 90) || (code === 32)){}
            else return false;
        }
        return true;
    }

    async validateLength(value = '', length = 0){
        console.log({ value, length })
        if(value.length > length) return false;
        return true
    }

}

const uploadPhoto = new UploadPhoto();

export default uploadPhoto;

import React, { useEffect, useContext, useState } from 'react'
import mainContext from '../../../contexts/mainContext';
import { ToastContainer, toast } from 'react-toastify';
import UploadPhoto from '../../../Controller/UploadPhoto';
import Organisation from '../../../Controller/Organisation';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function EditSuccessStory() {

    const context = useContext(mainContext);
    const [flag, setFlag] = useState(false);
    const url = new URLSearchParams(window.location.search);
    const { 
        setCanvasTitle,
        // setinCanvas,  
        setCanvasSubTitle,
        allFunctions,
        successStoryDetail
    } = context;

    const { handleCanvasClose, getSuccessStoryList } = allFunctions;

    const [successStoryData, setSuccessStoryData] = useState(successStoryDetail);
    const { title, subtitle, lowercontent, storyimage, iscard, iscarddescription, uppercontent, seo, istrending, tag } = successStoryData;
    const [relatedSuccessStory, setRelatedSuccessStory] = useState([]);
    const [ seodata, setSeoData ] = useState('');

    // const [value, setValue] = useState('');
    const Editor= {
        formats: [
            'header', 'font', 'size',
            'bold', 'italic', 'underline', 'strike', 'blockquote',
            'list', 'bullet', 'indent',
            'link', 'image', 'video'
        ],

        modules: {
            toolbar: [
              [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
              [{size: []}],
              ['bold', 'italic', 'underline', 'strike', 'blockquote'],
              [{'list': 'ordered'}, {'list': 'bullet'}, 
               {'indent': '-1'}, {'indent': '+1'}],
              ['link', 'image', 'video'],
              ['clean']
            ],
            clipboard: {
              // toggle to add extra line breaks when pasting HTML:
              matchVisual: false,
            }
          }

    }


    const handleChange = (e) => {
        const { name, value } = e.target;
        setSuccessStoryData((prevSatte) => {
            return { ...prevSatte, [name]: value }
        })
    }

    const getRelatedSuccessStory = async() => {
        const res = await Organisation.getSuccessStoryPartialList();
        if(res && (res.status === 200 || res.status === 201)){
            setRelatedSuccessStory(() => { return res.data.results });
        }
    }
    
    useEffect(() => {
        setCanvasTitle("Edit");
        setCanvasSubTitle("Success Story");
        getRelatedSuccessStory();
        // eslint-disable-next-line
    }, [])

    const changeAvatar = async(e) => {

        const { name } = e.target;

        const err = await UploadPhoto.checkImage(e.target.files[0]);
        if(err) {
            return toast.warning(err,{
                pauseOnHover: false,
                theme: "light"
            });
        }

        const image = await UploadPhoto.uploadPhoto(e.target.files[0]);
        // console.log({ image })
        const { path } = image;
        setSuccessStoryData((prevSatte) => {
            return { ...prevSatte, [name]: path }
        })

        // setImage(e.target.files[0]);
    }

    const handleImg = (id) => {
        // console.log('handle image')
        document.getElementById(id).click();
    }

    const handleSubmit = async() => {

        if(flag) return;
        setFlag(true);

        const res = await Organisation.updateSuccessStory(successStoryData)
        // console.log({ res })
        if(res && (res.status === 200 || res.status === 201)){
            toast.success("Success Story Is Updated Successfully",{
                pauseOnHover: false,
                theme: "light"
            });

            setTimeout(async() => {
                await getSuccessStoryList(url.get('page'))
                await handleCanvasClose();
            }, 2000)
        }
        else{
            if(typeof(res.data.message) == 'object' )
                toast.error(res.data.message ? res.data.message[0] : "SomeThing Went Wrong",{
                    pauseOnHover: false,
                    theme: "light"
                });
            else{
                toast.error(res.data.message ? res.data.message : "SomeThing Went Wrong",{
                    pauseOnHover: false,
                    theme: "light"
                });
            }

        }

        setFlag(false);
    }

    function handleSeoData(){
        setSuccessStoryData((prev) => { return { ...prev, seo: [...prev.seo,  seodata] }});
        setSeoData(() => '');
    }

    function handleDelete(index){
        const newArr = [...seo];
        newArr.splice(index, 1);
        setSuccessStoryData((prevSatte) => {
            return { ...prevSatte, seo: newArr }
        })
    }

  return (
    <>
            <ToastContainer autoClose={2000} position="top-center"  />
            <div className="add_company_main_div">
                <div className='upper_section'>

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className="input_box" style={{ width: '45%' }}>
                        <div className="title">Card</div>
                        <div className="check_box" style={{display: "flex", alignItems: "center", gap: "6vh", fontSize: "24px"}}>
                            <div>
                                <input checked={iscard === true} type="checkbox" 
                                    onChange={(e) => { setSuccessStoryData((prevSatte) => {return { ...prevSatte, iscard: true }}) }}  
                                    id="status_active" style={{ marginRight: '1vh' }} 
                                />
                                <label htmlFor="status_active">YES</label>
                            </div>
                            <div>
                                <input checked={iscard === false} type="checkbox" 
                                    onChange={(e) => { setSuccessStoryData((prevSatte) => {return { ...prevSatte, iscard: false }}) }}  
                                    id="status_inactive" style={{ marginRight: '1vh' }} 
                                />
                                <label htmlFor="status_inactive">NO</label>
                            </div>
                        </div>
                    </div>

                    <div className="input_box" style={{ width: '45%' }}>
                        <div className="title">Is Trending</div>
                        <div className="check_box" style={{display: "flex", alignItems: "center", gap: "6vh", fontSize: "24px"}}>
                            <div>
                                <input checked={istrending === true} type="checkbox" 
                                    onChange={(e) => { setSuccessStoryData((prevSatte) => {return { ...prevSatte, istrending: true }}) }}  
                                    id="status_trending" style={{ marginRight: '1vh' }} 
                                />
                                <label htmlFor="status_trending">YES</label>
                            </div>
                            <div>
                                <input checked={istrending === false} type="checkbox" 
                                    onChange={(e) => { setSuccessStoryData((prevSatte) => {return { ...prevSatte, istrending: false }}) }}  
                                    id="status_not_trending" style={{ marginRight: '1vh' }} 
                                />
                                <label htmlFor="status_not_trending">NO</label>
                            </div>
                        </div>
                    </div>
                </div>

                    <div className='input_box'>
                        <label htmlFor="">Story-Tag</label>
                        <input type="text" placeholder='Enter Story Tag' id='compnay_name'
                            name="tag" value={tag} onChange={handleChange}
                        />
                    </div>

                    <div className="input_box">
                        <label htmlFor="notification_body">Card Description</label>
                        <textarea id="notification_body" placeholder='enter card description' 
                            name='iscarddescription' value={iscarddescription} onChange={handleChange}
                        />
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">Title</label>
                        <input type="text" placeholder='Enter Name' id='compnay_name'
                            name="title" value={title} onChange={handleChange}
                        />
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">Sub Title</label>
                        <input type="text" placeholder='Enter Name' id='compnay_name'
                            name="subtitle" value={subtitle} onChange={handleChange}
                        />
                    </div>

                    {
                        false && 
                        <div className='input_box'>
                        <label htmlFor="">Related Story</label>
                        <div>
                            <select name="patientid" onChange={handleChange} style={{width: "20rem"}}>
                                <option value=""></option>
                                {
                                    relatedSuccessStory.map((item, index) => {
                                        // console.log({ item })
                                        return (
                                            <option key={index} value={item._id}>
                                                {item.title}
                                            </option>
                                        )
                                    })
                                }
                            </select>
                            
                        </div>
                        </div>
                    }

                    <div className='input_box'>
                        <label htmlFor="">Upper Content</label>
                        <input type="text" placeholder='Enter Title' id='compnay_name'
                            name="uppercontent" value={uppercontent} onChange={handleChange}
                        />
                    </div>

                    <div className="input_box">
                        <label htmlFor="notification_body">Lower Content</label>
                        <ReactQuill 
                            theme="snow" 
                            value={lowercontent} 
                            onChange={(value) => setSuccessStoryData((prev) => { return { ...prev, lowercontent: value } })}
                            modules={Editor.modules}
                            formats={Editor.formats} 
                        />
                        
                    </div>

                    <div className='input_box upload_photo'>
                        <label htmlFor="">Story Image</label>
                        <input type="file" style={{ display: "none" }} id="img_input" accept=".jpg, .jpeg, .png" onChange={changeAvatar} name="storyimage" />
                        <div style={{ margin: "5% 0" }}>
                            {
                                storyimage !== ""
                                ? <img src={`${SERVER_URL}/${storyimage}`} style={{ width: "90px" }} alt="" />
                                : ""
                            }
                            <button onClick={() => handleImg('img_input')}>Upload image</button>
                        </div>
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center', gap: '3vh', justifyContent: 'space-between' }}>
                        <div className='input_box' style={{ flex: 1 }}>
                            <label htmlFor="">Points</label>
                            <input type="text" placeholder='Enter Name' id='compnay_name' 
                                value={seodata} onChange={(e) => setSeoData(() => e.target.value)} 
                            />
                        </div>
                        <div className='lower_section'>
                            <button style={{ margin: 0, width: '17vh', padding: '0.5rem 0' }} onClick={handleSeoData}>Add</button>
                        </div>
                    </div>

                    {
                        seo &&
                        seo.length > 0 &&
                        seo.map((item, index) => {
                            return (
                                <div key={index} style={{ display: 'flex', alignItems: 'center', gap: '3vh' }}>
                                    <p style={{ margin: '5px 0', width: '90%' }}>{item}</p>
                                    <i className="fa fa-trash" aria-hidden="true" style={{ cursor: 'pointer' }} onClick={() => handleDelete(index)}></i>
                                </div>
                            )
                        })
                    }

                </div>
                <div className='lower_section' style={{marginTop: "1rem"}}>
                    <button onClick={handleSubmit}>Save And Proceed</button>
                </div>
            </div>
    </>
  )
}

export default EditSuccessStory
import React, { useEffect, useContext, useState } from 'react'
import mainContext from '../../../contexts/mainContext';
import { ToastContainer, toast } from 'react-toastify';
import UploadPhoto from '../../../Controller/UploadPhoto';
import Organisation from '../../../Controller/Organisation';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function AddVideo() {

    const context = useContext(mainContext);
    const [flag, setFlag] = useState(false);
    const url = new URLSearchParams(window.location.search);
    const { 
        setCanvasTitle,
        // setinCanvas,  
        setCanvasSubTitle,
        allFunctions
    } = context;

    const { handleCanvasClose, getvideoList } = allFunctions;

    const initialState = { 
        slug: 'home', videopath: '', points: []
    }

    const [howitworkdata, setHowItWorkData] = useState(initialState);
    const { slug, videopath, points  } = howitworkdata;
    const initialState2 = { content: '', heading: '' };
    const [ pointContent, setPointContent ] = useState(initialState2);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setHowItWorkData((prevSatte) => {
            return { ...prevSatte, [name]: value }
        })
    }

    const handleChange2 = (e) => {
        const { name, value } = e.target;
        setPointContent((prevSatte) => {
            return { ...prevSatte, [name]: value }
        })
    }
    
    useEffect(() => {
        setCanvasTitle("Add new");
        setCanvasSubTitle("Video");
        // eslint-disable-next-line
    }, [])

    const changeAvatar = async(e) => {

        const { name } = e.target;

        const err = await UploadPhoto.checkVideo(e.target.files[0]);
        if(err) {
            return toast.warning(err,{
                pauseOnHover: false,
                theme: "light"
            });
        }

        const image = await UploadPhoto.uploadVideo(e.target.files[0]);
        console.log({ image })
        const { path } = image?.data;
        console.log({ path })
        setHowItWorkData((prevSatte) => {
            return { ...prevSatte, [name]: path }
        })

        // setImage(e.target.files[0]);
    }

    const handleImg = (id) => {
        // console.log('handle image')
        document.getElementById(id).click();
    }

    const handleSubmit = async() => {

        if(flag) return;
        setFlag(true);

        const res = await Organisation.createVideo(howitworkdata)
        // console.log({ res })
        if(res && (res.status === 200 || res.status === 201)){
            toast.success("Video Data Is Created Successfully",{
                pauseOnHover: false,
                theme: "light"
            });

            setTimeout(async() => {
                await getvideoList(url.get('page'))
                await handleCanvasClose();
            }, 2000)
        }
        else{
            if(typeof(res.data.message) == 'object' )
                toast.error(res.data.message ? res.data.message[0] : "SomeThing Went Wrong",{
                    pauseOnHover: false,
                    theme: "light"
                });
            else{
                toast.error(res.data.message ? res.data.message : "SomeThing Went Wrong",{
                    pauseOnHover: false,
                    theme: "light"
                });
            }

        }

        setFlag(false);
    }

    const handleAddData = () => {
        setHowItWorkData((prevSatte) => {
            return { ...prevSatte, points: [ ...prevSatte.points, pointContent ] }
        })

        setPointContent((prev) => initialState2);
    }

    function handleDelete(index){
        // console.log({ index })
        const newArr = [...points];
        newArr.splice(index, 1);
        // setImages(newArr)
        setHowItWorkData((prevSatte) => {
            return { ...prevSatte, points: newArr }
        })
    }

    function handleEdit(index, item){
        const newArr = [ ...points ];
        newArr.splice(index, 1);

        setHowItWorkData((prevSatte) => {
            return { ...prevSatte, points: newArr }
        })

        setPointContent(() => item);
    }

  return (
    <>
            <ToastContainer autoClose={2000} position="top-center"  />
            <div className="add_company_main_div">
                <div className='upper_section'>

                <div className='input_box'>
                    <label htmlFor="">Slug</label>
                    <div className='select_input'>
                        <select name="slug" value={slug} onChange={handleChange}>
                            <option value="home">Home</option>
                            <option value="crowd-funding">Crowd Funding</option>
                            <option value="fundraisingtip">Fund Raising Tip</option>
                            <option value="how-it-work">How It Work</option>
                        </select>
                    </div>
                </div>

                <div className='input_box upload_photo'>
                    <label htmlFor="">Video</label>
                    <input type="file" style={{ display: "none" }} id="img_input" accept=".mp4, .mkv" onChange={changeAvatar} name="videopath" />
                    <div style={{ margin: "5% 0" }}>
                        {
                            videopath !== ""
                            ? <video src={`${SERVER_URL}/${videopath}`} style={{ width: "90px" }} alt="" />
                            : ""
                        }
                        <button onClick={() => handleImg('img_input')}>Upload video</button>
                    </div>
                </div>

                <h3>Points</h3>
                <div style={{ display: 'flex', alignItems: 'center', gap: '3vh', justifyContent: 'space-between' }}>
                    
                    <div className='input_box' style={{ flex: 1 }}>
                        <label htmlFor="">Heading</label>
                        <input type="text" placeholder='Enter Name' id='compnay_name' 
                            name="heading" value={pointContent.heading} onChange={handleChange2}
                        />
                    </div>
                    
                </div>

                <div className='input_box' style={{ flex: 1 }}>
                    <label htmlFor="">Content</label>
                    <textarea type="text" placeholder='Enter Name' id='compnay_name' 
                        name="content" value={pointContent.content} onChange={handleChange2}
                    />
                </div>

                <div className='lower_section'>
                    <button style={{ margin: 0, width: '17vh', padding: '0.5rem 0' }} onClick={handleAddData}>Add</button>
                </div>

                    {
                        points &&
                        points.length > 0 &&
                        points.map((item, index) => {
                            return (
                                <div key={index} style={{ display: 'flex', alignItems: 'center', gap: '3vh' }}>
                                    <p style={{ margin: '5px 0', width: '90%' }}>{item?.heading}</p>
                                    <i className="fas fa-edit" style={{ cursor: 'pointer' }}
                                        onClick={() => handleEdit(index, item)}
                                    ></i>
                                    <i className="fa fa-trash" aria-hidden="true" style={{ cursor: 'pointer' }} onClick={() => handleDelete(index)}></i>
                                </div>
                            )
                        })
                    }

                </div>
                <div className='lower_section' style={{marginTop: "1rem"}}>
                    <button onClick={handleSubmit}>Save And Proceed</button>
                </div>
            </div>
    </>
  )
}

export default AddVideo
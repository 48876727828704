import React, { useContext } from 'react'
import mainContext from '../../../contexts/mainContext';
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const Names = (props) => {

    const context = useContext(mainContext);
    const { setinCanvas, setPromoteSingleDetail, setPromoteDetail } = context;
    const { title, heading, image, item, data } = props;

    const handleClick = () => {
        setPromoteDetail(() => { return { name: data.name, _id: data._id }});
        setPromoteSingleDetail(() => item);
        setinCanvas('Edit promote single name')
    }

    return (
        <>

            <div className="list_card companies_list_grid" style={{ gridTemplateColumns:"3fr 3fr 6fr 3fr" }}>
                <div>{title}</div>
                <div>{heading}</div>
                <div style={{ textAlign: 'center' }}>
                    <img src={`${SERVER_URL}/${image}`} alt="" style={{ width: '40px' }} />
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <button className="list_card_view_btn" onClick={handleClick}>Edit Names</button>
                </div>

            </div>
        </>
    )
}

export default Names

import React, { useContext } from 'react'
import mainContext from '../../../contexts/mainContext';

const PricingFeesListCard = (props) => {

    const context = useContext(mainContext);
    const { setinCanvas, setPricingFeesDetail } = context;
    const { added, title, item, subscriptiontype, fees } = props;

    const handleClick = () => {
        setPricingFeesDetail(() => { return item })
        setinCanvas('Edit Pricing-Fees')
    }

    return (
        <>
            <div className="list_card companies_list_grid" style={{ gridTemplateColumns:"3fr 6fr 6fr 5fr 2fr" }}>
                {/* <div>
                    <div className={`list_card_status status_${activated ? 'active' : 'inactive'}`}>{activated ? 'TRUE' : 'FALSE'}</div>
                </div> */}
                <div>{title}</div>
                <div>{subscriptiontype}</div>
                <div>{fees}</div>
                <div style={{ textAlign: "right" }}>{added}</div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <button className="list_card_view_btn" onClick={handleClick}>Edit</button>
                </div>
            </div>
        </>
    )
}

export default PricingFeesListCard

import React, { useContext, useEffect } from 'react'
import mainContext from '../../../contexts/mainContext';

const AmenitiesFilter = () => {

    const context = useContext(mainContext);
    
    const {
        setCanvasTitle,
        allFunctions,
        param
    } = context;

    const { getAmenitiesList, handleCanvasClose } = allFunctions;  

    useEffect(() => {
        setCanvasTitle("Filter");
        // eslint-disable-next-line
    }, [])

    const handleSubmit = async() => {
        await getAmenitiesList(1, param);
        handleCanvasClose();
    }

    const resetFilter = () => {
        const res = document.getElementsByClassName('value-holder');
        for( let i=0;i<res.length;i++ ) {
            res[i].value='';
        }
    }
    
    return (
        <>
            <div className="filter_main_div">
                <div>

                    <div className="input_box">
                        <div className="title">Via status</div>
                        <div className="select_box">
                            <div>
                                <input type="checkbox" name="" id="status_active" />
                                <label htmlFor="status_active">Active</label>
                            </div>
                            <div>
                                <input type="checkbox" id="status_inactive" />
                                <label htmlFor="status_inactive">Inactive</label>
                            </div>
                        </div>
                    </div>

                    <div className="input_box">
                        <label htmlFor="date">Via Date</label>
                        <strong>From</strong>
                        <input type="date" id='from_date' className='value-holder' onChange={(e) =>  param.filterDate.from =  e.target.value }/>
                        <strong>To</strong>
                        <input type="date" id='to_date' className='value-holder' onChange={(e) =>  param.filterDate.to =  e.target.value } />
                    </div>

                </div>

                <div className="btns">
                    <button onClick={handleSubmit}>Apply filter</button>
                    <button className='delete_btn' onClick={resetFilter}>Reset filter</button>
                </div>
            </div>
        </>
    )
}

export default AmenitiesFilter

import React from 'react'
import { useLocation } from 'react-router';
import './HomePage.css';
import Analytics from '../Analytics/AnalyticsPage.jsx';
import Menu from '../Menu/Menu.jsx';
import AdminNotifications from '../Notification/AdminNotifications.jsx'
import ProfileNav from '../ProfileNav/ProfileNav.jsx';
import ManagementPage from '../Management/ManagementPage.jsx';
import OffCanvas from '../OffCanvas/OffCanvas.jsx';


import PatientPage from '../Management/Patient/PatientPage';
import FundRaiserPage from '../Management/FundRaiser/FundRaiserPage';
import BannerPage from '../Management/Banner/BannerPage';
import OurPartnerPage from '../Management/OurPartner/OurPartnerPage';
import BlogPage from '../Management/Blog/BlogPage';
import SuccessStoryPage from '../Management/SuccessStory/SuccessStoryPage';
import FaqsPage from '../Management/Faqs/FaqsPage';
import TestimonialPage from '../Management/Testimonial/TestimonialPage';
import AmenitiesPage from '../Management/Amenities/AmenitiesPage';
import KeyPointPage from '../Management/KeyPoint/KeyPointPage';
import PricingFeesPage from '../Management/PricingFees/PricingFeesPage';
import HowitWorkPage from '../Management/HowItWork/HowitWorkPage';
import MedicalCrowdFundingPage from '../Management/MedicalCrowdFunding/MedicalCrowdFundingPage';
import Pages from '../Management/Pages';
import AboutPage from '../Management/Aboutus/AboutPage';
import SocialMediaPage from '../Management/SocialMedia/SocialMediaPage';
import TeamPage from '../Management/Team/TeamPage';
import PromotePage from '../Management/Promote/PromotePage';
import VideoPage from '../Management/Video/VideoPage';
import TrustAndSafetyPage from '../Management/TrustSafety/TrustandSafetyPage';
import Forms from '../Management/Form';

import SubscriberPage from '../Management/Subscriber/SubscriberPage';
import FundRaiserApprovalPage from '../Management/FundRaiserApproval/FundRaiserApprovalPage';
import ReportPage from '../Management/Report/ReportPage';
import CareerPage from '../Management/Career/CareerPage';
import FaqDataPage from '../Management/FaqData/FaqDataPage';
import FundRaisingTipPage from '../Management/FundRaisingTipPage/FundRaisingTipPage';
import TransactionApprovalPage from '../Management/TransactionApproval/TransactionApprovalPage';
import StripePage from '../Management/StripeData/StripePage';

const HomePage = () => {
    const location = useLocation();
    return (
        <>
            <div className="admin_main_div">
                <Menu />
                <OffCanvas />
                <div className="detail_section">
                    <ProfileNav />
                    <span id="detail_section_before"></span>
                    {
                        location.pathname === "/" &&
                        <Analytics />
                    }
                    {
                        location.pathname === "/Admin-Notifications" &&
                        <AdminNotifications />
                    }
                    {
                        location.pathname === "/Management" &&
                        <ManagementPage />
                    }
                    {
                        location.pathname === "/Pages" &&
                        <Pages />
                    }
                    {
                        location.pathname === "/Forms" &&
                        <Forms />
                    }

                    {/* -------------------------------------------------------- */}

                    {
                        location.pathname === "/Management/patient" &&
                        <PatientPage />
                    }

                    {
                        location.pathname === "/Management/fundraiser" &&
                        <FundRaiserPage />
                    }

                    {
                        location.pathname === "/Management/banner" &&
                        <BannerPage />
                    }

                    {
                        location.pathname === "/Management/our-partner" &&
                        <OurPartnerPage />
                    }

                    {
                        location.pathname === "/Management/blog" &&
                        <BlogPage />
                    }

                    {
                        location.pathname === "/Management/success-story" &&
                        <SuccessStoryPage />
                    }

                    {
                        location.pathname === "/Management/faqs" &&
                        <FaqsPage />
                    }

                    {
                        location.pathname === "/Management/testimonial" &&
                        <TestimonialPage />
                    }

                    {
                        location.pathname === "/Management/amenities" &&
                        <AmenitiesPage />
                    }

                    {
                        location.pathname === "/Management/key-point" &&
                        <KeyPointPage />
                    }

                    {/* ---------------------------Pages Section Start ----------------------------------- */}

                    {
                        location.pathname === "/Pages/pricing-and-fees" &&
                        <PricingFeesPage />
                    }

                    {
                        location.pathname === "/Pages/how-it-work" &&
                        <HowitWorkPage />
                    }

                    {
                        location.pathname === "/Pages/medical-crowdfunding" &&
                        <MedicalCrowdFundingPage />
                    }

                    {
                        location.pathname === "/Pages/about-us" &&
                        <AboutPage />
                    }
                    
                    {
                        location.pathname === "/Pages/social-media" &&
                        <SocialMediaPage />
                    }
                    
                    {
                        location.pathname === "/Pages/team" &&
                        <TeamPage />
                    }
                    
                    {
                        location.pathname === "/Pages/promote" &&
                        <PromotePage />
                    }
                    
                    {
                        location.pathname === "/Pages/video" &&
                        <VideoPage />
                    }

                    {
                        location.pathname === "/Pages/trust-safety" &&
                        <TrustAndSafetyPage />
                    }

                    {
                        location.pathname === "/Pages/faq-data" &&
                        <FaqDataPage />
                    }

                    {
                        location.pathname === "/Pages/fundraising-tip" &&
                        <FundRaisingTipPage />
                    }

                    {/* -------------------------------Forms-------------------------------- */}

                    {
                        location.pathname === "/Forms/subscriber" &&
                        <SubscriberPage />
                    }

                    {
                        location.pathname === "/Forms/fundraiser-approval" &&
                        <FundRaiserApprovalPage />
                    }

                    {
                        location.pathname === "/Forms/reports" &&
                        <ReportPage />
                    }

                    {
                        location.pathname === "/Forms/career" &&
                        <CareerPage />
                    }

                    {
                        location.pathname === "/Forms/transaction-approval" &&
                        <TransactionApprovalPage />
                    }

                    {
                        location.pathname === "/Forms/stripe-data" &&
                        <StripePage />
                    }

                </div>
            </div>
        </>
    )
}

export default HomePage

import React, { useContext } from 'react'
import mainContext from '../../../contexts/mainContext';
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const Pieces = (props) => {

    const context = useContext(mainContext);
    const { setinCanvas, settrustandsafetyPieceDetail, settrustandsafetySinglePieceDetail } = context;
    const { title, isavailable, image, item, data } = props;

    const handleClick = () => {
        settrustandsafetyPieceDetail(() => { return { pieces: data.pieces, _id: data._id } });
        settrustandsafetySinglePieceDetail(() => item)
        setinCanvas('Edit Trust And Safety Single Piece Detail')
    }

    let status = isavailable ? 'active' : 'inactive'

    return (
        <>
            <div className="list_card companies_list_grid" style={{ gridTemplateColumns:"3fr 3fr 6fr 3fr" }}>
                <div>
                    <div className={`list_card_status status_${status}`}>{status}</div>
                </div>
                <div>{title}</div>
                <div style={{ textAlign: 'center' }}>
                    <img src={`${SERVER_URL}/${image}`} alt="" style={{ width: '40px' }} />
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <button className="list_card_view_btn" onClick={handleClick}>Edit Piece</button>
                </div>
            </div>
        </>
    )
}

export default Pieces

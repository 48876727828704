import React, { useState, useEffect } from 'react'
import "./loginPage.css";
import logo from './logo.png';
import cover_photo from './cover_photo.png';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Auth from '../../Controller/Auth';

const LoginPage = () => {

    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [pass, setPass] = useState('');

    const handleLogin = async(e) => {
        e.preventDefault();

        try {

            const res = await Auth.login({ email, password: pass })
            if(res && (res.status === 200 || res.status === 201)){

                localStorage.setItem("authToken", res.data.data.accessToken);

                toast.success("Login Successfully",{
                    pauseOnHover: false,
                    theme: "light"
                });


                setTimeout(() => {
                    navigate('/');
                    window.location.reload();
                }, 2000)
            }
            else{

                if(typeof(res.data.message) == 'object' )
                    return toast.error(res.data.message ? res.data.message[0] : "SomeThing Went Wrong",{
                        pauseOnHover: false,
                        theme: "light"
                    });
                else
                    return toast.error(res.data.message ? res.data.message : "SomeThing Went Wrong",{
                        pauseOnHover: false,
                        theme: "light"
                    });
            }

        }catch(err){
            toast.error("Invalid Credentials",{
                pauseOnHover: false,
                theme: "light"
            });
        }
        
    }

    useEffect(() => {

        function checkToken(){
            if(localStorage.getItem('authToken'))
                navigate('/');
        }

        checkToken();
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <ToastContainer autoClose={2000} position="top-center"  />
            <div className="login_page_main_div">
                <div className="left_section">
                    <div className="login_section">
                        <div className="hyratech_logo">
                            <img src={logo} alt="" />
                            {/* <h4>ORGANISATION</h4> */}
                        </div>
                        <div className="login_text">
                            <h3 style={{ marginBottom: "6px" }}>Welcome !</h3>
                            <p>Enter your email address & password to access the organisation</p>
                        </div>
                        <div className="login_form">
                            <label htmlFor="email">Email Address</label>
                            <input type="email" id="email" placeholder="enter here" value= {email} onChange={(e) => setEmail(e.target.value)} />
                            <label htmlFor="password">Password</label>
                            <input type="password" placeholder="enter here" value= {pass} onChange={(e) => setPass(e.target.value)} />
                            {/* <div className="login_forgot_section">
                                Forgot Password? <span>Click here to reset</span>
                            </div> */}
                            <button className="login_btn" onClick={handleLogin} style={{ marginTop: '35%' }}>Login to hyratech Admin</button>
                        </div>
                    </div>
                </div>
                <div className="right_section">
                    <img src={cover_photo} alt="" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                </div>
            </div>
        </>
    )
}

export default LoginPage

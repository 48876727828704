import React, { useContext, useEffect, useState } from 'react';
import TrustAndSafetyListCard from "./TrustandSafetyCard.jsx";
import SearchHeader from '../../SearchHeader/SearchHeader.jsx';
import "./TrustandSafetyPage.css";
import mainContext from '../../../contexts/mainContext.jsx';
import Pagination from '../../Pagination/Pagination.jsx';
import { useNavigate } from 'react-router-dom';
import Organisation from '../../../Controller/Organisation.jsx';
import { ToastContainer, toast } from 'react-toastify';
import Cards from './cards.jsx';
import Pieces from './pieces.jsx';

const TrustAndSafetyPage = () => {
    
    const context = useContext(mainContext);
    const url = new URLSearchParams(window.location.search);
    const navigate = useNavigate();

    const [ trustAndSafetyList, setTrustAndSafetyList ] = useState([])
    
    let {
        param,
        setProfileNavTitle,
        totalFetchDataCount,
        setTotalFetchDataCount,
        setAllFunctions,
        allFunctions
    } = context;
    
    const getTrustAndSafetyList = async(page = 1, pass = undefined) => {
        if(!page)
            page = 1;
        navigate(`?page=${page}`)
        if(pass){
            param = pass;
        }
        param.page = page;
        const res = await Organisation.getTrustAndSafetyList(param)
        // console.log({ res })
        if(res.status === 200 || res.status === 201){
            setTrustAndSafetyList(() => res.data.results)
            setTotalFetchDataCount(res.data.count)
        }else{
            toast.error(res.data.message ? res.data.message : "SomeThing Went Wrong",{
                pauseOnHover: false,
                theme: "light"
            });
        }
    }

    useEffect(() => {

        getTrustAndSafetyList(url.get("page"));
        setProfileNavTitle("Management");
        setAllFunctions({ ...allFunctions, getTrustAndSafetyList })
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <ToastContainer autoClose={2000} position="top-center"  />
            <SearchHeader callFunction={() => {}} addMoreSection={true} addSubTitle={true} subTitle="trust-safety" records={totalFetchDataCount} searchPlaceHolder="Search trust-safety" />
            <div className="component_body">
                <div className="list_header companies_list_grid" style={{ gridTemplateColumns:"5fr 5fr 2fr 5fr 3fr 3fr" }}>
                    <div>Card</div>
                    <div>Pieces</div>
                    <div>Added on</div>
                    <div style={{ textAlign: "right" }}></div>

                </div>
                <div>

                    {
                        trustAndSafetyList.length <= 0 ?
                            <div style={{ marginTop: "2vh" }}> No record found</div>
                        :
                            <>

                                {
                                    trustAndSafetyList.map((item) => {
                                        return (
                                            <TrustAndSafetyListCard
                                                key={item._id}
                                                card={item?.card}
                                                pieces={item?.pieces}
                                                item={item}
                                                added={item.createdAt ? item.createdAt.split('T')[0] : ''} 
                                            />
                                        )
                                    })
                                }
                                
                            </>
                    }

                </div>

                <>
                    <br />
                    <h3>Cards</h3>
                    <div className="list_header companies_list_grid" style={{ gridTemplateColumns:"3fr 3fr 6fr 3fr" }}>
                        <div>IsAvailable</div>
                        <div>Description</div>
                        <div style={{ textAlign: 'center' }}>Image</div>
                        <div></div>
                    </div>
                    <div>
                        {
                            trustAndSafetyList.length <= 0 ?
                                <div style={{ marginTop: "2vh" }}> No Cards Present</div>
                            :  
                                trustAndSafetyList[0].card?.map((item, index) => {
                                    return (
                                        <Cards 
                                            isavailable={item.isavailable}
                                            image={item.image}
                                            description={item.description}
                                            item={item}
                                            key={index}
                                            data={trustAndSafetyList[0]}
                                        />
                                    )
                                })
                        }
                        
                    </div>
                </>

                <>
                    <br />
                    <h3>Pieces</h3>
                    <div className="list_header companies_list_grid" style={{ gridTemplateColumns:"3fr 3fr 6fr 3fr" }}>
                        <div>IsAvailable</div>
                        <div>Title</div>
                        <div style={{ textAlign: 'center' }}>Image</div>
                        <div></div>
                    </div>
                    <div>
                        {
                            trustAndSafetyList.length <= 0 ?
                                <div style={{ marginTop: "2vh" }}> No Pieces Present</div>
                            :  
                                trustAndSafetyList[0].pieces?.map((item, index) => {
                                    return (
                                        <Pieces 
                                            isavailable={item.isavailable}
                                            image={item.image}
                                            title={item.title}
                                            item={item}
                                            key={index}
                                            data={trustAndSafetyList[0]}
                                        />
                                    )
                                })
                        }
                        
                    </div>
                </>


            </div>
            {
                param.limit < totalFetchDataCount ?
                    <>
                        {
                            param.limit >= totalFetchDataCount ?
                                ""
                                :
                                <Pagination totalPage={Math.ceil(totalFetchDataCount / param.limit)} callFunction={getTrustAndSafetyList} />
                        }
                    </>
                    :
                    ""
            }
        </>
    )
}

export default TrustAndSafetyPage

import React, { useContext } from 'react'
import mainContext from '../../../contexts/mainContext';

const BannerListCard = (props) => {

    const context = useContext(mainContext);
    const { setinCanvas, setBannerDetail } = context;
    const { added, status, item, heading, title, url } = props;

    const handleClick = () => {
        setBannerDetail(() => { return item });
        setinCanvas('Edit Banner')
    }

    return (
        <>
            <div className="list_card companies_list_grid" style={{ gridTemplateColumns:"2fr 6fr 6fr 6fr 2fr 2fr" }}>
                <div>
                    <div className={`list_card_status status_${status ? 'active' : 'inactive'}`}>{status ? 'active' : 'inactive'}</div>
                </div>
                <div>{heading}</div>
                <div>{title}</div>
                <div>{url}</div>
                <div style={{ textAlign: "right" }}>{added}</div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <button className="list_card_view_btn" onClick={handleClick}>Edit</button>
                </div>
            </div>
        </>
    )
}

export default BannerListCard

import React, { useEffect, useContext, useState } from 'react'
import mainContext from '../../../contexts/mainContext';
import { ToastContainer, toast } from 'react-toastify';
import Organisation from '../../../Controller/Organisation';
import UploadPhoto from '../../../Controller/UploadPhoto';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function AddTestimonial() {

    const context = useContext(mainContext);
    const [flag, setFlag] = useState(false);
    const url = new URLSearchParams(window.location.search);
    const { 
        setCanvasTitle,
        // setinCanvas,  
        setCanvasSubTitle,
        allFunctions
    } = context;

    const { handleCanvasClose, getTestimonialList } = allFunctions;

    const initialState = { 
        isactivated: true, image: '', heading: '', description: '', seo: []
    }

    const [testimonialData, setTestimonialData] = useState(initialState);
    const { isactivated, image, heading, description, seo } = testimonialData;
    const [ seodata, setSeoData ] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setTestimonialData((prevSatte) => {
            return { ...prevSatte, [name]: value }
        })
    }
    
    useEffect(() => {
        setCanvasTitle("Add new");
        setCanvasSubTitle("Testimonial");
        // eslint-disable-next-line
    }, [])

    const changeAvatar = async(e) => {

        const { name } = e.target;

        const err = await UploadPhoto.checkImage(e.target.files[0]);
        if(err) {
            return toast.warning(err,{
                pauseOnHover: false,
                theme: "light"
            });
        }

        const image = await UploadPhoto.uploadPhoto(e.target.files[0]);

        const { path } = image;
        setTestimonialData((prevSatte) => {
            return { ...prevSatte, [name]: path }
        })
    }

    const handleImg = (id) => {
        document.getElementById(id).click();
    }


    const handleSubmit = async() => {

        if(flag) return;
        setFlag(true);

        const res = await Organisation.createTestimonial(testimonialData)
        // console.log({ res })
        if(res && (res.status === 200 || res.status === 201)){
            toast.success("Testimonial Is Created Successfully",{
                pauseOnHover: false,
                theme: "light"
            });

            setTimeout(async() => {
                await getTestimonialList(url.get('page'))
                await handleCanvasClose();
            }, 2000)
        }
        else{
            if(typeof(res.data.message) == 'object' )
                toast.error(res.data.message ? res.data.message[0] : "SomeThing Went Wrong",{
                    pauseOnHover: false,
                    theme: "light"
                });
            else{
                toast.error(res.data.message ? res.data.message : "SomeThing Went Wrong",{
                    pauseOnHover: false,
                    theme: "light"
                });
            }

        }

        setFlag(false);
    }

    function handleSeoData(){
        setTestimonialData((prev) => { return { ...prev, seo: [...prev.seo,  seodata] }});
        setSeoData(() => '');
    }

    function handleDelete(index){
        const newArr = [...seo];
        newArr.splice(index, 1);
        setTestimonialData((prevSatte) => {
            return { ...prevSatte, seo: newArr }
        })
    }

  return (
    <>
            <ToastContainer autoClose={2000} position="top-center"  />
            <div className="add_company_main_div">
                <div className='upper_section'>

                    <div className="input_box">
                        <div className="title">Status</div>
                        <div className="check_box" style={{display: "flex", alignItems: "center", gap: "6vh", fontSize: "24px"}}>
                            <div>
                                <input checked={isactivated === true} type="checkbox" 
                                    onChange={(e) => { setTestimonialData((prevSatte) => {return { ...prevSatte, isactivated: true }}) }}  
                                    id="status_active" style={{ marginRight: '1vh' }} 
                                />
                                <label htmlFor="status_active">Active</label>
                            </div>
                            <div>
                                <input checked={isactivated === false} type="checkbox" 
                                    onChange={(e) => { setTestimonialData((prevSatte) => {return { ...prevSatte, isactivated: false }}) }}  
                                    id="status_inactive" style={{ marginRight: '1vh' }} 
                                />
                                <label htmlFor="status_inactive">InActive</label>
                            </div>
                        </div>
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">Heading</label>
                        <input type="text" placeholder='Enter heading' id='compnay_name'
                            name="heading" value={heading} onChange={handleChange}
                        />
                    </div>

                    <div className="input_box">
                        <label htmlFor="notification_body">Description</label>
                        <textarea id="notification_body" placeholder='enter description' 
                            name='description' value={description} onChange={handleChange}
                        />
                    </div>

                    

                    <div className='input_box upload_photo'>
                        <label htmlFor="">Image</label>
                        <input type="file" style={{ display: "none" }} id="img_input" accept=".jpg, .jpeg, .png" onChange={changeAvatar} name="image" />
                        <div style={{ margin: "5% 0" }}>
                            {
                                image !== ""
                                ? <img src={`${SERVER_URL}/${image}`} style={{ width: "90px" }} alt="" />
                                : ""
                            }
                            <button onClick={() => handleImg('img_input')}>Upload image</button>
                        </div>
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center', gap: '3vh', justifyContent: 'space-between' }}>
                        <div className='input_box' style={{ flex: 1 }}>
                            <label htmlFor="">Points</label>
                            <input type="text" placeholder='Enter Name' id='compnay_name' 
                                value={seodata} onChange={(e) => setSeoData(() => e.target.value)} 
                            />
                        </div>
                        <div className='lower_section'>
                            <button style={{ margin: 0, width: '17vh', padding: '0.5rem 0' }} onClick={handleSeoData}>Add</button>
                        </div>
                    </div>

                    {
                        seo &&
                        seo.length > 0 &&
                        seo.map((item, index) => {
                            return (
                                <div key={index} style={{ display: 'flex', alignItems: 'center', gap: '3vh' }}>
                                    <p style={{ margin: '5px 0', width: '90%' }}>{item}</p>
                                    <i className="fa fa-trash" aria-hidden="true" style={{ cursor: 'pointer' }} onClick={() => handleDelete(index)}></i>
                                </div>
                            )
                        })
                    }

                </div>
                <div className='lower_section' style={{marginTop: "1rem"}}>
                    <button onClick={handleSubmit}>Save And Proceed</button>
                </div>
            </div>
    </>
  )
}

export default AddTestimonial
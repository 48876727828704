import React, { useEffect, useContext, useState } from 'react'
import mainContext from '../../../contexts/mainContext';
import { ToastContainer, toast } from 'react-toastify';
import UploadPhoto from '../../../Controller/UploadPhoto';
import Organisation from '../../../Controller/Organisation'

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function AddFundRaiser() {

    const context = useContext(mainContext);
    
    const { 
        setCanvasTitle,
        setinCanvas,  
        setCanvasSubTitle,
        setFundraiserDetail
    } = context;

    const initialState = { 
        goalamount: '', healthissue: '', hospitaladdress: '', operationdate: '', image: '', patientid: '',
        about: { medicalreport: [], description: '', shortdescription: '', updates: '' }, fundraisertitle: '',
        urgency: true, slug: ''
    }

    const [patientlist, setpatientList] = useState([]);
    const [fundraiserdate, setfundraiserdata] = useState(initialState);
    const { patientid, goalamount, healthissue, hospitaladdress, operationdate, image, 
        about: { medicalreport, description, shortdescription, updates }, fundraisertitle, urgency, slug } = fundraiserdate;

    const handleChange = (e) => {
        const { name, value } = e.target;
        // console.log({ name, value })
        setfundraiserdata((prevSatte) => {
            return { ...prevSatte, [name]: value }
        })
    }

    const handleChange2 = (e) => {
        const { name, value } = e.target;
        // console.log({ name, value })
        setfundraiserdata((prevSatte) => {
            return { ...prevSatte, about: { ...prevSatte.about, [name]: value } }
        })
    }

    const getPatientList = async() => {

        try{

            const res = await Organisation.getPartialPatientList();
            if(res.status === 200){
                console.log({ res })
                setpatientList(() => { return res.data.results })
            }
        }catch(err){
            console.log({ err })
        }
        
    }
    
    useEffect(() => {
        getPatientList();
        setCanvasTitle("Add new");
        setCanvasSubTitle("Fund Raiser");
        // eslint-disable-next-line
    }, [])

    const changeAvatar = async(e) => {

        const { name } = e.target;

        const err = await UploadPhoto.checkImage(e.target.files[0]);
        if(err) {
            return toast.warning(err,{
                pauseOnHover: false,
                theme: "light"
            });
        }

        const image = await UploadPhoto.uploadPhoto(e.target.files[0]);
        console.log({ image })
        const { path } = image;

        if(name === 'medicalreport'){
            setfundraiserdata((prevSatte) => {
                return { ...prevSatte, about: { ...prevSatte.about, [name]: path } }
            })
        }else{   
            setfundraiserdata((prevSatte) => {
                return { ...prevSatte, [name]: path }
            })
        }

        // setImage(e.target.files[0]);
    }

    const changeAvatar2 = async(e) => {

        const err = await UploadPhoto.checkImage(e.target.files[0]);
        if(err) {
            return toast.warning(err,{
                pauseOnHover: false,
                theme: "light"
            });
        }

        const image = await UploadPhoto.uploadPhoto(e.target.files[0]);
        console.log({ image })
        const { path } = image;

        // if(name === 'medicalreport'){
            setfundraiserdata((prevSatte) => {
                return { ...prevSatte, 
                    about: { ...prevSatte.about, 
                                medicalreport: [ ...prevSatte.about.medicalreport, path ] 
                            } 
                }
            })
        // }else{   
        //     setfundraiserdata((prevSatte) => {
        //         return { ...prevSatte, [name]: path }
        //     })
        // }

        // setImage(e.target.files[0]);
    }

    const handleImg = (id) => {
        // console.log('handle image')
        document.getElementById(id).click();
    }

    const handleSubmit = async() => {

        // console.log(fundraiserdate)
        setFundraiserDetail((prevstate) => { return fundraiserdate })
        setinCanvas('add fundraiser bankdetail')
    }

    function handleDelete(index){
        const newArr = [...medicalreport];
        newArr.splice(index, 1);
        setfundraiserdata((prevSatte) => {
            return { ...prevSatte, 
                about: { ...prevSatte.about, 
                            medicalreport: [ ...newArr ] 
                        } 
            }
        })
    }

  return (
    <>
            <ToastContainer autoClose={2000} position="top-center"  />
            <div className="add_company_main_div">
                <div className='upper_section'>

                    <div className="input_box">
                        <div className="title">Urgency</div>
                        <div className="check_box" style={{display: "flex", alignItems: "center", gap: "6vh", fontSize: "24px"}}>
                            <div>
                                <input checked={urgency === true} type="checkbox" 
                                    onChange={(e) => { setfundraiserdata((prevSatte) => {return { ...prevSatte, urgency: true }}) }}  
                                    id="status_active" style={{ marginRight: '1vh' }} 
                                />
                                <label htmlFor="status_active">Active</label>
                            </div>
                            <div>
                                <input checked={urgency === false} type="checkbox" 
                                    onChange={(e) => { setfundraiserdata((prevSatte) => {return { ...prevSatte, urgency: false }}) }}  
                                    id="status_inactive" style={{ marginRight: '1vh' }} 
                                />
                                <label htmlFor="status_inactive">Inactive</label>
                            </div>
                        </div>
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">FundRaiser Title</label>
                        <input type="text" placeholder='Enter TItle' id='compnay_name' 
                            name="fundraisertitle" value={fundraisertitle} onChange={handleChange} 
                        />
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">URL POST FIX (SLUG)</label>
                        <input type="text" placeholder='Enter URL POST FIX' id='compnay_name' 
                            name="slug" value={slug} onChange={handleChange} 
                        />
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">Goal Amount</label>
                        <input type="text" placeholder='Enter goal amount' id='compnay_name' 
                            name="goalamount" value={goalamount} onChange={handleChange} 
                        />
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">Patient</label>
                        <div>
                            <select name="patientid" value={patientid} onChange={handleChange}>
                                <option value=""></option>
                                {
                                    patientlist.map((item, index) => {
                                        return (
                                            <option key={index} value={item._id}>
                                                {item.idnumber} - {item.contactdetail.name}
                                            </option>
                                        )
                                    })
                                }
                            </select>
                            
                        </div>
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">Health Issue</label>
                        <select name="healthissue" value={healthissue} onChange={handleChange}>
                            <option value="cardiac">Cardiac</option>
                            <option value="oncology">Oncology</option>
                            <option value="haematologyBMT">Haematology & BMT</option>
                            <option value="NeuroSpine">NeuroSpine</option>
                            <option value="Neurology">Neurology</option>
                            <option value="Orthopedics">Orthopedics</option>
                            <option value="Nephrology-Urology">Nephrology & Urology</option>
                            <option value="ENT">ENT</option>
                            <option value="GI & Bariatric">GI & Bariatric</option>
                            <option value="Liver Transplant">Liver Transplant</option>
                            <option value="Obstetrics & Gynecology">Obstetrics & Gynecology</option>
                            <option value="Opthalmology">Opthalmology</option>
                            <option value="Gastroenterology">Gastroenterology</option>
                            <option value="Dermatology & Cosmetology">Dermatology & Cosmetology</option>
                            <option value="Pulmonology">Pulmonology</option>
                            <option value="Vascular Surgery">Vascular Surgery</option>
                            <option value="Rheumatology">Rheumatology</option>
                            <option value="Internal Medicine">Internal Medicine</option>
                            <option value="Endocrinology">Endocrinology</option>
                            <option value="Kidney Transplant">Kidney Transplant</option>
                            <option value="Paediatrics">Paediatrics</option>
                            <option value="Pediatric Orthopedic Surgery">Pediatric Orthopedic Surgery</option>
                            <option value="Hepatology">Hepatology</option>
                            <option value="Pulmonology">Pulmonology</option>
                            <option value="Critical Care Medicine">Critical Care Medicine</option>
                            <option value="Pediatric Neurology">Pediatric Neurology</option>
                            <option value="Hematology">Hematology</option>
                            <option value="Spine">Spine</option>
                            <option value="RADIOLOGY">RADIOLOGY</option>
                            <option value="Dermatology">Dermatology</option>
                            <option value="Orthopedic Surgery">Orthopedic Surgery</option>
                            <option value="Pediatric Gastroenterology">Pediatric Gastroenterology</option>
                            <option value="Otolaryngology">Otolaryngology</option>
                            <option value="Orthoptics">Orthoptics</option>
                            <option value="Oculoplastics">Oculoplastics</option>
                            <option value="Pediatric">Pediatric</option>
                            <option value="Pediatrician">Pediatrician</option>
                            <option value="Urology">Urology</option>
                            <option value="Dermatology">Dermatology</option>
                            <option value="Bariatric & Metabolic surgery">Bariatric & Metabolic surgery</option>
                            <option value="Nephrology">Nephrology</option>
                            <option value="Obstetrics">Obstetrics</option>
                            <option value="Orthopedics">Orthopedics</option>
                            <option value="Hematology">Hematology</option>
                            <option value="Orthopedic">Orthopedic</option>
                            <option value="Otorhinolaryngology">Otorhinolaryngology</option>
                            <option value="Haematology">Haematology</option>
                            <option value="Liver & Biliary Sciences">Liver & Biliary Sciences</option>
                            <option value="Blood and Marrow Transplantation">Blood and Marrow Transplantation</option>
                            <option value="Nuclear Medicine">Nuclear Medicine</option>
                            <option value="Head & Neck Surgery">Head & Neck Surgery</option>
                            <option value="Bronchology  & Pulmonology">Bronchology  & Pulmonology</option>
                        </select>
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">Hospital Address</label>
                        <input type="text" placeholder='Enter hospital address' id='compnay_name' 
                            name="hospitaladdress" value={hospitaladdress} onChange={handleChange} 
                        />
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">End Date</label>
                        <input type="date" placeholder='Enter hospital address' id='compnay_name' 
                            name="operationdate" value={operationdate} onChange={handleChange} 
                        />
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">Short Description</label>
                        <input type="text" placeholder='Enter Name' id='compnay_name' 
                            name="shortdescription" value={shortdescription} onChange={handleChange2} 
                        />
                    </div>

                    <div className="input_box">
                        <label htmlFor="notification_body">Description</label>
                        <textarea id="notification_body" placeholder='enter here' 
                            name="description" value={description} onChange={handleChange2} 
                        />
                    </div>

                    <div className="input_box">
                        <label htmlFor="notification_body">updates</label>
                        <textarea id="notification_body" placeholder='enter here' 
                            name="updates" value={updates} onChange={handleChange2} 
                        />
                    </div>

                    <div className='input_box upload_photo'>
                        <label htmlFor="">Medical Report</label>
                        <input type="file" style={{ display: "none" }} id="img_input_3" accept=".pdf" onChange={changeAvatar2} name="medicalreport" />
                        <div style={{ margin: "5% 0" }}>
                            <button onClick={() => handleImg('img_input_3')}>Add medical report</button>
                        </div>
                    </div>

                    {
                        medicalreport &&
                        medicalreport.length > 0 &&
                        medicalreport.map((item, index) => {
                            return (
                                <div key={index} style={{ display: 'flex', alignItems: 'center', gap: '3vh' }}>
                                    <p style={{ margin: '5px 0', width: '90%' }}>
                                        <a href={`${SERVER_URL}/${item}`} target={'_blank'} rel="noreferrer">{index+1}. {item}</a>
                                    </p>
                                    <i className="fa fa-trash" aria-hidden="true" style={{ cursor: 'pointer' }} 
                                        onClick={() => handleDelete(index)}
                                        ></i>
                                </div>
                            )
                        })
                    }

                    <div className='input_box upload_photo'>
                        <label htmlFor="">Image</label>
                        <input type="file" style={{ display: "none" }} id="img_input_2" accept=".jpg, .jpeg, .png" onChange={changeAvatar} name="image" />
                        <div style={{ margin: "5% 0" }}>
                            {
                                image !== ""
                                ? <img src={`${SERVER_URL}/${image}`} style={{ width: "90px" }} alt="" />
                                : ""
                            }
                            <button onClick={() => handleImg('img_input_2')}>Upload image</button>
                        </div>
                    </div>

                </div>
                <div className='lower_section' style={{marginTop: "1rem"}}>
                    <button onClick={handleSubmit}>Save And Proceed</button>
                </div>
            </div>
    </>
  )
}

export default AddFundRaiser
import React, { useContext } from 'react'
import Organisation from '../../../Controller/Organisation';
import mainContext from '../../../contexts/mainContext';
import { ToastContainer, toast } from 'react-toastify';

const ReportListCard = (props) => {

    const url = new URLSearchParams(window.location.search);
    const context = useContext(mainContext);
    const { setinCanvas, setReportDetail  } = context;
    const { allFunctions } = context;
    const { getReportList } = allFunctions;
    const { added, email, item, name, urlpath, description } = props;

    const handleClick = async() => {
        const res = await Organisation.deleteReport(item._id)
        if(res && (res.status === 200 || res.status === 201)){
            toast.success("Report Is Deleted Successfully",{
                pauseOnHover: false,
                theme: "light"
            });

            await getReportList(url.get('page'))

        }
        else{
            if(typeof(res.data.message) == 'object' )
                toast.error(res.data.message ? res.data.message[0] : "SomeThing Went Wrong",{
                    pauseOnHover: false,
                    theme: "light"
                });
            else{
                toast.error(res.data.message ? res.data.message : "SomeThing Went Wrong",{
                    pauseOnHover: false,
                    theme: "light"
                });
            }

        }
    }

    const handleView = async() => {
        setReportDetail(() => item);
        setinCanvas('View Report');
    }

    return (
        <>
            <ToastContainer autoClose={2000} position="top-center"  />
            <div className="list_card companies_list_grid" style={{ gridTemplateColumns:"2fr 6fr 6fr 6fr 2fr 4fr" }}>
                <div>{name}</div>
                <div>{email}</div>
                <div>{urlpath}</div>
                <div>{description ? description.slice(0, 10) : ''}...</div>
                <div style={{ textAlign: "right" }}>{added}</div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <button className="list_card_view_btn" onClick={handleView} style={{ marginRight: '10px' }} >View</button>
                    <button className="list_card_view_btn" onClick={handleClick} >Delete</button>
                </div>
            </div>
        </>
    )
}

export default ReportListCard

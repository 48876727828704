import React  from 'react'
import { ResponsiveContainer, XAxis, YAxis, CartesianGrid, Legend, Tooltip, AreaChart, Area } from 'recharts';

const pdata = [
    {
        name: 'Jan',
        student: 12,
        fees: 13,
        price: '100',
        totalPayout: 10,
        totalRevenue: 10
    },
    {
        name: 'Feb',
        student: 2,
        fees: 36,
        price: '200',
        totalPayout: 20,
        totalRevenue: 90
    },
    {
        name: 'Mar',
        student: 70,
        fees: 65,
        price: '300',
        totalPayout: 30,
        totalRevenue: 80
    },
    {
        name: 'Apr',
        student: 70,
        fees: 65,
        price: '400',
        totalPayout: 40,
        totalRevenue: 70
    },
    {
        name: 'May',
        student: 90,
        fees: 100,
        price: '500',
        totalPayout: 50,
        totalRevenue: 60
    }
]

export function DisplayLineChart() {

  return (
    <>
        <ResponsiveContainer width="100%" aspect={3}>
            <AreaChart
                width={400}
                height={400}
                data={pdata}
                // margin={{
                //     top: 20,
                //     right: 100,
                //     left:20,
                //     bottom: 5
                // }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <YAxis />
                <XAxis dataKey="date" style={{ fontSize: "10px" }} />
                <Tooltip />
                <Legend />
                <Area  type="" dataKey="student" stroke="red" fill="#8884d821" />
                <Area  type="" dataKey="fees" stroke="green" fill="#ff000021" />
            </AreaChart>
        </ResponsiveContainer>
    </>
  )
}

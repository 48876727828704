import React, { useContext } from 'react'
import mainContext from '../../../contexts/mainContext';

const PromoteListCard = (props) => {

    const context = useContext(mainContext);
    const { setinCanvas, setPromoteDetail  } = context;
    const { item, title, benificiary, campaigner, added } = props;

    const handleEdit = () => {
        setPromoteDetail(() => item);
        setinCanvas('Edit Promote');
    }

    return (
        <>
            <div className="list_card companies_list_grid" style={{ gridTemplateColumns:"5fr 5fr 2fr 5fr 3fr" }}>
                <div>{title}</div>
                <div>{benificiary}</div>
                <div>{campaigner}</div>
                <div style={{ textAlign: "right" }}>{added}</div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <button className="list_card_view_btn" onClick={handleEdit}>Edit Details</button>
                </div>
            </div>
        </>
    )
}

export default PromoteListCard

import React, { useEffect, useContext } from 'react'
import mainContext from '../../../contexts/mainContext';

function ViewUserDetail() {

    const context = useContext(mainContext);
    
    const { 
        setCanvasTitle,
        setCanvasSubTitle,
        userdetail,
        setinCanvas
    } = context;

    const { name, email, countrycode, mobileno } = userdetail;
    
    useEffect(() => {
        setCanvasTitle("View");
        setCanvasSubTitle("User Detail");
        // eslint-disable-next-line
    }, [])

    const handleback = () => {
        setinCanvas('View Fund Raiser Approval')
    }

  return (
    <>
            <div className="add_company_main_div">
                <div className='upper_section'>

                    <div className='input_box'>
                        <label htmlFor="">Name</label>
                        <input type="text" placeholder='Enter name' id='compnay_name' 
                            value={name} disabled
                        />
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">Email</label>
                        <input type="text" placeholder='Enter email' id='compnay_name' 
                            value={email} disabled
                        />
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">Mobile No</label>
                        <input type="text" placeholder='Enter cause' id='compnay_name' 
                            value={`${countrycode} ${mobileno}`} disabled
                        />
                    </div>


                </div>
                <div className='lower_section' style={{ margin: "1rem 0", display: 'flex', gap: "2vh"  }}>                    
                    <button style={{ background: 'white', color: 'blue', border: '1px solid blue' }} onClick={handleback} >Back</button>
                </div>
            </div>
    </>
  )
}

export default ViewUserDetail
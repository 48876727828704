import React, { useContext } from 'react'
import mainContext from '../../../contexts/mainContext';

const StripeListCard = (props) => {

    const context = useContext(mainContext);
    const { setinCanvas, setStripeDetail } = context;
    const { added, email, item, isactivated } = props;

    const handleClick = () => {
        setStripeDetail(() => item);
        setinCanvas('Edit Stripe Data')
    }

    return (
        <>
            <div className="list_card companies_list_grid" style={{ gridTemplateColumns:"4fr 6fr 6fr 2fr 2fr" }}>
                <div>
                    <div className={`list_card_status status_${isactivated ? 'active' : 'inactive'}`}>{isactivated ? 'TRUE' : 'FALSE'}</div>
                </div>
                <div>{email}</div>
                <div></div>
                <div style={{ textAlign: "right" }}>{added}</div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <button className="list_card_view_btn" onClick={handleClick}>View</button>
                </div>
            </div>
        </>
    )
}

export default StripeListCard

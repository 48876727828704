import React, { useContext } from 'react'
import mainContext from '../../../contexts/mainContext';

const Fundraiser = (props) => {

    const context = useContext(mainContext);
    const { setinCanvas, setfaqDataSingleFundraiserDetail, setfaqDataFundraiserDetail } = context;
    const { title, question, answer, item, data } = props;

    const handleClick = () => {
        setfaqDataFundraiserDetail(() => { return { fundraiser: data.fundraiser, _id: data._id }});
        setfaqDataSingleFundraiserDetail(() => item);
        setinCanvas('Edit Faq Data Single FundRaiser Detail')
    }

    return (
        <>

            <div className="list_card companies_list_grid" style={{ gridTemplateColumns:"3fr 3fr 6fr 1fr" }}>
                <div>{title}</div>
                <div>{question}</div>
                <div style={{ textAlign: 'center' }}>
                    {answer}
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <button className="list_card_view_btn" onClick={handleClick}>Edit Data</button>
                </div>

            </div>
        </>
    )
}

export default Fundraiser

import React, { useContext } from 'react'
import mainContext from '../../../contexts/mainContext';

const TrustandSafetyListCard = (props) => {

    const context = useContext(mainContext);
    const { setinCanvas, settrustandsafetyCardDetail, settrustandsafetyDetail, settrustandsafetyPieceDetail } = context;
    const { item, card, pieces, added } = props;

    const handleCardEdit = () => {
        settrustandsafetyCardDetail(() => { return { card: item.card, _id: item._id }});
        setinCanvas('Edit Trust And Safety Card Detail');
    }

    const handleEdit = () => {
        settrustandsafetyDetail(() => { return { donar: item.donar, _id: item._id, campaigner: item.campaigner }});
        setinCanvas('Edit Trust And Safety')
    }

    const handlePiecesEdit = () => {
        settrustandsafetyPieceDetail(() => { return { pieces: item.pieces, _id: item._id } });
        setinCanvas('Edit Trust And Safety Piece Detail')
    }

    return (
        <>
            <div className="list_card companies_list_grid" style={{ gridTemplateColumns:"5fr 5fr 2fr 5fr 3fr 3fr" }}>
                <div>{card?.length}</div>
                <div>{pieces?.length}</div>
                <div style={{ textAlign: "right" }}>{added}</div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <button className="list_card_view_btn" onClick={handleCardEdit}>Edit Card</button>
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <button className="list_card_view_btn" onClick={handlePiecesEdit}>Edit Pieces</button>
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <button className="list_card_view_btn" onClick={handleEdit}>Edit Detail</button>
                </div>
            </div>
        </>
    )
}

export default TrustandSafetyListCard

import React, { useContext, useEffect } from 'react'
import mainContext from '../../../contexts/mainContext';

const EditEmployeeProfile = () => {
    const context = useContext(mainContext);
    const { setCanvasTitle, setCanvasSubTitle } = context;
    useEffect(() => {
        setCanvasTitle("Edit employee");
        setCanvasSubTitle("Update basic information");
        // eslint-disable-next-line
    }, [])
    return (
        <>
            <div className="edit_profile_main_div">
                <div className='upper_section'>
                    <div className='input_box'>
                        <label htmlFor="name">Full name</label>
                        <input type="text" placeholder='enter name here' id='name' />
                    </div>

                    <div className='input_box'>
                        <label htmlFor="mobile">Phone number</label>
                        <div>
                            <select>
                                <option>+971</option>
                                <option>+91</option>
                                <option>+201</option>
                                <option>+111</option>
                            </select>
                            <input type="number" placeholder='0000000000' id='mobile' />
                        </div>
                    </div>

                    <div className='input_box'>
                        <label htmlFor="email">Email address</label>
                        <input type="text" placeholder='enter email here' id='email' />
                    </div>

                    <div className='input_box img_div'>
                        <label htmlFor="">Profile image</label>
                        <div>
                            <img src="https://picsum.photos/200/300" alt="" />
                            <button>Replace image</button>
                        </div>
                    </div>
                    <div className="input_box">
                        <div className="title">Gender</div>
                        <div className="select_box">
                            <div>
                                <input type="checkbox" name="" id="male" />
                                <label htmlFor="male">Male</label>
                            </div>
                            <div>
                                <input type="checkbox" name="" id="female" />
                                <label htmlFor="female">Female</label>
                            </div>
                            <div>
                                <input type="checkbox" name="" id="other" />
                                <label htmlFor="other">Other</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='lower_section'>
                    <button>Update employee info</button>
                </div>
            </div>
        </>
    )
}

export default EditEmployeeProfile


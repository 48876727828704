import React, { useEffect, useContext, useState } from 'react'
import mainContext from '../../../contexts/mainContext';
import { ToastContainer, toast } from 'react-toastify';
import UploadPhoto from '../../../Controller/UploadPhoto';
import Organisation from '../../../Controller/Organisation';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function EditPromoteSingleName() {

    const context = useContext(mainContext);
    const [flag, setFlag] = useState(false);
    const url = new URLSearchParams(window.location.search);
    const { 
        setCanvasTitle,
        promoteDetail,  
        setCanvasSubTitle,
        allFunctions,
        promotesingleDetail
    } = context;

    const { handleCanvasClose, getPromoteList } = allFunctions;

    const [ pointContent, setPointContent ] = useState(promotesingleDetail);

    const handleChange2 = (e) => {
        const { name, value } = e.target;
        setPointContent((prevSatte) => {
            return { ...prevSatte, [name]: value }
        })
    }
    
    useEffect(() => {
        setCanvasTitle("Edit");
        setCanvasSubTitle("Promote Single Name");
        // eslint-disable-next-line
    }, [])

    const changeAvatar2 = async(e) => {

        const { name } = e.target;

        const err = await UploadPhoto.checkImage(e.target.files[0]);
        if(err) {
            return toast.warning(err,{
                pauseOnHover: false,
                theme: "light"
            });
        }

        const image = await UploadPhoto.uploadPhoto(e.target.files[0]);
        // console.log({ image })
        const { path } = image;
        setPointContent((prevSatte) => {
            return { ...prevSatte, [name]: path }
        })

        // setImage(e.target.files[0]);
    }

    const handleImg = (id) => {
        // console.log('handle image')
        document.getElementById(id).click();
    }

    const handleSubmit = async() => {

        if(flag) return;
        setFlag(true);

        for(let i=0; i<promoteDetail.name.length; i++){
            if(promoteDetail.name[i]._id === pointContent._id){
                promoteDetail.name[i] = pointContent;
            }
        }

        const res = await Organisation.updatePromote(promoteDetail)
        // console.log({ res })
        if(res && (res.status === 200 || res.status === 201)){
            toast.success("Data Is Updated Successfully",{
                pauseOnHover: false,
                theme: "light"
            });

            setTimeout(async() => {
                await getPromoteList(url.get('page'))
                await handleCanvasClose();
            }, 2000)
        }
        else{
            if(typeof(res.data.message) == 'object' )
                toast.error(res.data.message ? res.data.message[0] : "SomeThing Went Wrong",{
                    pauseOnHover: false,
                    theme: "light"
                });
            else{
                toast.error(res.data.message ? res.data.message : "SomeThing Went Wrong",{
                    pauseOnHover: false,
                    theme: "light"
                });
            }

        }

        setFlag(false);
    }

  return (
    <>
            <ToastContainer autoClose={2000} position="top-center"  />
            <div className="add_company_main_div">
                <div className='upper_section'>

                <div className='input_box' style={{ flex: 1 }}>
                    <label htmlFor="">Title</label>
                    <input type="text" placeholder='Enter Name' id='compnay_name' 
                        name="title" value={pointContent.title} onChange={handleChange2}
                    />
                </div>

                <div className='input_box' style={{ flex: 1 }}>
                    <label htmlFor="">Heading</label>
                    <input type="text" placeholder='Enter Name' id='compnay_name' 
                        name="heading" value={pointContent.heading} onChange={handleChange2}
                    />
                </div>

                <div className='input_box upload_photo'>
                    <label htmlFor="">Image</label>
                    <input type="file" style={{ display: "none" }} id="img_input_2" accept=".jpg, .jpeg, .png" onChange={changeAvatar2} name="image" />
                    <div style={{ margin: "5% 0" }}>
                        {
                            pointContent.image !== ""
                            ? <img src={`${SERVER_URL}/${pointContent.image}`} style={{ width: "90px" }} alt="" />
                            : ""
                        }
                        <button onClick={() => handleImg('img_input_2')}>Upload image</button>
                    </div>
                </div>

                </div>
                <div className='lower_section' style={{marginTop: "1rem"}}>
                    <button onClick={handleSubmit}>Save And Proceed</button>
                </div>
            </div>
    </>
  )
}

export default EditPromoteSingleName
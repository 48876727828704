import React from 'react'

const EditOrganisationProfile = () => {
    return (
        <>
            <div className="edit_profile_main_div">
                <div className='upper_section'>
                    <div className='input_box'>
                        <label htmlFor="">Full name</label>
                        <input type="text" placeholder='enter name here' id='name' />
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">Phone number</label>
                        <div>
                            <select>
                                <option></option>
                                <option value="+971">+971</option>
                                <option value="+91">+91</option>
                                <option value="+201">+201</option>
                                <option value="+111">+111</option>
                            </select>
                            <input type="number" placeholder='XX XXXX XXXX' id='mobile' />
                        </div>
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">Email address</label>
                        <input type="text" placeholder='email@MASEEHAS.in' id='email' />
                    </div>

                    <div className='input_box img_div'>
                        <label htmlFor="">Profile image</label>
                        <input type="file" id='img_input' style={{ display: "none" }} />
                        <div>
                            <button>Replace image</button>
                        </div>
                    </div>

                    <div className="input_box">
                        <div className="title">Documents</div>
                        <div className="select_box">
                            <div>
                                <input type="checkbox" id="verified" className='checkBoxs'/>
                                <label htmlFor="verified">Veirifed</label>
                            </div>
                            <div>
                                <input type="checkbox" id="unverified" className='checkBoxs'/>
                                <label htmlFor="unverified">Unverified</label>
                            </div>
                        </div>
                    </div>


                    <div className='input_box'>
                        <label htmlFor="">Organisation ID</label>
                        <input type="text" placeholder='enter here' />
                    </div>

                    <div className='input_box'>
                        <label htmlFor="contractStart">Contract started on</label>
                        <input type="date" id='contractStart' />
                    </div>

                    <div className='input_box'>
                        <label htmlFor="contractExpired">Contract expiring on</label>
                        <input type="date" id='contractExpired' />
                    </div>
                </div>
                <br />
                <div className='lower_section'>
                    <button>Update profile</button>
                </div>
            </div>
        </>
    )
}

export default EditOrganisationProfile

import React, { useContext, useState, useEffect } from 'react';
import mainContext from '../../../contexts/mainContext';
import UploadPhoto from '../../../Controller/UploadPhoto';
import { ToastContainer, toast } from 'react-toastify';
import Organisation from '../../../Controller/Organisation';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const EditAdminProfile = () => {
    const context = useContext(mainContext);
    const { setCanvasTitle, loginUserInfo } = context;

    const [ organisationdata, setOrganisationdata ] = useState(loginUserInfo);
    const { name, countrycode, mobileno, email, profileimage, qrcode, prefferedlanguage } = organisationdata;
    const [flag, setFlag] = useState(false);
    console.log({ loginUserInfo })
    console.log({ prefferedlanguage })

    useEffect(() => {
        setCanvasTitle("Edit your profile");
        if(prefferedlanguage === undefined){
            setOrganisationdata((prevSatte) => {
                return { ...prevSatte, prefferedlanguage: 'english' }
            })
        }
        // eslint-disable-next-line
    }, [])

    const changeAvatar = async(e) => {

        const { name } = e.target;

        const err = await UploadPhoto.checkImage(e.target.files[0]);
        if(err) {
            return toast.warning(err,{
                pauseOnHover: false,
                theme: "light"
            });
        }

        const image = await UploadPhoto.uploadPhoto(e.target.files[0]);
        const { path } = image;
        setOrganisationdata((prevSatte) => {
            return { ...prevSatte, [name]: path }
        })

        // setImage(e.target.files[0]);
        
    }

    const handleImg = (id) => {
        document.getElementById(id).click();
    }

    const handleSubmit = async() => {

        if(flag) return;
        setFlag(true);

        const res = await Organisation.updateOrganisation(organisationdata)
        // console.log({ res })
        if(res && (res.status === 200 || res.status === 201)){
            toast.success("Organisation Data Is Updated Successfully",{
                pauseOnHover: false,
                theme: "light"
            });

            setTimeout(async() => {
                window.location.reload();
            }, 2000)
        }
        else{
            if(typeof(res.data.message) == 'object' )
                toast.error(res.data.message ? res.data.message[0] : "SomeThing Went Wrong",{
                    pauseOnHover: false,
                    theme: "light"
                });
            else{
                toast.error(res.data.message ? res.data.message : "SomeThing Went Wrong",{
                    pauseOnHover: false,
                    theme: "light"
                });
            }

        }

        setFlag(false);
    }

    const handleChange = (e) => {
        setOrganisationdata((prevSatte) => {
            return { ...prevSatte, prefferedlanguage: e.target.value }
        })
    }
    
    return (
        <>
            <ToastContainer autoClose={2000} position="top-center"  />
            <div className="edit_profile_main_div">
                <div className='upper_section'>
                    <div className='input_box'>
                        <label htmlFor="">Full name</label>
                        <input type="text" placeholder='enter name here' value={name} disabled />
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">Mobile number</label>
                        <div>
                            <input type="number" placeholder='mobileno' value={`${countrycode}${mobileno}`} disabled />
                        </div>
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">Email address</label>
                        <input type="text" placeholder='email@masheeha.in' value={email} disabled />
                    </div>

                    <div className='input_box img_div'>
                        <label htmlFor="">Profile image</label>
                        <div>
                            <img src={`${SERVER_URL}/${profileimage}`} alt="" 
                                style={{ width: '100px', clipPath: 'none', height: 'auto' }} 
                            />
                        </div>
                    </div>

                    <div className='input_box img_div'>
                        <label htmlFor="">QrCode</label>
                        <input type="file" style={{ display: "none" }} id="img_input" accept=".jpg, .jpeg, .png" onChange={changeAvatar} name="qrcode" />
                        <div style={{ margin: "5% 0" }}>
                            {
                                qrcode && qrcode !== ""
                                ? <img src={`${SERVER_URL}/${qrcode}`} style={{ width: "90px" }} alt="" />
                                : ""
                            }
                            <button onClick={() => handleImg('img_input')}>Upload QrCode</button>
                        </div>
                    </div>

                    <div className='input_box' style={{ marginBottom: '2rem' }}>
                        <label htmlFor="">Preffered Language</label>
                        <select name="prefferedlanguage" value={prefferedlanguage} onChange={handleChange}>
                            <option value="english">English</option>
                            <option value="arabic">Arabic</option>
                        </select>
                    </div>

                </div>
                <div className='lower_section'>
                    <button onClick={handleSubmit}>Update profile</button>
                </div>
            </div>
        </>
    )
}

export default EditAdminProfile

import React, { useContext, useEffect } from 'react'
import mainContext from '../../../contexts/mainContext';

const AddOrganisation = () => {
    const context = useContext(mainContext);
    const { setinCanvas, setCanvasTitle, setCanvasSubTitle } = context;
    const handleProceed = () => {
        setinCanvas("Add organisation documents");
    }
    const handleImg = () => {
        document.getElementById("img_input").click();
    }
    useEffect(() => {
        setCanvasTitle("Add new");
        setCanvasSubTitle("Organisation");
        // eslint-disable-next-line
    }, [])
    return (
        <>
            <div className="add_company_main_div">
                <div className='upper_section'>
                    <div className='input_box'>
                        <label htmlFor="">Organisation name</label>
                        <input type="text" placeholder='enter name here' id='compnay_name' />
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">City</label>
                        <div className='select_input'>
                            <input type="text" placeholder='Select city' id='select_input' />
                            {/* <select onChange={(e) => { handleSelect(e) }}>
                                <option >Select city</option>
                                <option value="Mini">Mini</option>
                                <option value="Large">Large</option>
                                <option value="Laxury">Laxury</option>
                            </select> */}
                        </div>
                    </div>


                    <div className='input_box'>
                        <label htmlFor="">Full name</label>
                        <input type="text" placeholder='enter name here' id='full_name' />
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">Phone number</label>
                        <div>
                            <select>
                                <option>+971</option>
                                <option value="1">+91</option>
                                <option value="2">+201</option>
                                <option value="3">+111</option>
                            </select>
                            <input type="number" placeholder='0000000000' id='mobile' />
                        </div>
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">Email address</label>
                        <input type="text" placeholder='enter email here' id='email' />
                    </div>

                    <div className='input_box upload_photo'>
                        <label htmlFor="">Profile image</label>
                        <input type="file" style={{ display: "none" }} id="img_input" />
                        <div style={{ margin: "5% 0" }}>
                            <button onClick={handleImg}>Upload profile image</button>
                        </div>
                    </div>

                </div>
                <div className='lower_section'>
                    <button onClick={handleProceed}>Proceed to upload documents</button>
                </div>
            </div>
        </>
    )
}

export default AddOrganisation

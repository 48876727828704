import React, { useContext } from 'react'
import mainContext from '../../../contexts/mainContext';
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const Cards = (props) => {

    const context = useContext(mainContext);
    const { setinCanvas, settrustandsafetyCardDetail, settrustandsafetySingleCardDetail } = context;
    const { description, isavailable, image, item, data } = props;

    const handleClick = () => {
        settrustandsafetyCardDetail(() => { return{ card: data.card, _id: data._id }});
        settrustandsafetySingleCardDetail(() => item)
        setinCanvas('Edit Trust And Safety Single Card Detail')
    }

    let status = isavailable ? 'active' : 'inactive'

    return (
        <>
            <div className="list_card companies_list_grid" style={{ gridTemplateColumns:"3fr 3fr 6fr 3fr" }}>
                <div>
                    <div className={`list_card_status status_${status}`}>{status}</div>
                </div>
                <div>{description ? description.slice(0, 40) : ''}</div>
                <div style={{ textAlign: 'center' }}>
                    <img src={`${SERVER_URL}/${image}`} alt="" style={{ width: '40px' }} />
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <button className="list_card_view_btn" onClick={handleClick}>Edit Card</button>
                </div>
            </div>
        </>
    )
}

export default Cards

import React, { useEffect, useContext } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import mainContext from '../../contexts/mainContext';
import './Menu.css';
import logo from './Assets/maseeha_logo.png';
import Active_Analytics from './Assets/Active_svg/Analytics.svg';
import Active_Logout from './Assets/Active_svg/Logout.png';
import Active_Management from './Assets/Active_svg/Management.png';
import Inctive_Analytics from './Assets/Inactive_svg/Analytics.svg';
import Inactive_Logout from './Assets/Inactive_svg/Logout.svg';
import Inactive_Management from './Assets/Inactive_svg/Management.svg';
import Auth from '../../Controller/Auth';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

const Menu = () => {
    
    const context = useContext(mainContext);
    let { profileNavTitle, setLoginUserInfo } = context;
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {

        const fetchData = async() => {
            const token = localStorage.getItem('authToken');
            const res = await Auth.getProfile(token);
            // console.log({ res })
            if(res.status === 200 || res.status === 201){
                setLoginUserInfo( res.data )
            }else {
                
                localStorage.removeItem('authToken')
                toast.error("Please Login Again",{
                    pauseOnHover: false,
                    theme: "light"
                });

                setTimeout(() => {
                    navigate('/login')
                }, 1000)

            }
        }
        
        setTimeout(() => {
            fetchData();
        }, 1000)
        // eslint-disable-next-line
    }, [])


    useEffect(() => {
        
        const managementLi = document.querySelector(".management_li");
        const pageLi = document.querySelector(".page_li");
        const formLi = document.querySelector(".form_li");

        if (location.pathname.includes("Management")) {
            managementLi.classList.add("management_li_active");
        }
        else {
            managementLi.classList.remove("management_li_active");
        }

        if (location.pathname.includes("Pages")) {
            pageLi.classList.add("page_li_active");
        }
        else {
            pageLi.classList.remove("page_li_active");
        }

        if (location.pathname.includes("Forms")) {
            formLi.classList.add("form_li_active");
        }
        else {
            formLi.classList.remove("form_li_active");
        }

        // eslint-disable-next-line
    }, [profileNavTitle, location.pathname])
    
    return (
        <>
            <ToastContainer autoClose={2000} position="top-center"  />
            <div className="menu_main_div menu_active">
                <div className="menu_hyratech_logo">
                    <img src={logo} alt="" />
                </div>
                <div className="line"></div>
                <h6>MENU</h6>
                <div className="menu_list">
                    <ul>
                        <li>
                            <Link to="/" className={location.pathname === "/" ? "menu_active_link" : ""}>
                                <span><img src={location.pathname === "/" ? Active_Analytics : Inctive_Analytics} alt="" /></span>
                                Analytics
                            </Link>
                        </li>

                        <li className="li_collapse management_li">
                            <Link to="/Management" className={location.pathname.includes("/Management") ? "menu_active_link" : ""}>
                                <span><img src={location.pathname.includes("/Management") ? Active_Management : Inactive_Management} alt="" /></span>
                                Management
                            </Link>
                            <ul>
                                <li>
                                    <Link to="/Management/patient" className={location.pathname.includes("/Management/patient") ? "menu_active_link" : ""}>Patient</Link>
                                </li>
                                <li>
                                    <Link to="/Management/fundraiser" className={location.pathname.includes("/Management/fundraiser") ? "menu_active_link" : ""}>Fund Raiser</Link>
                                </li>
                                <li>
                                    <Link to="/Management/banner" className={location.pathname.includes("/Management/banner") ? "menu_active_link" : ""}>Banner</Link>
                                </li>
                                <li>
                                    <Link to="/Management/our-partner" className={location.pathname.includes("/Management/our-partner") ? "menu_active_link" : ""}>Association</Link>
                                </li>
                                <li>
                                    <Link to="/Management/blog" className={location.pathname.includes("/Management/blog") ? "menu_active_link" : ""}>Blog</Link>
                                </li>
                                <li>
                                    <Link to="/Management/success-story" className={location.pathname.includes("/Management/success-story") ? "menu_active_link" : ""}>SuccessStory</Link>
                                </li>
                                <li>
                                    <Link to="/Management/faqs" className={location.pathname.includes("/Management/faqs") ? "menu_active_link" : ""}>Faqs</Link>
                                </li>
                                <li>
                                    <Link to="/Management/testimonial" className={location.pathname.includes("/Management/testimonial") ? "menu_active_link" : ""}>Testimonial</Link>
                                </li>
                                <li>
                                    <Link to="/Management/amenities" className={location.pathname.includes("/Management/amenities") ? "menu_active_link" : ""}>Amenities</Link>
                                </li>
                                <li>
                                    <Link to="/Management/key-point" className={location.pathname.includes("/Management/key-point") ? "menu_active_link" : ""}>Key-Point</Link>
                                </li>
                                {/* <li>
                                    <Link to="/Management/pricing-and-fees" className={location.pathname.includes("/Management/pricing-and-fees") ? "menu_active_link" : ""}>Pricing-Fees</Link>
                                </li>
                                <li>
                                    <Link to="/Management/how-it-work" className={location.pathname.includes("/Management/how-it-work") ? "menu_active_link" : ""}>How-It-Work</Link>
                                </li>
                                <li>
                                    <Link to="/Management/medical-crowdfunding" className={location.pathname.includes("/Management/medical-crowdfunding") ? "menu_active_link" : ""}>CrowdFunding</Link>
                                </li> */}
                            </ul>
                        </li>

                        <li className="li_collapse page_li">
                            <Link to="/Pages" className={location.pathname.includes("/Pages") ? "menu_active_link" : ""}>
                                <span><img src={location.pathname.includes("/Pages") ? Active_Management : Inactive_Management} alt="" /></span>
                                Pages
                            </Link>
                            <ul>
                                <li>
                                    <Link to="/Pages/pricing-and-fees" className={location.pathname.includes("/Pages/pricing-and-fees") ? "menu_active_link" : ""}>Pricing-Fees</Link>
                                </li>
                                <li>
                                    <Link to="/Pages/how-it-work" className={location.pathname.includes("/Pages/how-it-work") ? "menu_active_link" : ""}>How-It-Work</Link>
                                </li>
                                <li>
                                    <Link to="/Pages/medical-crowdfunding" className={location.pathname.includes("/Pages/medical-crowdfunding") ? "menu_active_link" : ""}>CrowdFunding</Link>
                                </li>
                                <li>
                                    <Link to="/Pages/about-us" className={location.pathname.includes("/Pages/about-us") ? "menu_active_link" : ""}>About-us</Link>
                                </li>
                                <li>
                                    <Link to="/Pages/social-media" className={location.pathname.includes("/Pages/social-media") ? "menu_active_link" : ""}>Social-Media</Link>
                                </li>
                                <li>
                                    <Link to="/Pages/team" className={location.pathname.includes("/Pages/team") ? "menu_active_link" : ""}>Team</Link>
                                </li>
                                <li>
                                    <Link to="/Pages/promote" className={location.pathname.includes("/Pages/promote") ? "menu_active_link" : ""}>Promote</Link>
                                </li>
                                <li>
                                    <Link to="/Pages/video" className={location.pathname.includes("/Pages/video") ? "menu_active_link" : ""}>Video</Link>
                                </li>
                                <li>
                                    <Link to="/Pages/trust-safety" className={location.pathname.includes("/Pages/trust-safety") ? "menu_active_link" : ""}>Trust-Safety</Link>
                                </li>
                                <li>
                                    <Link to="/Pages/faq-data" className={location.pathname.includes("/Pages/faq-data") ? "menu_active_link" : ""}>FAQ-Data</Link>
                                </li>
                                <li>
                                    <Link to="/Pages/fundraising-tip" className={location.pathname.includes("/Pages/fundraising-tip") ? "menu_active_link" : ""}>FundRaising-Tip</Link>
                                </li>
                            </ul>
                        </li>

                        <li className="li_collapse form_li">
                            <Link to="/Forms" className={location.pathname.includes("/Forms") ? "menu_active_link" : ""}>
                                <span><img src={location.pathname.includes("/Forms") ? Active_Management : Inactive_Management} alt="" /></span>
                                Forms
                            </Link>
                            <ul>
                                <li>
                                    <Link to="/Forms/subscriber" className={location.pathname.includes("/Forms/subscriber") ? "menu_active_link" : ""}>Subscriber</Link>
                                </li>
                                <li>
                                    <Link to="/Forms/fundraiser-approval" className={location.pathname.includes("/Forms/fundraiser-approval") ? "menu_active_link" : ""}>FundRaiser-Approval</Link>
                                </li>
                                <li>
                                    <Link to="/Forms/reports" className={location.pathname.includes("/Forms/reports") ? "menu_active_link" : ""}>Reports</Link>
                                </li>
                                <li>
                                    <Link to="/Forms/career" className={location.pathname.includes("/Forms/career") ? "menu_active_link" : ""}>Career</Link>
                                </li>
                                <li>
                                    <Link to="/Forms/transaction-approval" className={location.pathname.includes("/Forms/transaction-approval") ? "menu_active_link" : ""}>Transaction-Approval</Link>
                                </li>
                                <li>
                                    <Link to="/Forms/stripe-data" className={location.pathname.includes("/Forms/stripe-data") ? "menu_active_link" : ""}>Stripe-Info</Link>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <Link to="/logout">
                                <span><img src={location.pathname === "/login" ? Active_Logout : Inactive_Logout} alt="" /></span>
                                Logout
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className="menu_footer">
                    <span>App Version 1.0.0</span>
                    <span>&copy; 2021 All rights reserved</span>
                </div>
            </div>
        </>
    )
}

export default Menu

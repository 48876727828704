import React, { useContext } from 'react'
import mainContext from '../../../contexts/mainContext';
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const TeamMembers = (props) => {

    const context = useContext(mainContext);
    const { setinCanvas, setTeamMember, setSingleTeamMember } = context;
    const { name, role, image, item, data } = props;

    const handleClick = () => {
        setTeamMember(() => { return { _id: data._id, members: data.members } })
        setSingleTeamMember(() => item);
        setinCanvas('Edit Single Team member')
    }

    return (
        <>

            <div className="list_card companies_list_grid" style={{ gridTemplateColumns:"3fr 3fr 6fr 3fr" }}>
                <div>{name}</div>
                <div>{role}</div>
                <div style={{ textAlign: 'center' }}>
                    <img src={`${SERVER_URL}/${image}`} alt="" style={{ width: '40px' }} />
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <button className="list_card_view_btn" onClick={handleClick}>Edit Member</button>
                </div>

            </div>
        </>
    )
}

export default TeamMembers

import React, { useEffect, useContext, useState } from 'react'
import mainContext from '../../../contexts/mainContext';
import { ToastContainer, toast } from 'react-toastify';
import UploadPhoto from '../../../Controller/UploadPhoto';
import Organisation from '../../../Controller/Organisation';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function EditCrowdFundingCard() {

    const context = useContext(mainContext);
    const [flag, setFlag] = useState(false);
    const url = new URLSearchParams(window.location.search);
    const { 
        setCanvasTitle,
        setCrowdFundingCardDetail,
        crowdfundingcardDetail,  
        setCanvasSubTitle,
        allFunctions
    } = context;

    const { handleCanvasClose, getCrowdFundingList } = allFunctions;

    const initialState = { image: '', title: '', heading: '' };

    const [howitworkdata, setHowItWorkData] = useState(initialState);
    const { image, heading, title } = howitworkdata;

    


    const handleChange = (e) => {
        const { name, value } = e.target;
        setHowItWorkData((prevSatte) => {
            return { ...prevSatte, [name]: value }
        })
    }
    
    useEffect(() => {
        setCanvasTitle("Edit");
        setCanvasSubTitle("Crowd Funding Card");
        // eslint-disable-next-line
    }, [])

    const changeAvatar = async(e) => {

        const { name } = e.target;

        const err = await UploadPhoto.checkImage(e.target.files[0]);
        if(err) {
            return toast.warning(err,{
                pauseOnHover: false,
                theme: "light"
            });
        }

        const image = await UploadPhoto.uploadPhoto(e.target.files[0]);
        // console.log({ image })
        const { path } = image;
        setHowItWorkData((prevSatte) => {
            return { ...prevSatte, [name]: path }
        })

        // setImage(e.target.files[0]);
    }

    const handleImg = (id) => {
        // console.log('handle image')
        document.getElementById(id).click();
    }

    const handleSubmit = async() => {

        if(flag) return;
        setFlag(true);

        const res = await Organisation.updatecrowdfunding(crowdfundingcardDetail)
        // console.log({ res })
        if(res && (res.status === 200 || res.status === 201)){
            toast.success("Card Is Updated Successfully",{
                pauseOnHover: false,
                theme: "light"
            });

            setTimeout(async() => {
                await getCrowdFundingList(url.get('page'))
                await handleCanvasClose();
            }, 2000)
        }
        else{
            if(typeof(res.data.message) == 'object' )
                toast.error(res.data.message ? res.data.message[0] : "SomeThing Went Wrong",{
                    pauseOnHover: false,
                    theme: "light"
                });
            else{
                toast.error(res.data.message ? res.data.message : "SomeThing Went Wrong",{
                    pauseOnHover: false,
                    theme: "light"
                });
            }

        }

        setFlag(false);
    }

    function handleDelete(index){
        // console.log({ index })
        const newArr = [ ...crowdfundingcardDetail.card ];
        newArr.splice(index, 1);
        // setImages(newArr)
        setCrowdFundingCardDetail((prevSatte) => {
            return { ...prevSatte, card: [ ...newArr ]}
        })
    }

    function handleEdit(index, item){
        const newArr = [ ...crowdfundingcardDetail.card ];
        newArr.splice(index, 1);
        setCrowdFundingCardDetail((prevSatte) => {
            return { ...prevSatte, card: [ ...newArr ]}
        })
        setHowItWorkData(() => item);
    }

    function handleCardData(){
        let data = { image, title, heading }
        setCrowdFundingCardDetail((prevSatte) => {
            return { ...prevSatte, card: [ ...prevSatte.card, data ]}
        })
        setHowItWorkData(() => initialState);
    }

    // console.log({ crowdfundingcardDetail })

  return (
    <>
            <ToastContainer autoClose={2000} position="top-center"  />
            <div className="add_company_main_div">
                <div className='upper_section'>

                <div style={{ display: 'flex', gap: '3vh' }}>
                    <div className='input_box'>
                        <label htmlFor="">Title</label>
                        <input type="text" placeholder='Enter Title' id='compnay_name'
                            name="title" value={title} onChange={handleChange}
                        />
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">Heading</label>
                        <input type="text" placeholder='Enter Url' id='compnay_name'
                            name="heading" value={heading} onChange={handleChange}
                        />
                    </div>
                </div>

                <div className='input_box upload_photo'>
                    <label htmlFor="">Image</label>
                    <input type="file" style={{ display: "none" }} id="img_input" accept=".jpg, .jpeg, .png" onChange={changeAvatar} name="image" />
                    <div style={{ margin: "5% 0" }}>
                        {
                            image !== ""
                            ? <img src={`${SERVER_URL}/${image}`} style={{ width: "90px" }} alt="" />
                            : ""
                        }
                        <button onClick={() => handleImg('img_input')}>Upload image</button>
                    </div>
                </div>

                <div className='lower_section'>
                    <button onClick={handleCardData}>Add Data</button>
                </div>

                    {
                        crowdfundingcardDetail &&
                        crowdfundingcardDetail.card &&
                        crowdfundingcardDetail.card.length > 0 &&
                        crowdfundingcardDetail.card.map((item, index) => {
                            return (
                                <div key={index} style={{ display: 'flex', alignItems: 'center', gap: '3vh' }}>
                                    <p style={{ margin: '5px 0', width: '90%' }}>{item?.title}</p>
                                    <i className="fas fa-edit" style={{ cursor: 'pointer' }}
                                        onClick={() => handleEdit(index, item)}
                                    ></i>
                                    <i className="fa fa-trash" aria-hidden="true" style={{ cursor: 'pointer' }} 
                                        onClick={() => handleDelete(index)}
                                    ></i>
                                </div>
                            )
                        })
                    }

                </div>
                <div className='lower_section' style={{marginTop: "1rem"}}>
                    <button onClick={handleSubmit}>Save And Proceed</button>
                </div>
            </div>
    </>
  )
}

export default EditCrowdFundingCard
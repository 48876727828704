import React, { useContext, useEffect, useState } from 'react';
import HowitWorkListCard from "./HowitWorkCard.jsx";
import SearchHeader from '../../SearchHeader/SearchHeader.jsx';
import "./HowitWorkPage.css";
import mainContext from '../../../contexts/mainContext.jsx';
import Pagination from '../../Pagination/Pagination.jsx';
import { useNavigate } from 'react-router-dom';
import Organisation from '../../../Controller/Organisation.jsx';
import { ToastContainer, toast } from 'react-toastify';

const HowitWorkPage = () => {
    
    const context = useContext(mainContext);
    const url = new URLSearchParams(window.location.search);
    const navigate = useNavigate();

    const [ howItWorkList, setHowItWorkList ] = useState([])
    
    let {
        param,
        setProfileNavTitle,
        totalFetchDataCount,
        setTotalFetchDataCount,
        setAllFunctions,
        allFunctions
    } = context;
    
    const getHowitworkList = async(page = 1, pass = undefined) => {
        if(!page)
            page = 1;
        navigate(`?page=${page}`)
        if(pass){
            param = pass;
        }
        param.page = page;
        const res = await Organisation.getHowitworkList(param)
        // console.log({ res })
        if(res.status === 200 || res.status === 201){
            setHowItWorkList(() => res.data.results)
            setTotalFetchDataCount(res.data.count)
        }else{
            toast.error(res.data.message ? res.data.message : "SomeThing Went Wrong",{
                pauseOnHover: false,
                theme: "light"
            });
        }
    }

    useEffect(() => {

        getHowitworkList(url.get("page"));
        setProfileNavTitle("Management");
        setAllFunctions({ ...allFunctions, getHowitworkList })
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <ToastContainer autoClose={2000} position="top-center"  />
            <SearchHeader callFunction={() => {}} addMoreSection={true} addSubTitle={true} subTitle="how-it-work" records={totalFetchDataCount} filterOf={"HowitworksFilter"} searchPlaceHolder="Search how-it-work" />
            <div className="component_body">
                <div className="list_header companies_list_grid" style={{ gridTemplateColumns:"3fr 3fr 6fr 6fr 5fr 2fr" }}>
                    <div>Status</div>
                    <div>Title</div>
                    <div>Button1</div>
                    <div>Button2</div>
                    
                    <div style={{ textAlign: "right" }}>Added on</div>
                    {/* <div></div> */}
                </div>
                <div>

                    {
                        howItWorkList.length <= 0 ?
                            <div style={{ marginTop: "2vh" }}> No record found</div>
                        :
                            <>
                                {
                                    howItWorkList.map((item) => {
                                        return (
                                            <HowitWorkListCard
                                                activated={item?.isavailable}
                                                key={item._id}
                                                item={item}
                                                title={item?.title}
                                                button1={item?.button1}
                                                button2={item?.button2}
                                                added={item.createdAt ? item.createdAt.split('T')[0] : ''} 
                                            />
                                        )
                                    })
                                }
                            </>
                    }

                </div>
            </div>
            {
                param.limit < totalFetchDataCount ?
                    <>
                        {
                            param.limit >= totalFetchDataCount ?
                                ""
                                :
                                <Pagination totalPage={Math.ceil(totalFetchDataCount / param.limit)} callFunction={getHowitworkList} />
                        }
                    </>
                    :
                    ""
            }
        </>
    )
}

export default HowitWorkPage

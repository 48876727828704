import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router';
import mainContext from '../../contexts/mainContext';
import './ManagementPage.css';
import PageBody from './ManagementPageBody/PageBody';

const Pages = () => {
    
    const location = useLocation();
    const context = useContext(mainContext);
    let { setProfileNavTitle } = context;
    
    useEffect(() => {
        setProfileNavTitle(() => "Pages");
    }, [setProfileNavTitle])

    return (
        <>
            <div className="management_main_div">
                {
                    location.pathname === "/Pages" &&
                    <PageBody />
                }
            </div>
        </>
    )
}

export default Pages

import React, { useContext } from 'react'
import mainContext from '../../../contexts/mainContext';

const TeamListCard = (props) => {

    const context = useContext(mainContext);
    const { setinCanvas, setTeamDetail, setTeamMember  } = context;
    const { item, title, description, members, added } = props;

    const handleEdit = () => {
        setTeamDetail(() => item);
        setinCanvas('Edit Team');
    }

    const handleEdit2 = () => {
        setTeamMember(() => { return { _id: item._id, members: item.members } })
        setinCanvas('Edit Team member')
    }

    return (
        <>
            <div className="list_card companies_list_grid" style={{ gridTemplateColumns:"5fr 5fr 2fr 5fr 3fr 3fr" }}>
                <div>{title}</div>
                <div>{description}</div>
                <div>{members?.length}</div>
                <div style={{ textAlign: "right" }}>{added}</div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <button className="list_card_view_btn" onClick={handleEdit}>Edit Details</button>
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <button className="list_card_view_btn" onClick={handleEdit2}>Edit Members</button>
                </div>
            </div>
        </>
    )
}

export default TeamListCard

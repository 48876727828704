import React, { useContext } from 'react'
import mainContext from '../../../contexts/mainContext';

const AboutCard = (props) => {

    const context = useContext(mainContext);
    const { setinCanvas, setaboutnameDetail, setaboutmissionDetail } = context;
    const { added, item, name, mission } = props;

    const handleClick = () => {
        setaboutnameDetail(() => { return { name: item.name, _id: item._id }});
        setinCanvas('Edit about name')
    }

    const handleClick2 = () => {
        setaboutmissionDetail(() => { return{ mission: item.mission, _id: item._id }});
        setinCanvas('Edit about mission')
    }

    return (
        <>
            <div className="list_card companies_list_grid" style={{ gridTemplateColumns:"3fr 3fr 6fr 6fr 5fr 3fr" }}>
                {/* <div>
                    <div className={`list_card_status status_${activated ? 'active' : 'inactive'}`}>{activated ? 'TRUE' : 'FALSE'}</div>
                </div> */}
                <div>{name?.length}</div>
                <div>{mission?.length}</div>
                <div></div>
                <div style={{ textAlign: "right" }}>{added}</div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <button className="list_card_view_btn" onClick={handleClick}>Edit Name</button>
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <button className="list_card_view_btn" onClick={handleClick2}>Edit Mission</button>
                </div>
            </div>
        </>
    )
}

export default AboutCard

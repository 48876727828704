import React, { useContext } from 'react'
import mainContext from '../../../contexts/mainContext';

const FundRaiserApprovalListCard = (props) => {

    const context = useContext(mainContext);
    const { setinCanvas, setFundRaiserApprovalDetail } = context;
    const { added, email, item, goalamount, cause, mobileno } = props;

    const handleClick = () => {
        setFundRaiserApprovalDetail(() => item);
        setinCanvas('View Fund Raiser Approval')
    }

    return (
        <>
            <div className="list_card companies_list_grid" style={{ gridTemplateColumns:"6fr 6fr 6fr 2fr 2fr 2fr" }}>
                <div>{email}</div>
                <div>{cause}</div>
                <div>{mobileno}</div>
                <div>{goalamount}</div>
                <div style={{ textAlign: "right" }}>{added}</div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <button className="list_card_view_btn" onClick={handleClick}>View</button>
                </div>
            </div>
        </>
    )
}

export default FundRaiserApprovalListCard

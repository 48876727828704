import React, { useEffect, useContext, useState } from 'react'
import mainContext from '../../../contexts/mainContext';
import { ToastContainer, toast } from 'react-toastify';
import Organisation from '../../../Controller/Organisation';

function AddStripe() {

    const context = useContext(mainContext);
    const [flag, setFlag] = useState(false);
    const url = new URLSearchParams(window.location.search);
    const { 
        setCanvasTitle,
        // setinCanvas,  
        setCanvasSubTitle,
        allFunctions
    } = context;

    const { handleCanvasClose, getStripeList } = allFunctions;

    const initialState = { 
        publishkey: '', secretkey: '', isactivated: false, email: ''
    }

    const [howitworkdata, setHowItWorkData] = useState(initialState);
    const { isactivated, publishkey, secretkey, email  } = howitworkdata;

    const handleChange = (e) => {
        const { name, value } = e.target;
        setHowItWorkData((prevSatte) => {
            return { ...prevSatte, [name]: value }
        })
    }
    
    useEffect(() => {
        setCanvasTitle("Add new");
        setCanvasSubTitle("Stripe (only one record is created at a time)");
        // eslint-disable-next-line
    }, [])

    const handleSubmit = async() => {

        if(flag) return;
        setFlag(true);

        const res = await Organisation.createStripeData(howitworkdata)
        // console.log({ res })
        if(res && (res.status === 200 || res.status === 201)){
            toast.success("Stripe Data Is Created Successfully",{
                pauseOnHover: false,
                theme: "light"
            });

            setTimeout(async() => {
                await getStripeList(url.get('page'))
                await handleCanvasClose();
            }, 2000)
        }
        else{
            if(typeof(res.data.message) == 'object' )
                toast.error(res.data.message ? res.data.message[0] : "SomeThing Went Wrong",{
                    pauseOnHover: false,
                    theme: "light"
                });
            else{
                toast.error(res.data.message ? res.data.message : "SomeThing Went Wrong",{
                    pauseOnHover: false,
                    theme: "light"
                });
            }

        }

        setFlag(false);
    }


  return (
    <>
            <ToastContainer autoClose={2000} position="top-center"  />
            <div className="add_company_main_div">
                <div className='upper_section'>

                <div className="input_box">
                    <div className="title">Status</div>
                    <div className="check_box" style={{display: "flex", alignItems: "center", gap: "6vh", fontSize: "24px"}}>
                        <div>
                            <input checked={isactivated === true} type="checkbox" 
                                onChange={(e) => { setHowItWorkData((prevSatte) => {return { ...prevSatte, isactivated: true }}) }}  
                                id="status_active" style={{ marginRight: '1vh' }} 
                            />
                            <label htmlFor="status_active">Active</label>
                        </div>
                        <div>
                            <input checked={isactivated === false} type="checkbox" 
                                onChange={(e) => { setHowItWorkData((prevSatte) => {return { ...prevSatte, isactivated: false }}) }}  
                                id="status_inactive" style={{ marginRight: '1vh' }} 
                            />
                            <label htmlFor="status_inactive">Inactive</label>
                        </div>
                    </div>
                </div>

                <div className='input_box'>
                    <label htmlFor="">Email</label>
                    <input type="text" placeholder='Enter Email' id='compnay_name'
                        name="email" value={email} onChange={handleChange}
                    />
                </div>

                <div className='input_box'>
                    <label htmlFor="">Publish Key</label>
                    <input type="text" placeholder='Enter Heading' id='compnay_name'
                        name="publishkey" value={publishkey} onChange={handleChange}
                    />
                </div>

                <div className='input_box'>
                    <label htmlFor="">Secret Key</label>
                    <input type="text" placeholder='Enter Heading' id='compnay_name'
                        name="secretkey" value={secretkey} onChange={handleChange}
                    />
                </div>

                </div>
                <div className='lower_section' style={{marginTop: "1rem"}}>
                    <button onClick={handleSubmit}>Save And Proceed</button>
                </div>
            </div>
    </>
  )
}

export default AddStripe
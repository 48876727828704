import React, { useContext } from 'react'
import mainContext from '../../../contexts/mainContext';
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const Mission = (props) => {

    const context = useContext(mainContext);
    const { setinCanvas, setaboutmissionSingleDetail, setaboutmissionDetail } = context;
    const { isactivated, title, image, item, data } = props;

    const handleClick = () => {
        setaboutmissionDetail(() => { return{ mission: data.mission, _id: data._id }});
        setaboutmissionSingleDetail(() => item)
        setinCanvas('Edit about single mission')
    }

    let status = isactivated ? 'active' : 'inactive';

    return (
        <>

            <div className="list_card companies_list_grid" style={{ gridTemplateColumns:"3fr 3fr 6fr 3fr" }}>
                <div>
                    <div className={`list_card_status status_${status}`}>{status}</div>
                </div>
                <div>{title}</div>
                <div style={{ textAlign: 'center' }}>
                    <img src={`${SERVER_URL}/${image}`} alt="" style={{ width: '40px' }} />
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <button className="list_card_view_btn" onClick={handleClick}>Edit Mission</button>
                </div>

            </div>
        </>
    )
}

export default Mission

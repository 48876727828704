import React, { useEffect, useContext, useState } from 'react'
import mainContext from '../../../contexts/mainContext';
import { ToastContainer, toast } from 'react-toastify';
import Organisation from '../../../Controller/Organisation';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function AddTrustAndSafety() {

    const context = useContext(mainContext);
    const [flag, setFlag] = useState(false);
    const url = new URLSearchParams(window.location.search);
    const { 
        setCanvasTitle,
        // setinCanvas,  
        setCanvasSubTitle,
        allFunctions
    } = context;

    const { handleCanvasClose, getTrustAndSafetyList } = allFunctions;

    const initialState = { 
        donar: '', campaigner: ''
    }

    const [howitworkdata, setHowItWorkData] = useState(initialState);
    const { donar, campaigner  } = howitworkdata;

    // const [value, setValue] = useState('');
    const Editor= {
        formats: [
            'header', 'font', 'size',
            'bold', 'italic', 'underline', 'strike', 'blockquote',
            'list', 'bullet', 'indent',
            'link', 'image', 'video'
        ],

        modules: {
            toolbar: [
              [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
              [{size: []}],
              ['bold', 'italic', 'underline', 'strike', 'blockquote'],
              [{'list': 'ordered'}, {'list': 'bullet'}, 
               {'indent': '-1'}, {'indent': '+1'}],
              ['link', 'image', 'video'],
              ['clean']
            ],
            clipboard: {
              // toggle to add extra line breaks when pasting HTML:
              matchVisual: false,
            }
          }

    }
    
    useEffect(() => {
        setCanvasTitle("Add new");
        setCanvasSubTitle("Trust And Safety");
        // eslint-disable-next-line
    }, [])

    const handleSubmit = async() => {

        if(flag) return;
        setFlag(true);

        const res = await Organisation.createTrustAndSafety(howitworkdata)
        // console.log({ res })
        if(res && (res.status === 200 || res.status === 201)){
            toast.success("Data Is Created Successfully",{
                pauseOnHover: false,
                theme: "light"
            });

            setTimeout(async() => {
                await getTrustAndSafetyList(url.get('page'))
                await handleCanvasClose();
            }, 2000)
        }
        else{
            if(typeof(res.data.message) == 'object' )
                toast.error(res.data.message ? res.data.message[0] : "SomeThing Went Wrong",{
                    pauseOnHover: false,
                    theme: "light"
                });
            else{
                toast.error(res.data.message ? res.data.message : "SomeThing Went Wrong",{
                    pauseOnHover: false,
                    theme: "light"
                });
            }

        }

        setFlag(false);
    }

  return (
    <>
            <ToastContainer autoClose={2000} position="top-center"  />
            <div className="add_company_main_div">
                <div className='upper_section'>

                <div className="input_box">
                    <label htmlFor="notification_body">Enter Donar</label>
                    <ReactQuill 
                        theme="snow" 
                        value={donar} 
                        onChange={(value) => setHowItWorkData((prev) => { return { ...prev, donar: value } })}
                        modules={Editor.modules}
                        formats={Editor.formats} 
                    />
                    
                </div>

                <div className="input_box">
                    <label htmlFor="notification_body">Enter Campaigner</label>
                    <ReactQuill 
                        theme="snow" 
                        value={campaigner} 
                        onChange={(value) => setHowItWorkData((prev) => { return { ...prev, campaigner: value } })}
                        modules={Editor.modules}
                        formats={Editor.formats} 
                    />
                    
                </div>

                </div>
                <div className='lower_section' style={{marginTop: "1rem"}}>
                    <button onClick={handleSubmit}>Save And Proceed</button>
                </div>
            </div>
    </>
  )
}

export default AddTrustAndSafety
import React, { useContext } from 'react'
import mainContext from '../../../contexts/mainContext';

const FundRaiserApprovalListCard = (props) => {

    const context = useContext(mainContext);
    const { setinCanvas, setTransactionApprovalDetail } = context;
    const { added, transactionid, item, amount, date, status } = props;

    const handleClick = () => {
        setTransactionApprovalDetail(() => item);
        setinCanvas('View Transaction Approval')
    }

    return (
        <>
            <div className="list_card companies_list_grid" style={{ gridTemplateColumns:"4fr 6fr 6fr 6fr 2fr 2fr" }}>
                <div>
                    <div className={`list_card_status status_${status === 'approved' ? 'active' : 'inactive'}`}>{status}</div>
                </div>
                <div>{transactionid}</div>
                <div>{amount}</div>
                <div>{date}</div>
                <div style={{ textAlign: "right" }}>{added}</div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <button className="list_card_view_btn" onClick={handleClick}>View</button>
                </div>
            </div>
        </>
    )
}

export default FundRaiserApprovalListCard

import React, { useEffect, useContext } from 'react'
import mainContext from '../../../contexts/mainContext';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function ViewCareer() {

    const context = useContext(mainContext);
    
    const { 
        setCanvasTitle,
        setCanvasSubTitle,
        careerDetail,
    } = context;

    const { postname, applicant, email, countrycode, mobileno, experience, cv } = careerDetail;
    
    useEffect(() => {
        setCanvasTitle("View");
        setCanvasSubTitle("Career Detail");
        // eslint-disable-next-line
    }, [])

  return (
    <>
            <div className="add_company_main_div">
                <div className='upper_section'>

                    <div className='input_box'>
                        <label htmlFor="">Post Name</label>
                        <input type="text" placeholder='Enter name' id='compnay_name' 
                            value={postname} disabled
                        />
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">Applicant Name</label>
                        <input type="text" placeholder='Enter name' id='compnay_name' 
                            value={applicant} disabled
                        />
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">Email</label>
                        <input type="text" placeholder='Enter email' id='compnay_name' 
                            value={email} disabled
                        />
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">Experience</label>
                        <input type="text" placeholder='Enter email' id='compnay_name' 
                            value={experience} disabled
                        />
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">Mobile No</label>
                        <input type="text" placeholder='Enter email' id='compnay_name' 
                            value={`${countrycode} ${mobileno}`} disabled
                        />
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">CV</label>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '3vh' }}>
                            <p style={{ margin: '5px 0', width: '90%' }}>
                                <a href={`${SERVER_URL}/${cv}`} target={'_blank'} rel="noreferrer">{cv}</a>
                            </p>
                        </div>
                    </div>


                </div>
            </div>
    </>
  )
}

export default ViewCareer
import React, { useContext,  useEffect } from 'react'
import mainContext from '../../../contexts/mainContext';


const FAQDriver = () => {
    const context = useContext(mainContext);
    const { setinCanvas, setCanvasTitle, setCanvasSubTitle, currentDriverFAQ } = context;

    const handleEdit = () => {
        setinCanvas("Edit faq-driver");
    }

    useEffect(() => {
        setCanvasTitle("FAQ");
        setCanvasSubTitle("Driver FAQ details");
        // eslint-disable-next-line
    }, [])
    return (
        <>
            {
                currentDriverFAQ ?
                    <div className="notification_main_div">
                        <div className="middle_section">
                            <p style={{ fontWeight: "bold" }}>{currentDriverFAQ.question}</p>
                            <br />
                            <p>{currentDriverFAQ.answer}</p>
                        </div>
                        <div className="lower_section" style={{ display: "flex", columnGap: "5%" }}>
                            <button onClick={handleEdit}>Edit FAQ</button>
                            <button className='delete_btn'>Delete FAQ</button>
                        </div>
                    </div> : "no data found try again"
            }
        </>
    )
}

export default FAQDriver

import React, { useEffect, useContext, useState } from 'react'
import mainContext from '../../../contexts/mainContext';
import { ToastContainer, toast } from 'react-toastify';
import Organisation from '../../../Controller/Organisation';

function EditFaq() {

    const context = useContext(mainContext);
    const [flag, setFlag] = useState(false);
    const url = new URLSearchParams(window.location.search);
    const { 
        setCanvasTitle,
        // setinCanvas,  
        setCanvasSubTitle,
        faqDetail,
        allFunctions
    } = context;

    const { handleCanvasClose, getFaqsList } = allFunctions;

    const [faqData, setFaqData] = useState(faqDetail);
    const { isactivated, slug, question, answer } = faqData;

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFaqData((prevSatte) => {
            return { ...prevSatte, [name]: value }
        })
    }
    
    useEffect(() => {
        setCanvasTitle("Edit");
        setCanvasSubTitle("Faq");
        // eslint-disable-next-line
    }, [])


    const handleSubmit = async() => {

        if(flag) return;
        setFlag(true);

        const res = await Organisation.updateFaq(faqData)
        // console.log({ res })
        if(res && (res.status === 200 || res.status === 201)){
            toast.success("Faqs Is Updated Successfully",{
                pauseOnHover: false,
                theme: "light"
            });

            setTimeout(async() => {
                await getFaqsList(url.get('page'))
                await handleCanvasClose();
            }, 2000)
        }
        else{
            if(typeof(res.data.message) == 'object' )
                toast.error(res.data.message ? res.data.message[0] : "SomeThing Went Wrong",{
                    pauseOnHover: false,
                    theme: "light"
                });
            else{
                toast.error(res.data.message ? res.data.message : "SomeThing Went Wrong",{
                    pauseOnHover: false,
                    theme: "light"
                });
            }

        }

        setFlag(false);
    }

  return (
    <>
            <ToastContainer autoClose={2000} position="top-center"  />
            <div className="add_company_main_div">
                <div className='upper_section'>

                    <div className="input_box">
                        <div className="title">Status</div>
                        <div className="check_box" style={{display: "flex", alignItems: "center", gap: "6vh", fontSize: "24px"}}>
                            <div>
                                <input checked={isactivated === true} type="checkbox" 
                                    onChange={(e) => { setFaqData((prevSatte) => {return { ...prevSatte, isactivated: true }}) }}  
                                    id="status_active" style={{ marginRight: '1vh' }} 
                                />
                                <label htmlFor="status_active">Active</label>
                            </div>
                            <div>
                                <input checked={isactivated === false} type="checkbox" 
                                    onChange={(e) => { setFaqData((prevSatte) => {return { ...prevSatte, isactivated: false }}) }}  
                                    id="status_inactive" style={{ marginRight: '1vh' }} 
                                />
                                <label htmlFor="status_inactive">InActive</label>
                            </div>
                        </div>
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">Category</label>
                        <div>
                            <select name="slug" value={slug} onChange={handleChange}>
                                <option value="how-it-work">How It Work</option>
                                <option value="crowd-funding">Crowd Funding</option>
                                <option value="reportabuse">Report Abuse</option>
                                <option value="career">Career</option>
                                <option value="faqs">Faqs</option>
                                <option value="withdrawls">WithDrawls</option>
                            </select>
                            
                        </div>
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">Question</label>
                        <input type="text" placeholder='Enter Name' id='compnay_name'
                            name="question" value={question} onChange={handleChange}
                        />
                    </div>

                    <div className="input_box">
                        <label htmlFor="notification_body">Answer</label>
                        <textarea id="notification_body" placeholder='enter card description' 
                            name='answer' value={answer} onChange={handleChange}
                        />
                    </div>

                </div>
                <div className='lower_section' style={{marginTop: "1rem"}}>
                    <button onClick={handleSubmit}>Save And Proceed</button>
                </div>
            </div>
    </>
  )
}

export default EditFaq
import React, { useContext } from 'react'
import mainContext from '../../../contexts/mainContext';

const CompaniesListCard = (props) => {

    const context = useContext(mainContext);
    const { setProfileNavTitle, setPatientDetail, setinCanvas } = context;
    const { name, disease, diseasetype, idnumber, mobileno, added, item } = props;

    const handleClick = () => {
        setPatientDetail(() => { return item });
        setProfileNavTitle(name);
        setinCanvas('Edit Patient')
    }

    return (
        <>
            <div className="list_card companies_list_grid" style={{ gridTemplateColumns:" 4fr 4fr 4fr 4fr 4fr 4fr 4fr" }}>
                <div>{name}</div>
                <div>{disease}</div>
                <div>{diseasetype}</div>
                <div>{idnumber}</div>
                <div>{mobileno}</div>
                <div style={{ textAlign: "right" }}>{added}</div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <button className="list_card_view_btn" onClick={handleClick}>Edit</button>
                </div>
            </div>
        </>
    )
}

export default CompaniesListCard

import React, { useState } from 'react'
import mainContext from './mainContext'

const MainState = (props) => {
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const nth = function (d) {
        if (d > 3 && d < 21) return "th";
        switch (d % 10) {
            case 1:
                return "st";
            case 2:
                return "nd";
            case 3:
                return "rd";
            default:
                return "th";
        }
    };
    const [allFunctions, setAllFunctions] = useState({})
    const [totalFetchDataCount, setTotalFetchDataCount] = useState(12);
    let param = {
        limit: 10,
        page: "",
        filterDate: {
            from: "",
            to: ""
        },
        status: "",
        searchtext: "",
        companyId: "",
        vendorId: "",
        category: ""
    }
    const [activePagination, setActivePagination] = useState(1);
    const [profileNavTitle, setProfileNavTitle] = useState("");
    const [inCanvas, setinCanvas] = useState("");
    const [canvasTitle, setCanvasTitle] = useState("");
    const [canvasSubTitle, setCanvasSubTitle] = useState("");

    const [patientDetail, setPatientDetail] = useState({});
    const [fundraiserDetail, setFundraiserDetail ] = useState({});
    const [bannerDetail, setBannerDetail] = useState({})
    const [ourpartnerDetail, setOurPartnerDetail] = useState({})
    const [blogDetail, setBlogDetail] = useState({});
    const [successStoryDetail, setSuccessStoryDetail] = useState({});
    const [faqDetail, setFaqDetails] = useState({});
    const [testimonialDetails, setTestimonialDetails] = useState({});
    const [amenitiesDetails, setAmenitiesDetails] = useState({});
    const [keyPointDetail, setKeyPointDetail] = useState({});
    const [pricingFeesDetail, setPricingFeesDetail] = useState({});
    const [howItWorkDetail, setHowItWorkDetail] = useState({});
    const [crowdfundingfundDetail, setCrowdFundingfundDetail] = useState({});
    const [crowdfundingsinglefundDetail, setCrowdFundingSinglefundDetail] = useState({});
    const [crowdfundingcardDetail, setCrowdFundingCardDetail] = useState({});
    const [crowdfundingsinglecardDetail, setCrowdFundingSingleCardDetail] = useState({});
    const [aboutnameDetail, setaboutnameDetail] = useState({});
    const [aboutnamesingleDetail, setaboutnameSingleDetail] = useState({});
    const [aboutmissionDetail, setaboutmissionDetail] = useState({});
    const [aboutmissionsingleDetail, setaboutmissionSingleDetail] = useState({});
    const [socialMediaDetail, setSocialMediaDetail] = useState({});
    const [teamDetail, setTeamDetail] = useState({});
    const [teamMember, setTeamMember] = useState({});
    const [singleteamMember, setSingleTeamMember] = useState({});
    const [promoteDetail, setPromoteDetail] = useState({});
    const [promotesingleDetail, setPromoteSingleDetail] = useState({});
    const [videoDetail, setVideoDetail] = useState({});
    const [trustandsafetyDetail, settrustandsafetyDetail] = useState({});
    const [trustandsafetyCardDetail, settrustandsafetyCardDetail] = useState({});
    const [trustandsafetysingleCardDetail, settrustandsafetySingleCardDetail] = useState({});
    const [trustandsafetyPieceDetail, settrustandsafetyPieceDetail] = useState({});
    const [trustandsafetysinglePieceDetail, settrustandsafetySinglePieceDetail] = useState({});
    const [fundraiserApprovalDetail, setFundRaiserApprovalDetail] = useState({});
    const [userdetail, setUserDetail] = useState({});
    const [reportDetail, setReportDetail] = useState({});
    const [careerDetail, setCareerDetail] = useState({});
    const [faqdatafundraiserdetail, setfaqDataFundraiserDetail] = useState({})
    const [faqdatasinglefundraiserdetail, setfaqDataSingleFundraiserDetail] = useState({})
    const [faqdataquickLinks, setfaqDataQuickLinks] = useState({})
    const [faqdatasinglequickLinks, setfaqDataSingleQuickLinks] = useState({})
    const [fundraisingtipcardDetail, setFundRaisingTipCardDetail] = useState({})
    const [fundraisingsingletipcardDetail, setFundRaisingSingleTipCardDetail] = useState({})
    const [transactionApprovalDetail, setTransactionApprovalDetail] = useState({})
    const [stripeDetail, setStripeDetail] = useState({});
    const [loginUserInfo, setLoginUserInfo] = useState({});

    return (
        <mainContext.Provider value={{
            param,
            nth,
            months,
            totalFetchDataCount,
            setTotalFetchDataCount,

            allFunctions,
            setAllFunctions,

            profileNavTitle,
            setProfileNavTitle,

            inCanvas,
            setinCanvas,
            canvasTitle,
            setCanvasTitle,
            canvasSubTitle,
            setCanvasSubTitle,

            activePagination,
            setActivePagination,

            loginUserInfo,
            setLoginUserInfo,

            patientDetail,
            setPatientDetail,
            fundraiserDetail,
            setFundraiserDetail,

            bannerDetail,
            setBannerDetail,

            ourpartnerDetail,
            setOurPartnerDetail,

            blogDetail,
            setBlogDetail,

            successStoryDetail,
            setSuccessStoryDetail,

            faqDetail,
            setFaqDetails,

            testimonialDetails,
            setTestimonialDetails,

            amenitiesDetails,
            setAmenitiesDetails,

            keyPointDetail,
            setKeyPointDetail,

            pricingFeesDetail, 
            setPricingFeesDetail,

            howItWorkDetail, 
            setHowItWorkDetail,

            crowdfundingfundDetail, 
            setCrowdFundingfundDetail,
            crowdfundingsinglefundDetail, 
            setCrowdFundingSinglefundDetail,

            crowdfundingcardDetail, 
            setCrowdFundingCardDetail,
            crowdfundingsinglecardDetail, 
            setCrowdFundingSingleCardDetail,

            aboutnameDetail, 
            setaboutnameDetail,
            aboutnamesingleDetail, 
            setaboutnameSingleDetail,

            aboutmissionDetail, 
            setaboutmissionDetail,
            aboutmissionsingleDetail, 
            setaboutmissionSingleDetail,

            socialMediaDetail, 
            setSocialMediaDetail,

            teamDetail, 
            setTeamDetail,

            teamMember, 
            setTeamMember,
            singleteamMember, 
            setSingleTeamMember,

            promoteDetail,
            setPromoteDetail,
            promotesingleDetail, 
            setPromoteSingleDetail,

            videoDetail, 
            setVideoDetail,

            trustandsafetyDetail, 
            settrustandsafetyDetail,
            
            trustandsafetyCardDetail, 
            settrustandsafetyCardDetail,
            trustandsafetysingleCardDetail, 
            settrustandsafetySingleCardDetail,

            trustandsafetyPieceDetail, 
            settrustandsafetyPieceDetail,
            trustandsafetysinglePieceDetail, 
            settrustandsafetySinglePieceDetail,

            fundraiserApprovalDetail, 
            setFundRaiserApprovalDetail,

            reportDetail, 
            setReportDetail,
            
            userdetail,
            setUserDetail,
            
            careerDetail, 
            setCareerDetail,

            faqdatafundraiserdetail, 
            setfaqDataFundraiserDetail,
            faqdatasinglefundraiserdetail, 
            setfaqDataSingleFundraiserDetail,

            faqdataquickLinks, 
            setfaqDataQuickLinks,
            faqdatasinglequickLinks, 
            setfaqDataSingleQuickLinks,

            fundraisingtipcardDetail, 
            setFundRaisingTipCardDetail,
            fundraisingsingletipcardDetail, 
            setFundRaisingSingleTipCardDetail,

            transactionApprovalDetail, 
            setTransactionApprovalDetail,

            stripeDetail, 
            setStripeDetail
            
        }} >
            {props.children}
        </mainContext.Provider>
    )
}

export default MainState
